import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlarmService } from '../alarms/alarm.service';
import { MatLegacySnackBar as MatSnackBar,MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import {GeaMesCognitoAuthService } from '@gea-mes/cognito';
import {Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Globals } from '../app.globals';
import {MatSort} from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialog2 } from '../confirmation-dialog2.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { VerifyUserDLPopup } from '../verify-user-dl-popup/verify-user-dl-popup.component';
import { AlarmSubscriptionRetryPopup } from '../alarm-retry-popup/alarm-retry-popup.component';
import { AlarmTeamsPopup } from '../alarm-teams-popup/alarm-teams-popup.component';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-groups-config.component.html',
  styleUrls: ['./user-groups-config.component.css']
})
export class UserGroupsConfigComponent implements OnInit {
  formGroup: UntypedFormGroup;
  alarm_groups: any;
  comparePlant: ((f1: any, f2: any) => boolean) | null = this.compareByPlant;
  siteData:any=[];
  adminSub$: Subscription;
  displayedColumns: string[] = ['GroupName','Description'];
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  isConfigTypeCreate:boolean;
  showSpinnerOnUpdate:boolean=false;
  avialbleUsers=[];
  assignedUsers=[];
  userGroupDetails;
  alarmTeamChannelInfo;
  constructor(public alarmService: AlarmService,
    private http: HttpClient,
    private formBuilder:UntypedFormBuilder,
    private dialog: MatDialog,
    public geaMesCognitoAuthService: GeaMesCognitoAuthService,
    public _global: Globals,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _router: Router,) { 
      this.formGroup = this.formBuilder.group({
      });
      
    }
  @ViewChild(MatSort, {static: true}) sort: MatSort;  

  ngOnInit() {
      this.formGroup.addControl('Plant',new UntypedFormControl());
      this.formGroup.addControl('userGroupName',new UntypedFormControl());
      this.formGroup.addControl('userGroupDescription',new UntypedFormControl());
      this.formGroup.addControl('searchAvailableUsers',new UntypedFormControl());
      this.formGroup.addControl('linkToTeams',new UntypedFormControl())
      this.fecthData();      

  }

  fecthData(){
    this.assignedUsers=[];
    this.route.data.subscribe(v => {
      if (v.type == 'create') {
        this.isConfigTypeCreate = true;
      } else {
        this.isConfigTypeCreate = false;
        this.formGroup.get('Plant').disable();
        this.alarmService.getAlarmUserGroupDetails(this.alarmService.goupdID_userGropSelected).subscribe(res => {
          this.userGroupDetails=res['Body'];
          this.formGroup.get('userGroupName').setValue(this.userGroupDetails['GroupName']);
          this.formGroup.get('userGroupDescription').setValue(this.userGroupDetails['Description']);
          if(this.userGroupDetails['TeamURL'] && this.userGroupDetails['TeamChannel']){
            this.formGroup.get("linkToTeams").setValue(1);
          }
          if(res['Body']['GroupMembers']){
            res['Body']['GroupMembers'].forEach(user => {
              user['selected']=false;
              user['show']=true;
              if(user['Type']=="DL" || (user['Type']=="user" && user['Email']==1))
                user['email']=true;
              else 
                user['email']=false;
              if(user['Type']=="user" && user['SMS']==1)
                user['sms']=true;
              else
                user['sms']=false;  
              this.assignedUsers.push(user);  
            });

            this.assignedUsers.forEach(user => {
              if(user['Type']=="DL"){
                user['Active']=1;
                let show_retryIcon_DL=false;
                user['DLUsers'].some(userInfo => {
                  if(userInfo['Active']==0){
                    show_retryIcon_DL=true;
                    user['Active']=0;
                  }
                });
              }
            });
          }
        });
      }
    });
    
    // Fetch Plant List
    this.alarmService.getSitesData().subscribe(res => {
      this.siteData=res['Body'];
      this.alarmService.alarmSiteData=res['Body'];
      if(this.alarmService.plantValue_UserGroups){
        this.formGroup.get('Plant').setValue(this.alarmService.plantValue_UserGroups)
      }
    });

    this.alarmService.getAlarmsListOfAvaiableUsers().subscribe(resp => {
      this.avialbleUsers=resp['Body']['Users'];
      if(resp['Body']['DLs']){
        resp['Body']['DLs'].forEach(element => {
          this.avialbleUsers.push(element)
        });
        
      }
      this.avialbleUsers.forEach(element => {
        element['selected']=false;
        element['show']=true;
        element['sms']=false;
        if(element['DLName'])
            element['email']=true;
            else 
            element['email']=false;
      });
    });

    // Check if login user is Super User 
    this.adminSub$ = this.geaMesCognitoAuthService.isUserInRole("Admin").pipe(first()).subscribe( out => {
      this._global.admin = out;
      if (this.adminSub$) this.adminSub$.unsubscribe(); // We've got an answer, can unsubscribe now
    });
  }

  ngOnDestroy() {
    this.alarmService.goupdID_userGropSelected=null;
  }

  compareByPlant(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }

  
  checkSlectedAVaialableUser(user){
    if(user['selected'])
      user['selected']=false;
    else
      user['selected']=true;    
  }

  moveUserToTarget(){
    let exist_count=0;
    this.avialbleUsers.forEach(user => {
    if(user['selected'] ){
        if(this.assignedUsers.filter(e => e.UserID === user['UserID']).length == 0 ||
          this.assignedUsers.filter(e => e.DLName === user['DLName']).length == 0 ){
          user['selected']=false;
         this.assignedUsers.push(user);
         ///this.avialbleUsers=this.avialbleUsers.filter(item => item['selected'] !== true);
       }else{
        exist_count++
         this._snackBar.open(exist_count+" User(s) Already Exists", "Error", {
           duration: 5000,
           horizontalPosition: this.horizontalPosition,
           verticalPosition: this.verticalPosition,
         });
       }
    }
         
    });

   //check if DL is aasigned and defualtly enable emial checkbox selection
    this.assignedUsers.forEach(user => {
      user['selected']=false;
      if(user['DLName']){
        user['email']=true;
      }
    });
  }

  moveUserToSource(user){
    user['selected']=true;
    user['email']=false;
    user['sms']=false;
    this.assignedUsers.forEach(user => {
      if(user['selected'] ){
        if(this.avialbleUsers.filter(e => e.UserID === user['UserID']).length == 0 ||
          this.avialbleUsers.filter(e => e.DLName === user['DLName']).length == 0    ){
          this.avialbleUsers.push(user);
        }
       
      }
    });
    this.assignedUsers=this.assignedUsers.filter(item => item['selected'] !== true);
    this.avialbleUsers.forEach(user => {
      user['selected']=false;
    });
  }

  onUserSearch(val){
    var inputSearchVal=this.formGroup.get('searchAvailableUsers').value.toLowerCase();
    this.avialbleUsers.forEach(user => {
    if( (user['First']  && user['First'].toLowerCase().includes(inputSearchVal)) ||
        (user['Last']   && user['Last'].toLowerCase().includes(inputSearchVal))  ||
        (user['DLName'] && user['DLName'].toLowerCase().includes(inputSearchVal)) )
        {
              user['show']=true;
        }else{
              user['show']=false;
        }
    });
  }

  setPlantValue(){
    this.alarmService.plantValue_UserGroups=this.formGroup.get('Plant').value;
  }

  createUserGroup(){
    let finalJSon={};
    let plantVal=this.formGroup.get('Plant').value;
    let user_group_name=this.formGroup.get('userGroupName').value;
    let user_group_description=this.formGroup.get('userGroupDescription').value;
    let group_memebers=[];
    if(!plantVal || !user_group_name || !user_group_description){
      this._snackBar.open("Please fill all the required fields.", "Error", {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
    else if( this.isConfigTypeCreate && (this.assignedUsers.length==0 && this.formGroup.get('linkToTeams').value!=true) ){
      this._snackBar.open("Please assign atleast one User/DL or configure a Teams Channel to create the user group.", "Error", {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
    else{
      this.showSpinnerOnUpdate=true;
      finalJSon['GroupName']=user_group_name;
      if(this.isConfigTypeCreate){
        finalJSon['GroupId']="";
        if(this.alarmTeamChannelInfo && this.formGroup.get('linkToTeams').value==true){
          finalJSon['TeamChannel']=this.alarmTeamChannelInfo['data']['TeamChannel'];
          finalJSon['TeamURL']=this.alarmTeamChannelInfo['data']['TeamURL'];
        }else{
          finalJSon['TeamChannel']="";
          finalJSon['TeamURL']=""; 
        }
      }else{
        finalJSon['GroupId']=this.userGroupDetails['GroupID'].toString();
        if(this.formGroup.get('linkToTeams').value==true){
          if(this.alarmTeamChannelInfo){
            finalJSon['TeamChannel']=this.alarmTeamChannelInfo['data']['TeamChannel'];
            finalJSon['TeamURL']=this.alarmTeamChannelInfo['data']['TeamURL'];
          }else{
            finalJSon['TeamChannel']=this.userGroupDetails['TeamChannel'];
            finalJSon['TeamURL']=this.userGroupDetails['TeamURL'];
          }
        }else{
          finalJSon['TeamChannel']="";
          finalJSon['TeamURL']=""; 
        }
      }
      
      
      finalJSon['Description']=user_group_description;
      finalJSon['Plant']=plantVal['name'];
      this.assignedUsers.forEach(user => {
        let userObj={};
        if(user['UserID']){
               userObj['Type']="user";
               userObj['UserID']= user['UserID'];
               userObj['First']= user['First'];
               userObj['Last']= user['Last'];
               userObj['SMS']= Number(user['sms']);
               userObj['SMSAvailable']= user['SMSAvailable'];
               userObj['Email']= Number(user['email']);
               if(user['SMSPhone']){
                userObj['SMSPhone']= user['SMSPhone'];
               }
            }else{
              userObj['Type']= "DL";
              userObj['DLName']= user['DLName'];
              userObj['DLUsers']=user['DLUsers'];
            }
            group_memebers.push(userObj);
        });
        finalJSon['GroupMembers']=group_memebers;
        this.alarmService.saveAlarmUserGroup(finalJSon).subscribe(res => {
          this.showSpinnerOnUpdate=false;
           if(res['statusMessage']=="User Group members created/updated Successfully"){
              this._snackBar.open("User Group members created/updated Successfully", "Success", {
                duration: 5000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                panelClass: ['Success']
              });
              this._router.navigate(["/userGroups"]);
           }else{
              this._snackBar.open(res['statusMessage'], "Error", {
                duration: 5000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
              });
           }
         });
    }

  }

  subscriptionChange(event,type,user){
    if(type=="email"){
        if(event.checked){
            user['email']=true;
        }else{
            user['email']=false;
        }
    }else if(type=="sms"){
        if(event.checked){
            user['sms']=true;
            if(user['SMSAvailable']==0){
              const dialogRef2 = this.dialog.open(ConfirmationDialog2, {
                width: '480px',
                disableClose: true,
                data: {
                  message: 'Please Enter Mobile Number 2.',
                  buttonText: {
                    ok: 'Submit',
                    cancel: 'Cancel'
                  }
                }
              });

            dialogRef2.afterClosed().subscribe(result => {
              if (result != null && result != "" && result != undefined) {
                if (result.data.EndPoint != "") {
                  user['SMSPhone']=result.data.EndPoint;
                }
              }else{
                user['sms']=false;
              } 
            })
            }
            

        }else{
          user['sms']=0;
        }  
    }
  }

  onNewUSerButtonClick(){
    const dialogRef3 = this.dialog.open(VerifyUserDLPopup, {
      width: '480px',
      disableClose: true,
      data: {
        headerLabel: 'Search New User ( By SSO )',
        verifyButtonLabel:'Verify SSO',
        verifyType:'User',
        buttonText: {
          ok: 'Submit',
          cancel: 'Cancel'
        }
      }
    });

    dialogRef3.afterClosed().subscribe(popupData => {
      if(popupData){
        popupData['data']['show']=true;
        popupData['data']['email']=false;
        popupData['data']['sms']=false;
        if (this.assignedUsers.filter(e => e.UserID === popupData['data']['UserID']).length == 0)
          this.assignedUsers.push(popupData['data']);
        else{
          this._snackBar.open("User Already Exists", "Error", {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      } 
    });
  }
  showErrorOnDLSubscriptionChange(dl_name){
   if(dl_name){
    this._snackBar.open("DL Subscription can't be changed", "Warning", {
      duration: 5000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
   }
  }
  onNewDlButtonClick(){
    const dialogRef4 = this.dialog.open(VerifyUserDLPopup, {
      width: '480px',
      disableClose: true,
      data: {
        headerLabel: 'Search New Distribution List',
        verifyButtonLabel:'Verify DL',
        verifyType:'DL',
        buttonText: {
          ok: 'Submit',
          cancel: 'Cancel'
        }
      }
    });

    dialogRef4.afterClosed().subscribe(popupData => {
      if(popupData){
        popupData['data']['show']=true;
        popupData['data']['email']=true;
        if (this.assignedUsers.filter(e => e.DLName === popupData['data']['DLName']).length == 0){
          this.assignedUsers.push(popupData['data']) 
        }else{
          this._snackBar.open("DL Already Exists", "Error", {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      }
     });
  }

  openSubscriptionRetryPopup(type,data){
    const alarmSubscriptionRetryPopup = this.dialog.open(AlarmSubscriptionRetryPopup, {
      width: '480px',
      disableClose: true,
      data: {
        headerLabel: 'Search New User ( By SSO )',
        type:type,
        data:data,
        buttonText: {
          ok: 'Submit',
          cancel: 'Cancel'
        }
      }
    });

    alarmSubscriptionRetryPopup.afterClosed().subscribe(popupData => {
      if(popupData['users_list'] && popupData['users_list'].length>0){
        let outJson={};
        outJson['userIdList']=popupData['users_list'];
        this.alarmService.AlarmSubscriptionRetry(outJson).subscribe(res => {
          if(res['statusMessage']=="UserProfile Updated Successfully"){
            this._snackBar.open("Re-Sent Confirmation Email.", "Success", {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['Success']
            });
            this.fecthData();
          }else{
            this._snackBar.open("Re-Sent Confirmation Failed.", "Error", {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
          }
         });
      } 
    });
  }

  openAlarmTeamsPopup(){
    let in_json_popup = {}
    if (this.userGroupDetails && this.userGroupDetails['TeamChannel'] && this.userGroupDetails['TeamURL']){
        in_json_popup = this.userGroupDetails;
    }
    this.alarmTeamChannelInfo={};
    const alarmTeamsChannelPopup = this.dialog.open(AlarmTeamsPopup, {
      width: '800px',
      disableClose: true,
      data: {
        headerLabel: 'Search New User ( By SSO )',
        type:'type',
        data: in_json_popup,
        buttonText: {
          ok: 'Submit',
          cancel: 'Cancel'
        }
      }
    });

    alarmTeamsChannelPopup.afterClosed().subscribe(popupData => {
      if(popupData['data']['eventype']=="close" && !(this.userGroupDetails && this.userGroupDetails['TeamURL'])){
          this.formGroup.get("linkToTeams").setValue(0);
      }else if(popupData['data']['eventype']=="update"){
          this.alarmTeamChannelInfo=popupData;
          this.createUserGroup();
      } 
    });
  }

  linkToTeamsSelectionChange(e){
   if(e.checked){
      this.openAlarmTeamsPopup();
    }
  }

}



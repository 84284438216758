
<mat-dialog-content>
    <span style="text-align: center;" class="font_size"><h4 ><b>Alarm Repeat</b></h4></span>
        <table  align="center" style="height: 250px;" class="AlarmRepeatTable">
          <tr >
            <td class="immediate_column">Immediate: <mat-checkbox class="email_checkbox" [(ngModel)]="ImmediateAlarm"> </mat-checkbox></td>
            <td class="repeat_column">Repeat: <input type="number" class="repaet_input" (keyup)="repaetIntervelValueCheck($event)" [(ngModel)]="repaetIntervelValue" (change)="repaetIntervelValueCheck($event)"/> (minutes)</td>
          </tr>
          <tr>
         
          </tr>
        </table>
</mat-dialog-content>


<mat-dialog-actions align="center" >
<div class="warn_text"> Note: Repeat Interval value supports >5 0r =0 values only.</div>
  	<button class="submit"  mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1"  [disabled]="!isrepaetIntervelValueValid">Submit</button>
</mat-dialog-actions>
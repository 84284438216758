import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { HeaderComponent } from './header/header.component';
import {GeaMesNavbarModule}from '@gea-mes/navbar';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatRippleModule } from '@angular/material/core';
import { ToastrModule } from 'ngx-toastr';
import {NavItem,NavItemService} from './nav-item.service';
import { AlarmsComponent } from './alarms/alarms.component';
import { AlarmManagerComponent } from './alarms/alarm-manager/alarm-manager.component';
import { AlarmService } from './alarms/alarm.service';
import { SubtypesComponent } from './alarms/subtypes/subtypes.component';
import { SubtypeParamsComponent } from './alarms/subtypes/subtype-params/subtype-params.component';
import { SubTypeService } from './alarms/subtypes/subtype.service';
import { HttpClientModule } from '@angular/common/http';
import { SubTypeParamService } from './alarms/subtypes/subtype-params/sub-type-param.service';
import { ConfirmationDialog } from './confirmation-dialog.component';
import { FormsModule } from '@angular/forms';
import { Globals } from './app.globals';
import { ConfirmationDialog2 } from './confirmation-dialog2.component';
import { VerifyUserDLPopup } from './verify-user-dl-popup/verify-user-dl-popup.component';
import {SelectUserGroupPopup} from './select-usergroups-popup/select-usergroups-popup.component';
import {AlarmRepeatPopup} from './alarm-repeat-popup/alarm-repeat-popup.component';
import { AlarmTeamsPopup } from './alarm-teams-popup/alarm-teams-popup.component';
import {AlarmSubscriptionRetryPopup} from './alarm-retry-popup/alarm-retry-popup.component';
import { SummaryComponent } from './summary/summary.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserGroupsComponent } from './user-groups/user-groups.component';
import { UserGroupsConfigComponent } from './user-groups-config/user-groups-config.component';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MyalarmsComponent } from './myalarms/myalarms.component';
import { MatSortModule } from '@angular/material/sort';
import { LogOutPageComponent } from './log-out-page/log-out-page.component';
import { MainContentComponent } from './main-content/main-content.component';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import { HelpDocumentComponent } from './help-document/help-document.component';

@NgModule({
    declarations: [
        AppComponent,
        SideNavComponent,
        HeaderComponent,
        AlarmsComponent,
        AlarmManagerComponent,
        SubtypesComponent,
        SubtypeParamsComponent,
        ConfirmationDialog,
        ConfirmationDialog2,
        VerifyUserDLPopup,
        SelectUserGroupPopup,
        AlarmRepeatPopup,
        AlarmTeamsPopup,
        AlarmSubscriptionRetryPopup,
        SummaryComponent,
        UserProfileComponent,
        UserGroupsComponent,
        UserGroupsConfigComponent,
        MyalarmsComponent,
        LogOutPageComponent,
        MainContentComponent,
        HelpDocumentComponent,
    ],
    imports: [
        GeaMesNavbarModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatSelectModule, MatSidenavModule, MatToolbarModule,
        MatRippleModule, MatFormFieldModule, MatButtonModule,
        MatIconModule, MatGridListModule, MatCardModule,
        MatInputModule, MatListModule, MatSnackBarModule, MatDialogModule, MatRadioModule,
        MatCheckboxModule, ReactiveFormsModule, ToastrModule, FormsModule, MatTableModule, MatSlideToggleModule,
        NgxMaterialTimepickerModule, MatProgressSpinnerModule, MatSortModule,MatTooltipModule
    ],
    providers: [AlarmService, SubTypeService, SubTypeParamService, Globals, NavItemService],
    bootstrap: [AppComponent]
})
export class AppModule { }

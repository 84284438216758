import { Injectable } from '@angular/core';
import { Alarm } from './alarm.model';
import { Subscription } from './alarm.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlarmService {
  list: Alarm[];
  subscription: any[];
  selectedAlarm: Alarm;
  objectModel: any;
  enableAlarm: any[];
  systemSelection:boolean=false;
  selectedSystem:string;
  systemList:any[];
  systemConfigList:any[];
  preselectionList:any[];
  alarmSiteData:any[];
  navigationSource:string;
  dropDownSelections:any={};
  plantValue_UserGroups:any={};
  goupdID_userGropSelected;
  emailConfigPopupText:any="Important: Please check your inbox for an email to complete the subscription process.  You will NOT receive any alarm emails until you open and confirm the subscription message.";
  readonly alarmURL = environment.apiGwUrl + "/configurelist";
  readonly AlarmConfiguredRuleAddEdit = environment.apiGwUrl + "/AlarmConfiguredRuleAddEdit";
  readonly AlarmConfiguredRuleDelete = environment.apiGwUrl + "/AlarmConfiguredRuleDelete";
  readonly AlarmSubscriptionAdd = environment.apiGwUrl + "/Subscribe";
  readonly AlarmUnsubscribe = environment.apiGwUrl + "/Unsubscribe";
  readonly AlarmSubscriptionList = environment.apiGwUrl + "/SubscriptionList";
  readonly AlarmConfigurationDisable = environment.apiGwUrl + "/AlarmConfigurationDisable";

  readonly dropdownUrl=environment.apiGwUrl + "/AlarmSubtypeList";
  readonly countUrl= environment.apiGwUrl +"/AlarmDashBoardConfigureList";
  readonly addProfileUrl=environment.userProfileBaseUrl+"/AlarmUserProfileAdd";
  readonly deleteProfileUrl=environment.userProfileBaseUrl+"/AlarmUserProfileDelete";
  readonly myProfileUrl=environment.apiGwUrl+"/AlarmMyConfigureList";
  

  readonly systemListUrl = environment.apiGwUrl + "/getSystemConfig";
  readonly getSitesURL = environment.apiGwUrl + "/AlarmSiteData";
  readonly alarmUserGroupListURL = environment.apiGwUrl + "/AlarmUserGroupList";
  readonly alarmListOfAvaialUsers=environment.apiGwUrl + "/AlarmListAvailUsers";
  readonly saveAlarmUserGroupURL=environment.apiGwUrl + "/AlarmUserGroupUpdate";
  readonly getAlarmUserGroupDetailsURL=environment.apiGwUrl+"/AlarmUserGroupDetails";
  readonly verifyUserURL=environment.apiGwUrl+"/ValidateSSO";
  readonly verifyDLURL=environment.apiGwUrl+"/AlarmValidateDL";
  readonly alarmGroupSubscriptionUpdateURL=environment.apiGwUrl+"/AlarmGroupSubscriptionUpdate";
  readonly alarmRetryURL=environment.apiGwUrl+"/AlarmUserGroupResend";
  readonly validateTeamsCannelURL=environment.apiGwUrl+"/AlarmTestTeamChannel"

  navigatedToUpdateAlramsFrom:string;

  constructor(private http: HttpClient) { }

  refreshList() {
    this.http.get(this.alarmURL)
      .toPromise().then(res => {
        this.http.get(this.AlarmSubscriptionList)
          .toPromise().then(subscriptions => {
            this.list = res as Alarm[];
            this.subscription = subscriptions as any[];

            for (let alarm of this.list) {
              if (alarm.subscriptions == undefined) {
                alarm.subscriptions = new Subscription();
                alarm.subscriptions.email = false;
                alarm.subscriptions.sms = false;
              }
              for (let data of this.subscription) {
                if (data.alarm_id == alarm.alarm_id) {
                  if (data.Protocol == 'email') {
                    alarm.subscriptions.email = true;
                  }
                  if (data.Protocol == 'sms') {
                    alarm.subscriptions.sms = true;
                  }
                }
              }
            }
          });
      });

  }


  formatMultiSelectValue(arr){
    let test_code_value="[";
    if(arr!=null || arr !=undefined){
      for(var i=0;i<arr.length;i++){
        test_code_value+='"'+arr[i].id+'"';
        if(i!=arr.length-1){
          test_code_value+=","
        }
      }
    }

    test_code_value+="]";
    return test_code_value;
}

setTestCodeIncludeMultiSelections(selections,toatlArr){
let preselection=[];
for (let s of selections) {
for(let obj of toatlArr){
    if(obj['id']==s){
      preselection.push(obj);
    }
}

}
return preselection;
}

setTestCodeExcludeMultiSelections(selections,toatlArr){
let preselection=[];
for (let s of selections) {
for(let obj of toatlArr){
    if(obj['id']==s){
      preselection.push(obj);
    }
}

}
return preselection;
}

  setSelectedAlarm(alarm: Alarm) {
    this.selectedAlarm = alarm;
  }
  postAlarm(formData: Alarm) {
    return this.http.post(this.AlarmConfiguredRuleAddEdit, formData);
  }

  putAlarm(formData: Alarm) {
    return this.http.post(this.AlarmConfiguredRuleAddEdit, formData);
  }

  deleteAlarm(id: number) {
    return this.http.put(this.AlarmConfiguredRuleDelete, { alarm_id: id });
  }

  subscribeAlarm(id: number, method: string,ImmediateAlarm:string,RepeatInterval:number) {
    return this.http.post(this.AlarmSubscriptionAdd, { alarm_id: id, method: method,ImmediateAlarm:ImmediateAlarm,RepeatInterval:RepeatInterval });
  }

  unsubscribeAlarm(id: number, method: string) {
    return this.http.put(this.AlarmUnsubscribe, { alarm_id: id, method: method});
  }

  addMobileNumber(Object) {
    return this.http.post(this.addProfileUrl, Object);
  }

  deleteMobileNumber(Object){
    return this.http.put(this.deleteProfileUrl, Object);
  }
  disableAlarm(id: number,alarmDisable: string) {
    return this.http.post(this.AlarmConfigurationDisable, { alarm_id: id, disableAlarm: alarmDisable });
  }

  setObjectModel(objectModel: any) {
    this.objectModel = objectModel;
  }

 // dropdownList(){
  plantList(){
    var url=this.dropdownUrl;
    return this.http.get(url)
  }

  lineList(plantName){
    var url=this.dropdownUrl+"?plantName="+plantName;
    return this.http.get(url)
  }

    subScribeCountUrl(inputParams){
    var dynamicURL=this.countUrl+"?";
    for (var key in inputParams) {
      if(inputParams[key] ){
        dynamicURL+="&"+key+"="+inputParams[key];
      }
     }
    var url=dynamicURL;
    return this.http.get(url);
  }
  subtypeList(inputParams){
    var dynamicURL=this.dropdownUrl+"?system_id="+inputParams.system_id;
    for (var key in inputParams) {
      if(inputParams[key] && key!='system_id'){
        dynamicURL+="&"+key+"="+inputParams[key];
      }
     }
    var url=dynamicURL;
    return this.http.get(url)
  }

  getAlarmsGrousUsersList(inputParams){
    var dynamicURL=this.alarmUserGroupListURL+"?";
    for (var key in inputParams) {
      if(inputParams[key] && key!='system_id'){
        dynamicURL+="&"+key+"="+inputParams[key];
      }
     }
    var url=dynamicURL;

    return this.http.get(url)
  }

  getAlarmsListOfAvaiableUsers(){
    var dynamicURL=this.alarmListOfAvaialUsers;
    //var url=dynamicURL;

    return this.http.get(dynamicURL)
  }

    filterConfigerList(inputParams)
  {
    var dynamicURL=this.alarmURL+"?system_id="+inputParams.system_id;
    for (var key in inputParams) {
      if(inputParams[key] && key!='system_id'){
        dynamicURL+="&"+key+"="+inputParams[key];
      }
     }
    var url=dynamicURL;

    return this.http.get(url)
    
  }

  setSystemSelection(){
    this.systemSelection=true;
  }
  setSelectedSystem(val){
    this.selectedSystem=val;
  }

  getSystemList(){
    return this.http.get(this.systemListUrl);
  }

  getSystemListByPlant(plantId){
    return this.http.get(this.systemListUrl+"?Plant="+plantId); 
  }
  saveSystemList(Obj){
    this.systemList=Obj.Body[0].System;
    // Sorting list alphabatically
    this.systemList.sort(function (x, y) {
      let a = x.system_name.toUpperCase(),
      b = y.system_name.toUpperCase();
     return a == b ? 0 : a > b ? 1 : -1;
   });

    this.systemConfigList=Obj.Body[0].SystemConfig;
  }

  SortOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
  }

  setPreSelections(arr){
    this.preselectionList=[];
    for(let selection of arr){
      this.preselectionList.push(selection)
    }
  }
  myProfileAlarms(){
    var url=this.myProfileUrl;
    return this.http.get(url);
  }

  getSitesData(){
    return this.http.get(this.getSitesURL);
  }

  saveAlarmUserGroup(json){
    return this.http.put(this.saveAlarmUserGroupURL, json);
  }

  validateSSOInput(json){
    var url=this.verifyUserURL+'?UserIDs='+json['userID'];
    return this.http.get(url)
  }

  getAlarmUserGroupDetails(group_id){
    var url=this.getAlarmUserGroupDetailsURL+'?GroupID='+group_id;
    return this.http.get(url)
  }

  validateDLInput(json){
    var url=this.verifyDLURL+"?DLName="+json['DLName'];
    return this.http.get(url)
  }

  AlarmGroupSubscriptionUpdate(json){
    return this.http.put(this.alarmGroupSubscriptionUpdateURL, json);
  }

  AlarmSubscriptionRetry(json){
    return this.http.put(this.alarmRetryURL,json)
  }
  
  validateTeamsChannel(json){
    let url=this.validateTeamsCannelURL+"?TeamChannel="+json['TeamChannel']+"&TeamURL="+json['TeamURL']
    return this.http.get(url)
  }
}

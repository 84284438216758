import { Component, Inject } from '@angular/core';
import {  MatLegacyDialogRef as MatDialogRef,  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {  UntypedFormGroup } from '@angular/forms';
import { AlarmService } from '../alarms/alarm.service';

@Component({
  selector: 'verify-user-dl-popup',
  templateUrl: 'verify-user-dl-popup.html',
  styleUrls: ['verify-user-dl-popup.css']

})
export class VerifyUserDLPopup {
  formGroup: UntypedFormGroup;
  headerLabel: string = "";
  confirmButtonText = "";
  inputLable="";
  outputLable="";
  verifyType="";
  verifyButtonLabel="";
  cancelButtonText = "Cancel";
  isUserVerified:boolean=false;
  isDlVerified:boolean=false;
  showSpinner:boolean=false;
  verifiedUserName:string="";
  verifiedUserDetails;
  verifiedDlUsersDetails;
  userNameInput;
  dlNameInput;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public alarmService: AlarmService,
    private dialogRef: MatDialogRef<VerifyUserDLPopup>) {
      if(data && data.headerLabel){
         this.headerLabel = data.headerLabel;
         this.verifyButtonLabel=data.verifyButtonLabel;
         this.verifyType=data.verifyType;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
      if(data.verifyType=="User"){
        this.inputLable="Enter User SSO:";
        this.outputLable="User Name:";
      }else if(data.verifyType=="DL"){
        this.inputLable="Enter DL Name:";
        this.outputLable="DL Users:"
      }
      }
  }

  numberOnly(event) {
    if(this.verifyType=="User"){
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }
  
  }

  verifyUser(): void {
    this.showSpinner=true;
    let json={};
    json['userID']=this.userNameInput;
    this.alarmService.validateSSOInput(json).subscribe(res => {
      this.showSpinner=false;
      this.isUserVerified=true;
      this.verifiedUserDetails=res['Users'][0];
      this.verifiedUserName=res['Users'][0]['First']+" "+res['Users'][0]['Last'];
    });
     
  }

  verifyDL(){
    this.showSpinner=true;
    let json={};
    json['DLName']=this.dlNameInput.trim();
    this.alarmService.validateDLInput(json).subscribe(res => {
      this.showSpinner=false;
      this.isDlVerified=true;
      this.verifiedDlUsersDetails=res;
    });
  }  

  onConfirmClickUser(): void {
    this.dialogRef.close({event:true,data:this.verifiedUserDetails});
  }

  onConfirmClickDL():void {
    this.dialogRef.close({event:true,data:this.verifiedDlUsersDetails});
  }

}

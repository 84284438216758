import { Component, Inject } from '@angular/core';
import {  MatLegacyDialogRef as MatDialogRef,  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {  UntypedFormGroup } from '@angular/forms';
import { AlarmService } from '../alarms/alarm.service';
import {  MatLegacyCheckbox as MatCheckbox } from '@angular/material/legacy-checkbox';

@Component({
  selector: 'alarm-retry-popup',
  templateUrl: 'alarm-retry-popup.html',
  styleUrls: ['alarm-retry-popup.css']

})
export class AlarmSubscriptionRetryPopup {
  formGroup: UntypedFormGroup;
  showSpinner:boolean=false;
  checkUserGroupData:boolean;
  repaetIntervelValue:number=0;
  ImmediateAlarm :boolean=false;
  isRetryValid:boolean=false;
  user_data;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public alarmService: AlarmService,
    private dialogRef: MatDialogRef<AlarmSubscriptionRetryPopup>) {
    this.user_data=this.data;
    if(this.user_data.data.Type=="DL"){
      this.user_data.data.DLUsers.forEach(user=>{
        user['selectedForRetrySubscription']=false;
      });
    }else{
      this.isRetryValid=true;
    }
           
  }


  onUserSelect(user): void {
     if(user['selectedForRetrySubscription']==true){
      user['selectedForRetrySubscription']=false;
     }else{
      user['selectedForRetrySubscription']=true;
     }
     let users_list=[];
     this.user_data.data.DLUsers.forEach(user=>{
      if(user['selectedForRetrySubscription']==true)
      {
        users_list.push(user['UserID'])
      }
    });
    if(users_list.length>0){
      this.isRetryValid=true;
    }else{
      this.isRetryValid=false;
    }

   }

  onConfirmClick(): void {
    let outJson={};
    let users_list=[];
    if(this.user_data.data.Type=="DL"){
      this.user_data.data.DLUsers.forEach(user=>{
        if(user['selectedForRetrySubscription']==true)
        {
          users_list.push(user['UserID'])
        }
      });
    }else{
      users_list.push( this.user_data.data['UserID'])
    }
    this.dialogRef.close({event:true,users_list:users_list});
    
    
  }

  onCancelClick():void{
    this.dialogRef.close({event:true,users_list:[]});
  }




}

import { Injectable } from '@angular/core';
import { SubTypeParam } from './subtypeparam.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubTypeParamService {
  list: SubTypeParam[];
  readonly rootURL = environment.apiGwUrl + "/AlarmListingSubTypesParameters"
  constructor(private http: HttpClient) { }

  aSynchRefreshList(id: number) {
    return this.http.get(this.rootURL + "?subtype_id=" + id);
  }

}


<mat-dialog-content>
  <mat-spinner [diameter]="75" *ngIf="showSpinner" strokeWidth="15"></mat-spinner>
    <span style="text-align: center;" class="font_size"><h4 ><b>Alarm Teams Support</b></h4>
      <img class="iconDisplay" style="float:right;width:30px;margin-top: -37px;cursor: pointer;" src="./assets/images/help.png" 
      matTooltip="Help Document" (click) = "openHelpDocument()">
    </span>
        <table  align="center" style="height: 550px;" class="teamsChannelTable">
          <tr >
            <td class="team_channel_table_td">Teams Channel Name: <input type="text" class="inputField" [(ngModel)]="teamsChannelName" (keyup)="inputChange()"/></td>
          </tr>
          <tr>
            <td class="repeat_column">
              <span style="vertical-align: 50px;">Teams Channel URL  : </span> 
              <textarea class="inputField"  style="height:65px;"  [(ngModel)]="teamsChannelURL" (keyup)="inputChange()"></textarea>
            </td>
          </tr>
        </table>
</mat-dialog-content>


<mat-dialog-actions align="center" >
    <button class="submit"  mat-raised-button color="primary" (click)="validateChannel()" tabindex="1"  [disabled]="!isInputHasValue">Submit</button>
  	<button class="submit"  mat-raised-button color="primary" (click)="onCancelClick()" tabindex="1"  >Cancel</button>
</mat-dialog-actions>
export class Alarm {
  alarm_id: number;
  subtype_id: number;
  short_desc: string;
  desc: string;
  updated_by: string;
  updated_time: string;
  criterion: string;
  subscriptions: Subscription;
  disableAlarm: number;
}

export class Subscription {
  email: boolean;
  sms: boolean;
}



<mat-dialog-content>

    <span style="text-align: center;" class="font_size"><h4 ><b>User Profile</b></h4></span>
	<!-- {{message}} -->
    
       
        
        <table align="center" style="margin-top: 50px;">
            <!-- <label class="phn_label">Enter Phone Number</label> -->
            <tr *ngFor="let mobile of mobileNumbers;let i=index">
                <td colspan="2"> <label class="phn_label">Enter Phone Number</label> <input  class="mobile_number_input" maxlength="14" (keypress)="numberOnly($event)" [(ngModel)]="mobile.mobileNumber" (ngModelChange)="valuechange($event,i)" (keypress)="formatPhoneNumber(i)">  </td>
                <!-- <td *ngIf="mobile.del"> <button  mat-raised-button (click)="deletePhoneNumber(i)" color="warn" >-</button> </td> -->
            </tr> 
             <tr *ngIf="showRepeatEnableFields">
              <td class="immediate_column" style="padding-top: 10px">Immediate: <mat-checkbox class="email_checkbox" [(ngModel)]="ImmediateAlarm"> </mat-checkbox></td>
              <td class="repeat_column" style="padding-top: 10px">Repeat: <input type="number" class="repaet_input" [(ngModel)]="repaetIntervelValue" (keyup)="repaetIntervelValueCheck($event)"  (change)="repaetIntervelValueCheck($event)"/> (minutes)</td>
            </tr>   
        </table> 
                 
   
    
</mat-dialog-content>


<mat-dialog-actions align="center" style="margin-top: 10px !important;margin-bottom:5px">
	<div class="warn_text" *ngIf="showRepeatEnableFields"> Note: Repeat Interval value supports >5 0r =0 values only.</div>
	<button class="submit"  mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1" [disabled]="showRepeatEnableFields && !isrepaetIntervelValueValid">{{confirmButtonText}}</button>
	<button class="cancel"  mat-raised-button mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
</mat-dialog-actions>

<div class="help-doc-container">
<div class="page-heading"><b>Help Document For Configuring A Teams Channel For Alarms</b></div>
<div class="step-div">
    <div class="step-number">Step 1</div>
    <div class="step-content">
        Open Microsoft Teams application and click on "Teams" option and choose "See all channels", 
        Then use either existing channel or create new channel to link channel with a user group.<br/><br/>
        <h5><b>New Channel</b></h5>
         Click on "+" sign and Add channel. After creating, proceed to step-2<br/><br/>
        <h5><b>Existing Channel</b></h5>
        If you want to use existing channel, directly proceed to step-2
    </div>
    <div class="step-images">
        <img src="../../assets/images/help-document-images-latest/image1.png" width="100" height="100">
    </div>
</div>

<div class="step-div">
    <div class="step-number">Step 2</div>
    <div class="step-content">Select & right click on teams channel and then click on Manage Channel <br/><br/> 
        Select Edit
    </div>
    <div class="step-images"> 
        <img src="../../assets/images/help-document-images-latest/image2.png" width="100" height="100">
    </div>
</div>

<div class="step-div">
    <div class="step-number">Step 3</div>
    <div class="step-content">If this Incoming WebHook is not listed, jump to appendix (refer the appendix at the bottom of this document) to add it to your 
        teams apps.   Then come back at the edit step.<br/><br/>
        Click Configure
    </div>
    <div class="step-images"> 
        <img src="../../assets/images/help-document-images-latest/image3.png" width="100" height="100">
    </div>
</div>


<div class="step-div">
    <div class="step-number">Step 4</div>
    <div class="step-content"> Enter a Name for the connection and then click "Create"
    </div>
    <div class="step-images"> 
        <img src="../../assets/images/help-document-images-latest/image4.png" width="150" height="50">
    </div>
</div>

<div class="step-div">
    <div class="step-number">Step 5</div>
    <div class="step-content"> Copy the URL listed. You can paste the same in Brilliant Factory Alarm Application.<br/><br/>
    Click Done.
    </div>
    <div class="step-images"> 
        <img src="../../assets/images/help-document-images-latest/image5.png" width="150" height="50">
    </div>
</div>

<div class="step-div">
    <div class="step-number">Step 6</div>
    <div class="step-content"> Open Brilliant Factory Alarm Application.<br/>Create or Select group.<br/>
        Click the Check box for Link Teams Channel.
    </div>
    <div class="step-images"> 
        <img src="../../assets/images/help-document-images-latest/image6.png" width="150" height="50">
    </div>
</div>

<div class="step-div">
    <div class="step-number">Step 7</div>
    <div class="step-content"> Enter the Name of the Channel(need not be match with teams channel name) and copy the URL from the teams web hook configuration.<br/><br/>
        Click Submit
    </div>
    <div class="step-images"> 
        <img src="../../assets/images/help-document-images-latest/image7.png" width="150" height="50">
    </div>
</div>

<div class="step-div">
    <div class="step-number"></div>
    <div class="step-content"> This should send a TEST Message and Report success at the top of the screen.
    </div>
    <div class="step-images"> 
        <img src="../../assets/images/help-document-images-latest/image8.png" width="150" height="50">
    </div>
</div>

<div class="step-div">
    <div class="step-number"></div>
    <div class="step-content"> Open Microsoft Teams Application and go the channel. You can find the test message.
    </div>
    <div class="step-images"> 
        <img src="../../assets/images/help-document-images-latest/image9.png" width="150" height="50">
    </div>
</div>

<div class="page-heading appendix-heading"><b><u>Appendix</u></b></div>

<div class="step-div">
    <div class="step-number"></div>
    <div class="step-content">Select high level team. <br/><br/> 
        Click on the 3 dots and then select Manage team option
    </div>
    <div class="step-images"> 
        <img src="../../assets/images/help-document-images-latest/Appendix-Image1.png" width="100" height="100">
    </div>
</div>

<div class="step-div">
    <div class="step-number"></div>
    <div class="step-content">Select Apps tab</div>
    <div class="step-images"> 
        <img src="../../assets/images/help-document-images-latest/Appendix-Image2.png" width="100" height="100">
    </div>
</div>

<div class="step-div">
    <div class="step-number"></div>
    <div class="step-content">Click on get more Apps & Search for Incoming WebHook.<br/>
        Click on Add
    </div>
    <div class="step-images"> 
        <img src="../../assets/images/help-document-images-latest/Appendix-Image3.png" width="100" height="100">
    </div>
</div>

<div class="step-div">
    <div class="step-number"></div>
    <div class="step-content">Click Add to Team
    </div>
    <div class="step-images"> 
        <img src="../../assets/images/help-document-images-latest/Appendix-Image4.png" width="100" height="100">
    </div>
</div>

<div class="step-div">
    <div class="step-number"></div>
    <div class="step-content">Close Window. DO NOT Configure a connector from this window.  It will place at top level of team. 
    </div>
    
</div>



</div>
import { Component, Inject } from '@angular/core';
import {  MatLegacyDialogRef as MatDialogRef,  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {  UntypedFormGroup } from '@angular/forms';
import { AlarmService } from '../alarms/alarm.service';
import {  MatLegacyCheckbox as MatCheckbox } from '@angular/material/legacy-checkbox';

@Component({
  selector: 'select-usergroups-popup',
  templateUrl: 'select-usergroups-popup.html',
  styleUrls: ['select-usergroups-popup.css']

})
export class SelectUserGroupPopup {
  formGroup: UntypedFormGroup;
  showSpinner:boolean=false;
  userGroupList=[];
  checkUserGroupData:boolean;
  showRepeatEnableFields:boolean=false;
  isrepaetIntervelValueValid:boolean=true;
  alarm_data;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public alarmService: AlarmService,
    private dialogRef: MatDialogRef<SelectUserGroupPopup>) {
      let plant_name=this.data.Plant;
      this.showRepeatEnableFields=this.data.showRepeatEnableFields;
      this.alarm_data=data.alarm_data;
      let inputParams = {};
      inputParams['Plant']=plant_name;
      this.showSpinner=true;
      this.alarmService.getAlarmsGrousUsersList(inputParams).subscribe(resp => {      
        this.userGroupList=resp['Body'];
        this.showSpinner=false;
        let datacheck = this.userGroupList
        if (datacheck.length>0) {
        this.userGroupList.forEach(usrGrp => {
          usrGrp['checked']=false;
          usrGrp['EmailImmedidateAlarm']=false;
          usrGrp['EmailRepeatInterval']="0";
          usrGrp['isrepaetIntervelValueValid']=true;
          this.alarm_data['subscriptionData'].some((el) => { 
            if(el.GroupId == usrGrp['GroupID']){
              usrGrp['checked']=true;
               usrGrp['EmailImmedidateAlarm']=el['EmailImmedidateAlarm'];
               usrGrp['EmailRepeatInterval']=el['EmailRepeatInterval'];

            }
           })
        });
      }
      else{
        this.checkUserGroupData = true;
      }
      });
  }

  userGroupSelectionChange(check: MatCheckbox,user_group){
    if(check.checked){
      user_group['checked']=true;
    }else{
      user_group['checked']=false; 
    }
  }

  ImmediateAlarmchange(check: MatCheckbox,user_group){
    if(check.checked){
      user_group['ImmediateAlarm']=1;
    }else{
      user_group['ImmediateAlarm']=0; 
    }
  }

  
  onConfirmClick(): void {
    let outJson={};
    let groupIDList=[];
    this.userGroupList.forEach(userGrp => {
      if(userGrp['checked']){
        let tempObj={};
        tempObj['groupId']=userGrp['GroupID'];
        if(userGrp['ImmediateAlarm'] && userGrp['ImmediateAlarm']!=""){
          tempObj['ImmediateAlarm']="1";
        }else{
         tempObj['ImmediateAlarm']="0";
        }
          tempObj['RepeatInterval']=userGrp['EmailRepeatInterval'];
        groupIDList.push(tempObj)
      }
    });
    outJson['groupData']=groupIDList;
    outJson['alarmId']=this.alarm_data['alarm_id'];
    this.dialogRef.close({event:true,data:outJson});
  }

  onCancelClick():void{

  }
  
  repaetIntervelValueCheck(usergrp){
  this.isrepaetIntervelValueValid=true;
   if (usergrp['EmailRepeatInterval']>=5 || usergrp['EmailRepeatInterval']==0) {
      usergrp['isrepaetIntervelValueValid']=true;
    }
    else if(usergrp['EmailRepeatInterval']==null){
	    usergrp['isrepaetIntervelValueValid']=true;
	    usergrp['EmailRepeatInterval']=0;
    }
    else{
    	usergrp['isrepaetIntervelValueValid']=false;
    }
    this.userGroupList.forEach(usrGrp => {
     if(usrGrp['isrepaetIntervelValueValid']==false){
     this.isrepaetIntervelValueValid=false;
     }
    
    });
    
  }



}

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AlarmService } from '../alarm.service';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SubTypeService } from '../subtypes/subtype.service';
import { SubTypeParam } from '../subtypes/subtype-params/subtypeparam.model';
import { SubTypeParamService } from '../subtypes/subtype-params/sub-type-param.service';
import { SubType } from '../subtypes/subtype.model';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { tap, map, filter, pluck } from 'rxjs/operators';
import { environment } from '../../../../src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-alarm-manager',
  templateUrl: './alarm-manager.component.html',
  styleUrls: ['./alarm-manager.component.css']
})
export class AlarmManagerComponent implements OnInit {
  formGroup: UntypedFormGroup;
  type: boolean = true;
  subTypeParamslist: SubTypeParam[];

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;
  comparePlantLine:  ((f1: any, f2: any) => boolean) | null = this.compareByPlantLine;
  compareSystem:((f1: any, f2: any) => boolean) | null = this.compareBySystem;
  compareDynamic: ((f1: any, f2: any) => boolean) | null = this.compareDynamicValue;

  context: Subject<any> = new ReplaySubject(1)
  selected$: Subject<any> = new Subject()
  treeNodes: Observable<any>[]
  treeSelections: Observable<any>[]
  selections: Map<string, any>
  subtypeList:any=[];
  savedSubtypeList:any=[];
  plantList:any=[];
  lineList:any=[];
  testCodeList:any=[];
  sectionList:any=[];

  dynamicCodes:any=[];
  disableTestCodes:boolean;
  isSubtypeSelected:boolean=false;
  isSytemSelected:boolean=false;
  hasPlantSelectedInService:boolean=false;
  tempVarible:any={};
  siteData:any=[];
  lineData:any=[];
  isAlarmTypeExiration:boolean=false;
  isAlarmTypeExirationClick:boolean=false;


  tree = [];

  constructor(private formBuilder: UntypedFormBuilder,
    public alarmService: AlarmService,
    public subTypeService: SubTypeService,
    private subTypeParamService: SubTypeParamService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private _router: Router,
    private http: HttpClient,
  ) {
    this.formGroup = this.formBuilder.group({
      alarm_id: [],
      subtype_id: [],
      desc: [],
      updated_by: [],
      subTypeControl: [],
      criterion: [],
      system:[]
    });

    this.treeNodes = [];  //the different nodes of selection
    this.treeSelections = [];  //what is selected
    this.selections = new Map<string, any>();
  }

  ngOnInit() {

    this.alarmService.getSitesData().subscribe(res => {
      this.siteData=res['Body']; 
      this.alarmService.alarmSiteData=res['Body']; 
    });

    this.subTypeService.getSubtypes(null).subscribe(data=>{
      this.subtypeList=data;
      this.savedSubtypeList=data;

     
      
      setTimeout(() => {

          //To Pre Select System
      if(this.alarmService.selectedSystem!=undefined){
        this.formGroup.get('system').setValue(this.alarmService.selectedSystem);
        this.isSytemSelected=true;
        if(this.type){
          this.filterSubtypeList();
        }
      }
      
        if(this.subTypeService.selectedSubType!=undefined){
            const select_subtype = this.subtypeList.find(c => c.subtype_id == this.subTypeService.selectedSubType.subtype_id);
            this.formGroup.get('subTypeControl').setValue(select_subtype);
            this.onSubTypeSelect();
        }
      }, 1000);  

   });


    // if(this.subTypeService.selectedPlant!=undefined){
    //   this.hasPlantSelectedInService=true;
    // }


    if(this.alarmService.preselectionList && this.alarmService.preselectionList[0]){
      this.hasPlantSelectedInService=true;
    }

     // To Fecth System List
     this.alarmService.getSystemList().subscribe(res => {
      this.alarmService.saveSystemList(res);
    });




    //load SubTypes
    this.route.data.subscribe(v => {
      if (v.type == 'create') {
        this.type = true;
        this.disableTestCodes=true;
        this.formGroup.get('subTypeControl').enable();
        this.alarmService.navigationSource="alarms";
      } else {

        this.type = false;
        this.formGroup.get('subTypeControl').disable();
        this.formGroup.get('system').disable();
        this.route.queryParams.subscribe((params) => {
          if(params['alarmID']){
            this.isAlarmTypeExiration=true;
            let inputParams={};
            inputParams['Plant']=params['plantID'];
            this.alarmService.subScribeCountUrl(inputParams).subscribe(data => {
              let isAlarmFound=false;
              data['Body']['systemdata'].forEach(element => {
                element['alamData'].forEach(el => {
                if(Number(el['alarm_id'])==Number(params['alarmID'])){
                  isAlarmFound=true;
                   this.alarmService.selectedAlarm=el;
                   let subtypeFromSummary:any={};
                   this.alarmService.setSystemSelection();
                   this.alarmService.setSelectedSystem(element['system_id']);
                   subtypeFromSummary['subtype_id'] = el['subtype_id'];
                   subtypeFromSummary['sub_type'] = el['sub_type'];
                   this.subTypeService.setSelectedSubType(subtypeFromSummary);
                   this.alarmService.navigatedToUpdateAlramsFrom="summary";
                   this.alarmService.navigationSource="summary";
                   this.formGroup.patchValue(this.alarmService.selectedAlarm);
                 }
                });
               });
               if(!isAlarmFound){
                alert("Alarm Not Found. Navigating to Home Screen.");
                this._router.navigate(["/summary"]);
               }
            });
          }else{
            this.isAlarmTypeExiration=false;
            this.formGroup.patchValue(this.alarmService.selectedAlarm);
          }
        });
      }
    });


  }

  deselectAll(subtype){
    this.formGroup.get(subtype).setValue([]);
  }


  getAPIBindingList(inputJson,lamdaname,indexValue){
      inputJson['display_ord']=this.subTypeParamslist[indexValue]['display_ord'];
      let checkTestCodesURL =environment.baseURL+"/"+lamdaname;
      this.http.post(checkTestCodesURL,inputJson).toPromise().then(data => {
      this.subTypeParamslist[indexValue]['listValue']=data['Body'];
      this.disableTestCodes=false;
      if(!this.type){
        let jsonString: any = this.alarmService.selectedAlarm.criterion;
        if(this.subTypeParamslist[indexValue]['API_Binding_Attribute'] && this.subTypeParamslist[indexValue]['paramType']==='set'){
          let preselection=this.alarmService.setTestCodeIncludeMultiSelections(jsonString[this.subTypeParamslist[indexValue]['name']],this.subTypeParamslist[indexValue]['listValue']);
          this.formGroup.get(this.subTypeParamslist[indexValue]['name']).setValue(preselection);
        }

        if(this.subTypeParamslist[indexValue]['API_Binding_Attribute'] && this.subTypeParamslist[indexValue]['paramType']==='scalar'){
          let val=this.subTypeParamslist[indexValue].name;
          let list=this.subTypeParamslist[indexValue]['listValue']
          for(var i=0;i<list.length;i++){
            if(list[i]['id']==jsonString[val]){
              this.formGroup.get(val).setValue(list[i]);
            }
          }
         

        }


      }
    });
  }


  compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1.subtype_id === f2.subtype_id;
  }
  compareByPlantLine(f1: any, f2: any){
    return f1 && f2 && f1.name === f2.name;
  }

  compareBySystem(f1: any, f2: any) {
    return f1 && f2 && f1 === f2;
  }

  onSubTypeSelect() {
    this.isSubtypeSelected=true;
    let temLineList=[];
    //Remove components from UI
    if (this.subTypeParamslist != undefined) {
      for (let subTypeParam of this.subTypeParamslist) {
        this.formGroup.removeControl(subTypeParam.name);
        this.hasPlantSelectedInService=false;
      }
    }
    this.tree = [];
    this.treeNodes=[];
    //set SubType
    this.subTypeService.setSelectedSubType(this.formGroup.get('subTypeControl').value);
    this.formGroup.get('subtype_id').setValue(this.formGroup.get('subTypeControl').value.subtype_id);
    //Add components to UI
    this.subTypeParamService.aSynchRefreshList(this.formGroup.get('subTypeControl').value.subtype_id).subscribe(data => {
      this.subTypeParamslist = data as SubTypeParam[];
      this.getAPIBindingParams(this.subTypeParamslist);
      //this.getOptions();
      let count = 0;
      let treeCount = 0;
      for (let subTypeParam of this.subTypeParamslist) {
        if (this.type) {
          this.formGroup.addControl(subTypeParam.name, new UntypedFormControl(subTypeParam.default_value));
          if(subTypeParam['API_Binding_Attribute']){
            let tempObj={};
            tempObj['subtype']=subTypeParam.name;
            tempObj['lamdaKey']=subTypeParam['API_Binding_Attribute'];
            this.dynamicCodes.push(tempObj)

          }
        } else {
          let jsonString: any = this.alarmService.selectedAlarm.criterion;
          this.formGroup.addControl(subTypeParam.name, new UntypedFormControl(jsonString[subTypeParam.name]));
          if (subTypeParam.API_Binding == 1 ) {

            if (subTypeParam.name=="Plant"){
              this.alarmService.alarmSiteData.forEach(object => {
                if (object['id'] == jsonString[subTypeParam.name] ) {
                  temLineList=this.alarmService.alarmSiteData.find(x => x.id === object['id']).lines;
                  this.formGroup.get(subTypeParam.name).setValue(object['name']);
                  this.formGroup.get(subTypeParam.name).disable();
                }
              });
            }

            if(subTypeParam.name=="Line"){
              temLineList.forEach(object => {
                if (object['id'] == jsonString[subTypeParam.name] ) {
                  this.formGroup.get(subTypeParam.name).setValue(object['name']);
                  this.formGroup.get(subTypeParam.name).disable();
                } 

              });
            }
            this.formGroup.addControl(subTypeParam.name, new UntypedFormControl(jsonString[subTypeParam.name]));
          }else if(subTypeParam['API_Binding_Attribute'] !=null){
            this.formGroup.addControl(subTypeParam.name, new UntypedFormControl(jsonString[subTypeParam.name]));
          }else {
            this.formGroup.addControl(subTypeParam.name, new UntypedFormControl(jsonString[subTypeParam.name]));
          }
        }
        if (subTypeParam.user_editable && subTypeParam.API_Binding != 1) {
          this.formGroup.get(subTypeParam.name).enable();
        } else if (!subTypeParam.user_editable) {
          this.formGroup.get(subTypeParam.name).disable();
        }
        count++;
      }

      if(!this.type){
       // this.checkForDataBindingAPIS();
       this.updateAlarmAPIBindingAttributeLists();
      }

    });
  }

  getAPIBindingParams(subTypeParamslist) {
    for (let subTypeParam of this.subTypeParamslist) {
      if (subTypeParam.API_Binding == 1) {
        this.tree.push(subTypeParam.API_Key);
      }

    }
  }

  onExpireAlarmUpdate(){
    this.isAlarmTypeExirationClick=true;
    this.onFormSubmit(this.formGroup) 
  }

  onFormSubmit(form: UntypedFormGroup) {

      this.validateIncludeExcludeFields();
     if(!this.validateRequiredData(this.subTypeParamslist)){

      this._snackBar.open("Please enter required fields.", "", {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
       panelClass: ['alert-success'],
      });

     }else if(!this.formGroup.valid){
      this._snackBar.open("Please enter valid inputs.", "", {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
       panelClass: ['alert-success'],
      });
     }

     else{

    let value = '{';
    let count = 0;
    let alarmDescription = '';
    alarmDescription = this.formGroup.get('subTypeControl').value.short_desc;

    for (let subTypeParam of this.subTypeParamslist) {
     // if(subTypeParam['API_Binding_Attribute'] !=null ){
        if(subTypeParam['API_Binding_Attribute'] !=null && subTypeParam['paramType']=="set"){
        let testcode_include_value;
              if(this.formGroup.get(subTypeParam.name).value){
              testcode_include_value=this.alarmService.formatMultiSelectValue(this.formGroup.get(subTypeParam.name).value)
              }else{
                testcode_include_value="[]";
              }
            value += '"' + subTypeParam.name + '" : ' + testcode_include_value +'';

      } else if(subTypeParam['API_Binding_Attribute'] !=null && subTypeParam['paramType']=="scalar"){
        value += '"' + subTypeParam.name + '" : "' + this.formGroup.get(subTypeParam.name).value.id + '"';
      }
      else{

            if (subTypeParam.API_Binding == 1 && this.type) {
                value += '"' + subTypeParam.name + '" : "' + this.formGroup.get(subTypeParam.name).value.id + '"';
                alarmDescription += " - " + this.formGroup.get(subTypeParam.name).value.name;
            } else if (subTypeParam.API_Binding != 1 && subTypeParam.paramType == 'scalar') {
              //value += '"' + subTypeParam.name + '" : "' + this.formGroup.get(subTypeParam.name).value + '"';

              if(this.formGroup.get(subTypeParam.name).value!=null){
                value += '"' + subTypeParam.name + '" : "' + this.formGroup.get(subTypeParam.name).value + '"';
              }else{
                value += '"' + subTypeParam.name + '" : ""';
              }
            } else if (subTypeParam.API_Binding == 1 && !this.type) {
            //let jsonString: any = JSON.parse(this.alarmService.selectedAlarm.criterion);
              let jsonString: any = this.alarmService.selectedAlarm.criterion;
              value += '"' + subTypeParam.name + '" : "' + jsonString[subTypeParam.name] + '"';
              alarmDescription += " - " + this.formGroup.get(subTypeParam.name).value;
            } else if (subTypeParam.API_Binding != 1 && subTypeParam.paramType == 'set') {
              let exclude = this.formGroup.get(subTypeParam.name).value;
              value += '"' + subTypeParam.name + '" : [';
              let splittedCount = 0;
              if (exclude != null && exclude.length > 0) {
                var splitted;
                var splittedArray;
                if (Object.prototype.toString.call(exclude) === '[object Array]') {
                  splitted = new Set(exclude);
                } else {
                  splittedArray = this.formGroup.get(subTypeParam.name).value.split(",");
                  splitted = new Set(splittedArray);
                }
                splitted.forEach(function(item) {
                  value += '"' + item + '"'
                  if (splittedCount != splitted.size - 1) {
                    value += ', ';
                  }
                  splittedCount++;
                });
              }
              value += ']'

            }

      }



      if (count != this.subTypeParamslist.length - 1) {
        value += ', ';
      }
      count++;
    }
    value += '}';

    this.formGroup.get('criterion').setValue(value);
    this.formGroup.get('desc').setValue(alarmDescription);
    if (form.value.alarm_id == null)
      this.insertRecord(form);
    else{
      if(this.isAlarmTypeExirationClick){
        this.formGroup.addControl("isExpirationDateUpdate", new UntypedFormControl(true));
      }else{
        this.formGroup.addControl("isExpirationDateUpdate", new UntypedFormControl(false)); 
      }
      this.updateRecord(form);
    }
     }

  }


  insertRecord(form: UntypedFormGroup) {
    this.alarmService.postAlarm(form.value).subscribe(res => {
      this._snackBar.open("Record creation Success", " ", {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
       panelClass: ['alert-success'],
      });
      this.type = false;
      this.checknavigation();
     
    });

  }

  updateRecord(form: UntypedFormGroup) {
    this.alarmService.putAlarm(form.value).subscribe(res => {
      this._snackBar.open("Record Updation Success", " ", {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
      this.checknavigation();
      //this.alarmService.refreshList();
    });
  }

  validateRequiredData(arr){
    let isDataValid=true;
    for(let i=0;i<arr.length;i++){
      let frmCntrlName=arr[i]['name'];
      let frmCntrlValue=this.formGroup.get(frmCntrlName).value;
      if(arr[i]['mandatory_field']==1 && ( frmCntrlValue==null || frmCntrlValue=="")){
        isDataValid=false;
      }
    }
     return isDataValid;
  }

  onSystemSelect(){
    
    let plantList=this.alarmService.alarmSiteData;
    let filteredPlantList=[];
    plantList.forEach((element,index) => {
      if(element['alarmSystemId'].includes(this.formGroup.get('system').value)){
        filteredPlantList.push(element);
      }
    });
    this.siteData=filteredPlantList;
    this.alarmService.setSystemSelection();

    this.alarmService.setSelectedSystem(this.formGroup.get('system').value);
    this.isSytemSelected=true;

    if(this.type){
      this.filterSubtypeList(); // To Filter Subtype List Based on System
      if (this.subTypeParamslist != undefined) {
          for (let subTypeParam of this.subTypeParamslist) {
            //clearing existing form data
            this.formGroup.get(subTypeParam.name).setValue('');
            this.formGroup.get(subTypeParam.name).clearValidators();
            this.formGroup.get(subTypeParam.name).updateValueAndValidity();
          }
      }
    }



  }

  filterSubtypeList(){
    let systemId=this.formGroup.get('system').value;
    var tempObj={};
    var tempSubTypeListArr=[];
    for(var i=0;i<this.savedSubtypeList.length;i++){
      if(this.savedSubtypeList[i].system_id==systemId){
        tempObj=this.savedSubtypeList[i];
        tempSubTypeListArr.push(tempObj);
        tempObj={};
      }
    }
    this.subtypeList=tempSubTypeListArr;
  }

  validateIncludeExcludeFields(){
    let regularExp = new RegExp("^[,-]*$");
      for (let subTypeParam of this.subTypeParamslist) {
         if(subTypeParam.name=="Exclusion" || subTypeParam.name=="Exclusion Station" ||
         subTypeParam.name=="Xclude_Station" || subTypeParam.name=="Include Station"){
             let inputValue=this.formGroup.get(subTypeParam.name).value;
             if(inputValue != null){
              let endCharacter = inputValue.slice(-1);
              if(regularExp.test(endCharacter)){
                let removeSplChar = inputValue.slice(0, -1);
                this.formGroup.get(subTypeParam.name).setValue(removeSplChar);
              }
            }
        }
      }
    }
    restrictSpecialCharachters(evt: any,paramName: String): boolean{
      if(paramName=="Exclusion" || paramName=="Exclusion Station" ||
      paramName=="Xclude_Station" || paramName=="Include Station"){
      let regEx = new RegExp("^[0-9,-]*$");
         if (regEx.test(evt.key)) {
          return true;
         } else {
          return false;
        }
       }
       //this.checkForDataBindingAPIS();
    }

    checkForDataBindingAPIS(index){
      let inMandatoryFilled=false;
      for (let i=0;i<index;i++) {
        let val=this.subTypeParamslist[i]
        if(val['mandatory_field']=="1" && this.formGroup.get(val['name']).value !=null ){
          inMandatoryFilled=true;
        }
        else if(val['mandatory_field']=="1" && this.formGroup.get(val['name']).value ==null ){
          inMandatoryFilled=false;
        }

      }
      if(inMandatoryFilled){
        let inputJson={};
        for (let i=0;i<index;i++) {
          let val=this.subTypeParamslist[i];
          if(val['API_Binding_Attribute'] && val['paramType']=="scalar"){
           let tempObj={};
           tempObj['Description']=this.formGroup.get(val['name']).value.id;
           tempObj['id']=this.formGroup.get(val['name']).value.id;
           inputJson[val['name']]=tempObj;
           tempObj={};
          }else if( val['API_Binding_Attribute'] && val['paramType']=="set"){
             inputJson[val['name']]=this.alarmService.formatMultiSelectValue(this.formGroup.get(val['name']).value);
          }else{
            if(val['name']!="Plant" && val['name']!="Line"){
              inputJson[val['name']]=this.formGroup.get(val['name']).value;
            }
            if(val['name']=="Plant" || val['name']=="Line"){
              inputJson[val['name']]=this.formGroup.get(val['name']).value.id;
            }
          }

        }

         inputJson['system']=this.formGroup.get('system').value;
         inputJson['subtype_id']=this.formGroup.get('subTypeControl').value.subtype_id;
         inputJson['subTypeControl']=this.formGroup.get('subTypeControl').value;

         if(!this.type){
          let jsonString: any = this.alarmService.selectedAlarm.criterion;
          inputJson['Plant']=jsonString['Plant'];
          inputJson['Line']=jsonString['Line'];

         }
      
         if(!this.subTypeParamslist[index]['listValue']){
           this.getAPIBindingList(inputJson,this.subTypeParamslist[index]['API_Binding_Attribute'],index);
         }
      }else{
        this._snackBar.open("Please fill all required fields.", "", {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition
        });
      }
    }

  

    compareDynamicValue(f1: any, f2: any){
      return f1 && f2 && f1.id === f2.id;
    }
    
    clearLists(index){
       for (var i=index+1; i<this.subTypeParamslist.length;i++) {
          if(this.subTypeParamslist[i]['API_Binding_Attribute'] && this.subTypeParamslist[i]['listValue']){
            delete this.subTypeParamslist[i]['listValue'];
          }
          }
    }
    
    updateAlarmAPIBindingAttributeLists(){
      for (var i=0; i<this.subTypeParamslist.length;i++) {
          var value= this.subTypeParamslist[i]['API_Binding_Attribute'];
            if(value != null){
                 this.formInputJsonForAPIBindingAttributeLists(this.subTypeParamslist);
                 break;
             }
          }
    }

    formInputJsonForAPIBindingAttributeLists(arr){
      let inputJson={};
        for (let i=0;i<this.subTypeParamslist.length;i++) {
          let val=this.subTypeParamslist[i]
         if(val['API_Binding_Attribute'] && val['paramType']=="scalar"){
           let tempObj={};
           tempObj['Description']=this.alarmService.selectedAlarm[val['name']];
           tempObj['id']=this.alarmService.selectedAlarm[val['name']];
           inputJson[val['name']]=tempObj;
           tempObj={};
         }
         else if(val['API_Binding_Attribute'] && val['paramType']=="set"){
          inputJson[val['name']]=this.alarmService.selectedAlarm.criterion[val['name']];
        }
        else{
            if(val['name']=="Plant" || val['name']=="Line" ){
              inputJson[val['name']]=this.formGroup.get(val['name']).value.id;
            }
            if(val['name']!="Plant" && val['name']!="Line"){
              inputJson[val['name']]=this.formGroup.get(val['name']).value;
           }
         } 
        inputJson['system']=this.formGroup.get('system').value;
        inputJson['subtype_id']=this.formGroup.get('subTypeControl').value.subtype_id;
        inputJson['subTypeControl']=this.formGroup.get('subTypeControl').value;
        if(!this.type){
          let jsonString: any = this.alarmService.selectedAlarm.criterion;
          inputJson['Plant']=jsonString['Plant'];
          inputJson['Line']=jsonString['Line'];
          }
        if(val['API_Binding_Attribute']){
          this.getAPIBindingList(inputJson,val['API_Binding_Attribute'],i);
         } 
        }
 
    }

    onPlantSelect(){
      this.lineData=this.siteData.find(x => x.id === this.formGroup.get('Plant').value.id).lines;
    }

    checknavigation(){
      if(this.alarmService.navigationSource=="alarms"){
        this._router.navigate(["/alarms"]);
      }
      else if(this.alarmService.navigationSource=="summary"){
        this._router.navigate(["/summary"]);
      }
    }
    

}

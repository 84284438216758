<div >
	<a *ngIf="this._global.admin" mat-raised-button class="box" class="createAlarmButton" routerLink="/create-alarm" style="height: 45px !important;margin: 0px 10px 0px 15px;"  [disabled]="!this._global.admin">Create Alarm</a>
  <a *ngIf="!this._global.admin" mat-raised-button class="box" class="createAlarmButton" style="height: 45px !important;margin: 0px 10px 0px 15px;"  [disabled]="!this._global.admin">Create Alarm</a>
</div>


  <form [formGroup]="formGroup" class="float_right inputForm">
    <div class="parent">
      <input type="hidden" formControlName="alarm_id">
      <input type="hidden" formControlName="subtype_id">
      <input type="hidden" formControlName="criterion">
      <input type="hidden" formControlName="desc">

      <!--System Dropdown-->
      <mat-form-field class="slicer systemDropDown">
        <mat-label>System</mat-label>
        <mat-select (selectionChange)="onSystemSelect()" formControlName="system" required  [value]="alarmService.selectedSystem" [compareWith]="compareSystem">
          <mat-option value="null" disabled selected>Select...</mat-option>
          <mat-option *ngFor="let sys of alarmService.systemList" [value]="sys.system_id">
            {{sys.system_name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

     
      <ng-container *ngFor="let input of inputList; index as i">

        <!-- Plant / Line Dropdown of Appsync -->
        <ng-container *ngIf="!input['API Binding Attribute'] && input['Name']=='Plant'">
          <mat-form-field class="slicer margin_left dynamicFeild">
            <mat-label>{{input.Name | titlecase}}</mat-label>
              <mat-select  [formControlName]="input['Name']" (selectionChange)="checkGetData(input['Display Order'],i);onPlantSelect()"
                [compareWith]="compareByPlantLine" required>
                <mat-option value="null" disabled selected>Select...</mat-option>
                <mat-option *ngFor="let s of siteData" [value]="s">
                  {{s.name}}
                </mat-option>
              </mat-select>
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="!input['API Binding Attribute'] && input['Name']=='Line'">
          <mat-form-field class="slicer margin_left dynamicFeild">
            <mat-label>{{input.Name | titlecase}}</mat-label>
              <mat-select  [formControlName]="input['Name']" (selectionChange)="checkGetData(input['Display Order'],i);"
                [compareWith]="compareByPlantLine" required>
                <mat-option value="null" disabled selected>Select...</mat-option>
                <mat-option *ngFor="let s of lineData" [value]="s">
                  {{s.name}}
                </mat-option>
              </mat-select>
          </mat-form-field>
        </ng-container>
  
        <!--Other dynamic Dropdowns-->
        <ng-container *ngIf="input['API Binding Attribute'] && input['Name']!='subTypeControl'">
          <mat-form-field class="slicer margin_left dynamicFeild" >
            <mat-label>{{input.Name}}</mat-label>
              <mat-select  [formControlName]="input['Name']" (selectionChange)="checkGetData(input['Display Order'],i);"
                [compareWith]="compareDynamic" required>
                <mat-option value="null" disabled selected>Select...</mat-option>
                <mat-option *ngFor="let s of input['List']" [value]="s">
                  {{s.Description}}
                </mat-option>
              </mat-select>
          </mat-form-field>
        </ng-container>

        <!--Subtype Dropdowns-->
       <ng-container *ngIf="input['API Binding Attribute'] && input['Name']=='subTypeControl'">
        <mat-form-field class="slicer margin_left dynamicFeild" >
          <mat-label>Category</mat-label>
            <mat-select  [formControlName]="input['Name']" (selectionChange)="getAlarmData();storeSubtypeSelection()"
              [compareWith]="compareSubType" required>
              <mat-option value="null" disabled selected>Select...</mat-option>
              <mat-option *ngFor="let s of input['List']" [value]="s">
                {{s.sub_type}}
              </mat-option>
            </mat-select>
        </mat-form-field>
      </ng-container>
  
      </ng-container>

    </div>
  </form>

  <div class="table-padding" *ngIf="tableShow">
    <table id="mobile_alarmTable" >
      <tr *ngFor="let alarm of alarmData">
        <table class="inner_table" width="100%;">
          <tr class="header">
            <td></td>
            <td *ngIf="this._global.admin"><button (click)="openDialog(alarm)" color="warn" class="delete_button">
              <mat-icon class="delete">delete</mat-icon></button></td>
              	<td *ngIf="!this._global.admin"><button class="delete_button_2">
              <mat-icon class="delete">delete</mat-icon></button></td>
          </tr>
          <tr *ngFor="let column of columns" (click)="onAlarmSelect(alarm)">
             <td width="50%" style="font-weight: bold;">{{column}}</td>
             <td class="column_data" width="50%" style="font-size: 12px;">{{alarm[column]}}</td>
          </tr>

          <tr>
            <td width="50%" style="font-weight: bold;">Subscription Type</td>
            <td *ngIf="tableShow">
              <mat-checkbox style="width:75px;padding-right:10px;" [checked]="alarm['subscriptions']['email']"
                (change)="onSubscriptionTypeSelect($event,'email', alarm)">eMail</mat-checkbox>
              <mat-checkbox style="width:75px;padding-right:10px;" [checked]="alarm['subscriptions']['sms']"
                (change)="onSubscriptionTypeSelect($event,'sms', alarm)">SMS</mat-checkbox>
            </td>
          </tr>
          <tr>
            <td width="50%" style="font-weight: bold;">Alarm Enable</td>
            <td *ngIf="tableShow">
              <mat-checkbox style="width:75px;padding-right:10px;" [checked]='alarm.disableAlarmFlag'
                (change)="onDisableAlarm($event,'disableAlarm', alarm)"></mat-checkbox>
            </td>
          </tr>
        </table>
      </tr>
    </table>

    <table class="lineTable" id=lineTable>
      <thead>
        <tr>
          <th *ngFor="let column of columns" class="mobile-label">{{column}}</th>
          <th width="20%" class="mobile-label">Subscription Type</th>
          <th width="5%" class="mobile-label">Alarm Enable</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let alarm of alarmData" style="cursor:pointer">
            <td *ngFor="let columnnn of columns" class="column_data" (click)="onAlarmSelect(alarm)" style="text-align: center;">{{alarm[columnnn]}}</td>
          <td width="20%" style="padding-left: 10px;" *ngIf="tableShow">
            <mat-checkbox style="width:75px;padding-right:10px;" [checked]="alarm['subscriptions']['email']"
              (change)="onSubscriptionTypeSelect($event,'email', alarm)">eMail</mat-checkbox>
            <mat-checkbox style="width:75px;padding-right:10px;" [checked]="alarm['subscriptions']['sms']"
              (change)="onSubscriptionTypeSelect($event,'sms', alarm)">SMS</mat-checkbox>
              
            <td width="5%" style="padding-left: 10px;" *ngIf="tableShow">
            <mat-checkbox style="width:75px;padding-right:10px;" [checked]='alarm.disableAlarmFlag'
              (change)="onDisableAlarm($event,'disableAlarm',alarm)"></mat-checkbox>
          </td>
		<ng-template [ngIf] = "!this._global.admin">
			<td *ngIf="tableShow"><button mat-raised-button [disabled]=!this._global.admin color="warn">X</button></td>
		</ng-template>
		<ng-template [ngIf] = "this._global.admin">
			<td *ngIf="tableShow"><button class="alarm_delete" mat-raised-button (click)="openDialog(alarm)" color="warn">X</button></td>
		</ng-template>

        </tr>
      </tbody>
    </table>
  </div>

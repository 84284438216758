<form [formGroup]="formGroup" class="float_right inputForm"> 

    <div  class="userGroupContainer">
        <h3 class="headerConfiguration" *ngIf="isConfigTypeCreate">Create New User Group</h3>
        <h3 class="headerConfiguration" *ngIf="!isConfigTypeCreate">Update User Group</h3>
        <table class="userGroupTable desktopView">
            <div class="inputGroupDiv">
                <div class="">
                    <mat-form-field class="slicer">
                        <mat-label>Plant</mat-label>
                        <mat-select formControlName="Plant" required (selectionChange)="setPlantValue()" [value]="alarmService.selectedSystem" [compareWith]="comparePlant">
                        <mat-option value="null" disabled selected>Select...</mat-option>
                        <mat-option *ngFor="let s of siteData" [value]="s">
                            {{s.name}}
                        </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="UserGroupNameInputDiv inputFiled">
                    <mat-form-field class="demo-full-width" appearance="fill" >
                        <mat-label>User Group Name</mat-label>
                        <input matInput placeholder="User Group Name" formControlName="userGroupName" width="50%" required autocomplete="off">
                    </mat-form-field>
                </div>
                <div class="UserGroupDescriptionInputDiv inputFiled">
                <mat-form-field class="demo-full-width" appearance="fill" >
                    <mat-label>User Group Description</mat-label>
                    <input matInput placeholder="User Group Description" formControlName="userGroupDescription" width="50%" required autocomplete="off">
                </mat-form-field>
                </div>
                <div class="teams-link-div">
                    <mat-checkbox  style=" margin-left: 10px;top:30%"  formControlName="linkToTeams"  (change)="linkToTeamsSelectionChange($event)">Link Teams Channel</mat-checkbox>
                    <span  style="margin-top:20px; margin-left: 10px;" *ngIf= "userGroupDetails  && userGroupDetails['TeamURL']" (click)="openAlarmTeamsPopup()"><span class="hyper-link"><u>View Channel Details</u></span></span> 
                </div>
            </div>
            <div class="DragAndDropContainer">
                <div class="listDiv">
                    <div style="display:flex">
                        <div class="avaialableUsersContainer" style="float:left">
                            <tr class="containerHeading">
                                <td class="lableCell">Available Users</td>
                            </tr>      
                            <mat-form-field class="demo-full-width" appearance="outline" class="searchAvailableUsersInput">
                                <input matInput placeholder="Search Users"  width="90%" class="searchAvailableUsersInput" (keyup)="onUserSearch($event)" formControlName="searchAvailableUsers" autocomplete="off">
                              </mat-form-field>

                            <table class="availableUsersTable">
                                <tr *ngFor=" let user of avialbleUsers">
                                    <td *ngIf="user.First || user.Last" class="tableCell lableCellAvailableUser clickableCell" [ngClass]="user.selected ? 'selectedUser' : 'null'" [ngClass]="!user.show ? 'hideUser' : 'null'" (click)="checkSlectedAVaialableUser(user)">{{user.First}} {{user.Last}} </td>
                                    <td *ngIf="user.DLName" class="tableCell lableCellAvailableUse clickableCellr" [ngClass]="user.selected ? 'selectedUser' : 'null'" [ngClass]="!user.show ? 'hideUser' : 'null'" (click)="checkSlectedAVaialableUser(user)">{{user.DLName}} </td> 
                                </tr>
                            </table>
                        </div>
                        <div class="buttonsDiv">
                            <button  mat-raised-button class="customButton buttonsBetweenContainer movetoDestination" (click)="moveUserToTarget()"  > >> </button>
                        </div>
                        <div class="assginedUsersContainer" style="float:right">
                           <div class="containerHeading">
                            <table>   
                            <tr >
                                    <td class="lableCell headingCell">Assigned Users</td>
                                    <td class="checkBoxCell headingCell centerText">Email</td>
                                    <td class="checkBoxCell headingCell paddingRight10">SMS</td>
                                    <td class="checkBoxCell headingCell"></td>
                                </tr>
                            </table>
                           </div>
                            <table class="assignedUsersTable">
                                <tr *ngFor=" let user of assignedUsers">
                                    <td *ngIf="user.First || user.Last" class="tableCell lableCell">{{user.First}} {{user.Last}} <span class="material-icons retryIcon" (click)="openSubscriptionRetryPopup('User',user)" *ngIf="user.Active==0">subscriptions</span> </td>
                                    <td *ngIf="user.DLName" class="tableCell lableCell">{{user.DLName}} <span class="material-icons retryIcon" (click)="openSubscriptionRetryPopup('DL',user)" *ngIf="user.Active==0">subscriptions</span>
                                    </td>
                                    <td class="tableCell checkBoxCell"><mat-checkbox (change)="subscriptionChange($event,'email',user)" class="email_checkbox" [checked]="user.email" [disabled]="user.DLName" (click)="showErrorOnDLSubscriptionChange(user.DLName)"> </mat-checkbox></td>
                                    <td class="tableCell checkBoxCell"><mat-checkbox [ngClass]="user.DLName ? 'hideUser' : 'null'" (change)="subscriptionChange($event,'sms',user)" class="email_checkbox" [checked]="user.sms"> </mat-checkbox></td>
                                    <td class="tableCell checkBoxCell"><button mat-raised-button  class="removeUserButton" (click)="moveUserToSource(user)" color="warn"  >X</button></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                
                <div class="AddNewButtonGroup">
                       <div><button  mat-raised-button class="customButton" (click)="onNewUSerButtonClick()"  >New User (SSO)</button> </div>
                       <p></p>
                       <div><button  mat-raised-button class="customButton" (click)="onNewDlButtonClick()"  >New GEA DL</button> </div> 
                </div>  
              </div>
              
                <div  class="submitbuttonGroup">
                        <button mat-raised-button color="primary" class="submit saveButton" (click)="createUserGroup()" *ngIf="isConfigTypeCreate">Submit</button>
                        <button mat-raised-button color="primary" class="submit saveButton" (click)="createUserGroup()" *ngIf="!isConfigTypeCreate">Update</button>
                        <button mat-raised-button color="primary" type="reset" routerLink="/userGroups" class="cancel saveButton">Cancel</button>
                        <mat-spinner [diameter]="70" *ngIf="showSpinnerOnUpdate" ></mat-spinner>
                    </div>

        </table>

        <table class="userGroupTable mobileView">
        <div class="inputGroupDiv">
            <div class="">
                <mat-form-field class="slicer">
                    <mat-label>Plant</mat-label>
                    <mat-select formControlName="Plant" required  (selectionChange)="setPlantValue()" [value]="alarmService.selectedSystem" [compareWith]="comparePlant">
                    <mat-option value="null" disabled selected>Select...</mat-option>
                    <mat-option *ngFor="let s of siteData" [value]="s">
                        {{s.name}}
                    </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="UserGroupNameInputDiv inputFiled">
                <mat-form-field class="demo-full-width" appearance="fill" >
                    <mat-label>User Group Name</mat-label>
                    <input matInput placeholder="User Group Name" formControlName="userGroupName" width="50%" required autocomplete="off">
                </mat-form-field>
            </div>
         </div>
         <div class="inputGroupDiv2">   
            <div class="UserGroupDescriptionInputDiv inputFiled">
                <mat-form-field class="demo-full-width userGroupDesc" appearance="fill" >
                    <mat-label>User Group Description</mat-label>
                    <input matInput placeholder="User Group Description" formControlName="userGroupDescription" width="50%" required autocomplete="off">
                </mat-form-field>
            </div>         
        </div>
        <div class="inputGroupDiv3">
            <div><button  mat-raised-button class="customButton" (click)="onNewUSerButtonClick()"  >New User (SSO)</button> </div>
            <div class="paddingLeft5"><button  mat-raised-button class="customButton" (click)="onNewDlButtonClick()"  >New GEA DL</button> </div> 
        </div>  
        <div class="inputGroupDiv4">
            <mat-checkbox  style="padding-left: 1%"  formControlName="linkToTeams"  (change)="linkToTeamsSelectionChange($event)">Link Teams Channel</mat-checkbox>
            <span  style="margin-top:20px; margin-left: 10px;" *ngIf= "userGroupDetails  && userGroupDetails['TeamURL']" (click)="openAlarmTeamsPopup()"><span class="hyper-link"><u>View Channel Details</u></span></span>
        </div>
        <div class="listDiv">
                <div style="display:flex">
                    <div class="avaialableUsersContainer" style="float:left">
                        <tr class="containerHeading">
                            <td class="lableCell">Available</td>
                        </tr>      
                        <mat-form-field class="demo-full-width" appearance="outline" class="searchAvailableUsersInput">
                            <input matInput placeholder="Search Users"  width="90%" class="searchAvailableUsersInput" (keyup)="onUserSearch($event)" formControlName="searchAvailableUsers" autocomplete="off">
                          </mat-form-field>

                        <table class="availableUsersTable">
                            <tr *ngFor=" let user of avialbleUsers">
                                <td *ngIf="user.First || user.Last" class="tableCell lableCellAvailableUser clickableCell" [ngClass]="user.selected ? 'selectedUser' : 'null'" [ngClass]="!user.show ? 'hideUser' : 'null'" (click)="checkSlectedAVaialableUser(user)">{{user.First}} {{user.Last}} </td>
                                <td *ngIf="user.DLName" class="tableCell lableCellAvailableUse clickableCellr" [ngClass]="user.selected ? 'selectedUser' : 'null'" [ngClass]="!user.show ? 'hideUser' : 'null'" (click)="checkSlectedAVaialableUser(user)">{{user.DLName}} </td> 
                            </tr>
                        </table>
                    </div>
                    <div class="buttonsDiv">
                        <button  mat-raised-button class="customButton buttonsBetweenContainer movetoDestination" (click)="moveUserToTarget()"  > >> </button>
                    </div>
                    <div class="assginedUsersContainer" style="float:right">
                       <div class="containerHeading">
                        <!-- <table>    -->
                        <tr >
                                <td class="lableCell headingCell">Assigned</td>
                                <td class="headingCell headingCellMobile">Email</td>
                                <td class="headingCell  headingCellMobile">SMS</td>
                                <td class="headingCell"></td>
                            </tr>
                        <!-- </table> -->
                       </div>
                        <table class="assignedUsersTable">
                            <tr *ngFor=" let user of assignedUsers">
                                <td *ngIf="user.First || user.Last" class="tableCell lableCell">{{user.First}} {{user.Last}} <span class="material-icons retryIcon" (click)="openSubscriptionRetryPopup('User',user)" *ngIf="user.Active==0">subscriptions</span></td>
                                <td *ngIf="user.DLName" class="tableCell lableCell wordBreak">{{user.DLName}} <span class="material-icons retryIcon" (click)="openSubscriptionRetryPopup('DL',user)" *ngIf="user.Active==0">subscriptions</span></td>
                                <td class="tableCell checkBoxCell"><mat-checkbox (change)="subscriptionChange($event,'email',user)" class="email_checkbox" [checked]="user.email" [disabled]="user.DLName" (click)="showErrorOnDLSubscriptionChange(user.DLName)"> </mat-checkbox></td>
                                <td class="tableCell checkBoxCell"><mat-checkbox [ngClass]="user.DLName ? 'hideUser' : 'null'" (change)="subscriptionChange($event,'sms',user)" class="email_checkbox" [checked]="user.sms"> </mat-checkbox></td>
                                <td class="tableCell checkBoxCell"><button mat-raised-button  class="removeUserButton" (click)="moveUserToSource(user)" color="warn"  >X</button></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div  class="submitbuttonGroup">
                    <button mat-raised-button color="primary" class="submit saveButton" (click)="createUserGroup()" *ngIf="isConfigTypeCreate">Submit</button>
                    <button mat-raised-button color="primary" class="submit saveButton" (click)="createUserGroup()" *ngIf="!isConfigTypeCreate">Update</button>
                    <button mat-raised-button color="primary" type="reset" routerLink="/userGroups" class="cancel saveButton">Cancel</button>
                    <mat-spinner [diameter]="70" *ngIf="showSpinnerOnUpdate" ></mat-spinner>
                </div> 
            </div>
          
        </table>   
        
    </div>       
</form>
import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { SubTypeService } from '../alarms/subtypes/subtype.service';
import { AlarmService } from '../alarms/alarm.service';
import {  MatLegacyCheckbox as MatCheckbox } from '@angular/material/legacy-checkbox';
import { Alarm } from '../alarms/alarm.model';
import { ConfirmationDialog2 } from '../confirmation-dialog2.component';
import { ConfirmationDialog } from '../confirmation-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { environment } from '../../../src/environments/environment';
import { HttpClient } from '@angular/common/http'; 
import { AlarmRepeatPopup } from '../alarm-repeat-popup/alarm-repeat-popup.component';

@Component({
  selector: 'app-myalarms',
  templateUrl: './myalarms.component.html',
  styleUrls: ['./myalarms.component.css'],
})
export class MyalarmsComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  dataSource: any[];
  dataGroupByAlarmName: any = [];
  dataGroupByAlarm: any;
  subscriptionData: any = [];
  mobilenumberCount: number = 0;
  emailSubsription:boolean=false;
  checkSubsriptionData:boolean;
  booleanValue: boolean = false;
  readonly checkMobileNumber = environment.userProfileBaseUrl + "/AlarmUserProfileList?Protocol=sms&type=check";
  constructor(public alarmService: AlarmService,
    private router: Router,
    private _snackBar: MatSnackBar,
    public subTypeService: SubTypeService,
    private dialog: MatDialog,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.getCountData();
    this.http.get(this.checkMobileNumber).toPromise().then(data => {
      let totalresp = data['Body'][0]['ProfileData'];
      for (var i = 0; i < totalresp.length; i++) {
        if (totalresp[i].Protocol == "sms") {
          this.mobilenumberCount++;
        }
        if (totalresp[i].Protocol == "email") {
          this.emailSubsription=true;
        }
      }
    });
  }

  getCountData() {
    this.alarmService.myProfileAlarms().subscribe(data => {
      this.groupDataByAlarm(data['Body']);
    });
  }
  groupDataByAlarm(data) {
    this.dataGroupByAlarmName =[];
    if (data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        this.dataGroupByAlarmName.push(data[i])
        this.dataGroupByAlarmName[i].criterion = JSON.parse(data[i].criterion);
        for (var key in this.dataGroupByAlarmName[i].criterion) {
          if (key == "AlarmName") {
            this.dataGroupByAlarmName[i][key] = this.dataGroupByAlarmName[i].criterion[key];
          }}
      }
      this.dataGroupByAlarmName.forEach(alarmdata => {
        alarmdata['Sms']=Boolean(alarmdata['Sms'])
        alarmdata['email']=Boolean(alarmdata['email']);
        alarmdata['group']=Boolean(alarmdata['group'])
      });
    }else {
      this.checkSubsriptionData = true;
    }
  }
  onSubscriptionTypeSelect(check: MatCheckbox, type: string, alarm: Alarm) {
    let RepeatEnable=false;
    if(alarm['criterion']['RepeatEnable'] && alarm['criterion']['RepeatEnable']=="1"){
      RepeatEnable=true;
    }
    if (check.checked) {

      if (type == "sms") {
          this.openDialog2(alarm, type,RepeatEnable);
      } else {
        this.showAlarmRepeatPopupForEmail(alarm,type,RepeatEnable)
      }
    } else {
      //Add code to unsubscribe
      this.alarmService.unsubscribeAlarm(alarm.alarm_id, type).subscribe(res => {
        let statusMessage = res['StatusMessage'];
        if (res['errorMessage'] != "") {
          this._snackBar.open(statusMessage, "", {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          this.getCountData()
        } else {
          this._snackBar.open("Alarm Unsubscription", "Success", {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['Success'],
          });
        }});
    }
  }
  subscribeAlarm(alarm_id, type,ImmediateAlarm,RepeatInterval) {

    this.alarmService.subscribeAlarm(alarm_id, type,ImmediateAlarm,RepeatInterval).subscribe(res => {
      if (res['errorMessage'] != "") {
        this._snackBar.open("Error occured while Alarm subscription", "Error", {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      } else {
        this._snackBar.open("Alarm subscription", "Success", {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          panelClass: ['Success']
        });
        this.getCountData();
      }
    });
  }
  openDialog2(alarm, type,RepeatEnable) {
    if(RepeatEnable){
      if (this.mobilenumberCount > 0) {
        this.showAlarmRepeatPopupForSMS(alarm['alarm_id'],type)
  
      } else {
        
        const dialogRef2 = this.dialog.open(ConfirmationDialog2, {
          width: '480px',
          disableClose: true,
          data: {
            message: 'Please Enter Mobile Number.',
            showRepeatEnableFields:RepeatEnable,
            buttonText: {
              ok: 'Submit',
              cancel: 'Cancel'
            }
          }
        });
    
    
    
        dialogRef2.afterClosed().subscribe(result => {
          if (result != null && result != "" && result != undefined) {
            if (result.data.Protocol != "" && result.data.EndPoint != "") {
              let immediate_alarmCheck="0";
              if(result['data']['ImmediateAlarm']){        
              immediate_alarmCheck="1";
              }
              this.alarmService.addMobileNumber(result.data).subscribe(res => {
                if (res['statusMessage'] == "UserProfile Updated Successfully") {
                  this.mobilenumberCount++;
                  this.subscribeAlarm(alarm['alarm_id'], type,immediate_alarmCheck,result.data.repaetIntervelValue);
    
                } else {
                  this._snackBar.open("Error occured while adding mobile Number", "Error", {
                    duration: 5000,
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                  });
                }
              });
    
    
            }
          } 
        });
      }
     }else{
      if (this.mobilenumberCount > 0) {
        this.subscribeAlarm(alarm['alarm_id'], type,"","");
      }else{
        const dialogRef2 = this.dialog.open(ConfirmationDialog2, {
          width: '480px',
          disableClose: true,
          data: {
            message: 'Please Enter Mobile Number.',
            showRepeatEnableFields:RepeatEnable,
            buttonText: {
              ok: 'Submit',
              cancel: 'Cancel'
            }
          }
        });
    
    
    
        dialogRef2.afterClosed().subscribe(result => {
          if (result != null && result != "" && result != undefined) {
            if (result.data.Protocol != "" && result.data.EndPoint != "") {
    
              this.alarmService.addMobileNumber(result.data).subscribe(res => {
                if (res['statusMessage'] == "UserProfile Updated Successfully") {
                  this.mobilenumberCount++;
                  this.subscribeAlarm(alarm['alarm_id'], type,"","");
    
                } else {
                  this._snackBar.open("Error occured while adding mobile Number", "Error", {
                    duration: 5000,
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                  });
                }
              });
    
    
            }
          } 
        });
      }
     }
      
  }
  openDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: this.alarmService.emailConfigPopupText,
        buttonText: {
          ok: 'OK'
        }
      }
    });
  }
  sort(colName,boolean) {
    if (boolean == true){
        this.dataGroupByAlarmName.sort((a, b) => a[colName] < b[colName] ? 1 : a[colName] > b[colName] ? -1 : 0)
        this.booleanValue = !this.booleanValue;
    }
    else{  
        this.dataGroupByAlarmName.sort((a, b) => a[colName] > b[colName] ? 1 : a[colName] < b[colName] ? -1 : 0)
        this.booleanValue = !this.booleanValue;

    }
}
ngOnChanges(){
if(this.dataGroupByAlarmName.length == 0){
  this.checkSubsriptionData = true;
 }
}

showAlarmRepeatPopupForEmail(alarm: Alarm,type,RepeatEnable){
  if(RepeatEnable){
  const dialogRef = this.dialog.open(AlarmRepeatPopup, {
    width: '480px',
    disableClose: true,
    data: {}
  });

  dialogRef.afterClosed().subscribe(popupData => {
      if(!this.emailSubsription){
        let json={};
        json['EndPoint']="";
        json['Protocol']="email";
        let immediate_alarmCheck="0";
        if(popupData['data']['ImmediateAlarm']){        
         immediate_alarmCheck="1";
        }this.alarmService.addMobileNumber(json).subscribe(res => {
           if (res['statusMessage'] == "UserProfile Updated Successfully") {
              this.emailSubsription=true;
              this.openDialog();
              this.subscribeAlarm(alarm.alarm_id, type,immediate_alarmCheck,popupData['data']['repaetIntervelValue']);
          } else {
            this._snackBar.open("Error occured while crearting email subscription", "Error", {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
          }
        });

        
      }else{
        let immediate_alarmCheck="0";
        if(popupData['data']['ImmediateAlarm']){        
         immediate_alarmCheck="1";
        }
        this.subscribeAlarm(alarm.alarm_id, type,immediate_alarmCheck,popupData['data']['repaetIntervelValue']);
      }
    
  });
}else{
  if(!this.emailSubsription){
    let json={};
    json['EndPoint']="";
    json['Protocol']="email";
    this.alarmService.addMobileNumber(json).subscribe(res => {
       if (res['statusMessage'] == "UserProfile Updated Successfully") {
          this.emailSubsription=true;
          this.openDialog();
          this.subscribeAlarm(alarm.alarm_id, type,"","");
      } else {
        this._snackBar.open("Error occured while crearting email subscription", "Error", {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    });

    
  }else{
    this.subscribeAlarm(alarm.alarm_id, type,"","");
  }
}

}

showAlarmRepeatPopupForSMS(alarm_id,type){
  const dialogRef = this.dialog.open(AlarmRepeatPopup, {
    width: '480px',
    disableClose: true,
    data: {}
  });

  dialogRef.afterClosed().subscribe(popupData => {
    let immediate_alarmCheck="0";
        if(popupData['data']['ImmediateAlarm']){        
         immediate_alarmCheck="1";
    }
    this.subscribeAlarm(alarm_id, type,immediate_alarmCheck,popupData['data']['repaetIntervelValue']);
  });

 
  
}

}

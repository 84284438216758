import { Component, OnInit } from '@angular/core';
import { AlarmService } from './alarm.service';
import { Router,ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {  MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import {  MatLegacyCheckbox as MatCheckbox } from '@angular/material/legacy-checkbox';
import { Alarm } from './alarm.model';
import { ConfirmationDialog } from '../confirmation-dialog.component';
import { SubTypeService } from './subtypes/subtype.service';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { SubTypeParam } from './subtypes/subtype-params/subtypeparam.model';
import { SubTypeParamService } from './subtypes/subtype-params/sub-type-param.service';
import { SubType } from './subtypes/subtype.model';
import { Observable, Subject, ReplaySubject, Subscription } from 'rxjs';
import { tap, map, filter, pluck, first } from 'rxjs/operators';
import { environment } from '../../../src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ConfirmationDialog2 } from '../confirmation-dialog2.component';
import {GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { Globals } from '../app.globals';


@Component({
  selector: 'app-alarms',
  templateUrl: './alarms.component.html',
  styleUrls: ['./alarms.component.css']
})
export class AlarmsComponent implements OnInit {


  formGroup: UntypedFormGroup;
  type: boolean = true;
  subTypeParamslist: SubTypeParam[];

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  comparePlant: ((f1: any, f2: any) => boolean) | null = this.compareByPlant;
  compareLine: ((f1: any, f2: any) => boolean) | null = this.compareByLine;
  compareSubType: ((f1: any, f2: any) => boolean) | null = this.compareBySubType;
  compareSystem:((f1: any, f2: any) => boolean) | null = this.compareBySystem;
  comparePlantLine:  ((f1: any, f2: any) => boolean) | null = this.compareByPlantLine;
  compareDynamic: ((f1: any, f2: any) => boolean) | null = this.compareDynamicValue;

  context: Subject<any> = new ReplaySubject(1)
  selected$: Subject<any> = new Subject()
  selections: Map<string, any>
  adminSub$: Subscription;

  tree = [];
  tempObj:any={};
  resp:any;
  columns:any=[];
  alarmData:any=[];
  list: Alarm[];
  subscription: any[];
  tableShow:boolean=false;
  plantList:any=[];
  lineList:any=[];
  subtypeList:any=[];
  newmobileNumber:number;
  mobilenumberCount:number=0;
  emailSubsription:boolean=false;
  previousLine:any={};
  alarmDisable:number;
  sectionList:any=[];
  inputList:any=[];
  tempVarible:any={};
  routeParams:any=[];
  siteData:any=[];
  lineData:any=[];

  readonly AlarmSubscriptionList = environment.apiGwUrl + "/SubscriptionList";
  readonly checkMobileNumber =  environment.userProfileBaseUrl+"/AlarmUserProfileList?Protocol=sms&type=check";
  readonly subtypeURL=environment.apiGwUrl + "/AlarmSubtypeList";

  constructor(public alarmService: AlarmService,
    private router: Router,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public subTypeService:SubTypeService,
    private formBuilder:UntypedFormBuilder,
    public subTypeParamService: SubTypeParamService,
    private route: ActivatedRoute,
    private http: HttpClient,
    public geaMesCognitoAuthService: GeaMesCognitoAuthService,
    public _global: Globals
    )

    {
      this.formGroup = this.formBuilder.group({
        alarm_id: [],
        subtype_id: [],
        desc: [],
        updated_by: [],
        subTypeControl: [],
        criterion: [],
        plantName:[],
        lineName:[],
        system:[],

      });
      
    this.selections = new Map<string, any>();

     }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.routeParams=params;
    });

    this.alarmService.getSitesData().subscribe(res => {
      this.siteData=res['Body'];
      if(this.routeParams.plant || this.alarmService.dropDownSelections.plant){
        this.plantPreSelection();
      }
      this.alarmService.alarmSiteData=res['Body'];
    });

    // To Fecth System List
    this.alarmService.getSystemList().subscribe(res => {
    this.alarmService.saveSystemList(res);


      //Pre Select System DropDown

      if( this.routeParams.system){
        for(var i=0;i<this.alarmService.systemList.length;i++){
          if(this.alarmService.systemList[i].system_id==this.routeParams.system){
            this.formGroup.get('system').setValue(this.alarmService.systemList[i].system_id); 
            this.checkPreSelection();
            
          }
        }
      }else{
        if(this.alarmService.selectedSystem!=undefined){
          this.formGroup.get('system').setValue(this.alarmService.selectedSystem);
          this.checkPreSelection();
          
        }
      }


    });



    // Check to see if this user is an admin
    this.adminSub$ = this.geaMesCognitoAuthService.isUserInRole("Admin").pipe(first()).subscribe( out => {
      this._global.admin = out;
      if (this.adminSub$) this.adminSub$.unsubscribe(); // We've got an answer, can unsubscribe now
    });

    this.http.get(this.checkMobileNumber).toPromise().then(data => {
      let totalresp=data['Body'][0]['ProfileData'];
      for(var i=0;i<totalresp.length;i++){
        if(totalresp[i].Protocol=="sms"){
          this.mobilenumberCount++;
        }
        if (totalresp[i].Protocol == "email") {
          this.emailSubsription=true;
        }
      }
    });

    if(this.subTypeService.selectedPlant!=undefined){
      this.formGroup.get('plantName').setValue(this.subTypeService.selectedPlant);
        var plantName=this.formGroup.get('plantName').value.name;
      this.alarmService.lineList(plantName).subscribe(data => {
        this.lineList=data;
      });
      this.formGroup.get('lineName').setValue(this.subTypeService.selectedLine);
    }
    if(this.subTypeService.selectedLine!=undefined){
      this.formGroup.get('lineName').setValue(this.subTypeService.selectedLine);
    }
    if(this.subTypeService.selectedSubType!=undefined){
      this.formGroup.get('subTypeControl').setValue(this.subTypeService.selectedSubType);
    }

    if(this.subTypeService.selectedPlant!=undefined && this.subTypeService.selectedLine!=undefined && this.subTypeService.selectedSubType!=undefined){
      setTimeout(() => {
      this.getData();
    }, 1000);
    }

  }

  ngOnDestroy() {

    this.alarmService.dropDownSelections={};

    if(this.formGroup.get('system')){
       this.alarmService.dropDownSelections.system=this.formGroup.get('system').value;
    }

    if(this.formGroup.get('Plant')){
      this.alarmService.dropDownSelections.plant=this.formGroup.get('Plant').value;
    }
 
    if(this.formGroup.get('Line')){
       this.alarmService.dropDownSelections.line=this.formGroup.get('Line').value;
    }
 
    if(this.formGroup.get('Area')){
       this.alarmService.dropDownSelections.area=this.formGroup.get('Area').value;
    }

    if(this.formGroup.get('subTypeControl')){
      this.alarmService.dropDownSelections.subtype=this.formGroup.get('subTypeControl').value;
    }
 
   }

  
  getData(){
    this.getAlarmData();
  }

  onAlarmSelect(alarm: Alarm) {
    this.alarmService.setSelectedAlarm(alarm);
    if(this._global.admin){
      this.router.navigate(["/update-alarm"]);
      this.alarmService.navigationSource="alarms";
    }
  }

  subscribeAlarm(alarm_id, type){

    this.alarmService.subscribeAlarm(alarm_id, type,"",0).subscribe(res => {
      if (res['errorMessage'] != "") {
        this._snackBar.open("Error occured while Alarm subscription", "Error", {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }  else {
        this._snackBar.open("Alarm subscription", "Success", {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          panelClass: ['Success'],
        });
      }
    });

  }

  onSubscriptionTypeSelect(check: MatCheckbox, type: string, alarm: Alarm) {
    if (check.checked) {

      if(type=="sms"){

        if(this.mobilenumberCount>0){

          this.subscribeAlarm(alarm.alarm_id, type);

        }else{
          this.openDialog2(alarm.alarm_id, type);
        }


      }else{
       if(!this.emailSubsription){
        let json={};
        json['EndPoint']="";
        json['Protocol']="email";
        this.alarmService.addMobileNumber(json).subscribe(res => {
           if (res['statusMessage'] == "UserProfile Updated Successfully") {
              this.emailSubsription=true;
              this.showEmailConfigPopup();
              this.subscribeAlarm(alarm.alarm_id, type);
          } else {
            this._snackBar.open("Error occured while crearting email subscription", "Error", {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
          }
        });

        
      }else{
        this.subscribeAlarm(alarm.alarm_id, type);
      }

      }


    } else {
      //Add code to unsbscribe
      this.alarmService.unsubscribeAlarm(alarm.alarm_id, type).subscribe(res => {
        let statusMessage = res['StatusMessage'];
        if (res['errorMessage'] != "") {
          this._snackBar.open(statusMessage, "", {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        } else {
          this._snackBar.open("Alarm Unsubscription", "Success", {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['Success'],
          });
        }
      });
    }
  }
  disableAlarm(alarm_id, alarmEnable){
    this.alarmService.disableAlarm(alarm_id, alarmEnable).subscribe(res => {

   });
  }
  onDisableAlarm(check: MatCheckbox, type: string, alarm: Alarm) {
          if (check.checked) {
          this.alarmDisable = 0
          this.disableAlarm(alarm.alarm_id, this.alarmDisable)
          this._snackBar.open("Alarm Enabled ","Success", {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['Success'],
          });
      }
      else{
          this.alarmDisable = 1
          this.disableAlarm(alarm.alarm_id, this.alarmDisable)
          this._snackBar.open("Alarm Disabled ","Success", {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['Success'],
          });
      }
    }

    showEmailConfigPopup() {
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        data: {
          message:this.alarmService.emailConfigPopupText,
          buttonText: {
            ok: 'OK'
            //cancel: 'No'
          }
        }
      });
  }

  openDialog(alarm: Alarm) {
    if(this._global.admin){
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        data: {
          message: 'Are you sure want to delete?',
          buttonText: {
            ok: 'Yes',
            cancel: 'No'
          }
        }
      });

      
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          const a = document.createElement('a');
          a.click();
          a.remove();
          this.alarmService.deleteAlarm(alarm.alarm_id).subscribe(res => {
           let statusMessage = res['StatusMessage'];
           let statusCode = res['statusCode'];
            this._snackBar.open(statusMessage," ", {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['alert-success'],
            });
            if(statusCode==1){
              this.getData();
            }

          });
        }
      });
    }

  }

  openDialog2(alarm_id, type) {
    const dialogRef2 = this.dialog.open(ConfirmationDialog2, {
      width: '480px',
      disableClose: true,
      //height:'400px',
      data: {
        message: 'Please Enter Mobile Number.',
        buttonText: {
          ok: 'Submit',
          cancel: 'Cancel'
        }
      }
    });



    dialogRef2.afterClosed().subscribe(result => {
      if (result!=null && result!="" && result!=undefined) {
        if(result.data.Protocol!="" && result.data.EndPoint!=""){

          this.alarmService.addMobileNumber(result.data).subscribe(res => {
            if(res['statusMessage']=="UserProfile Updated Successfully"){
                this.mobilenumberCount++;
              this.subscribeAlarm(alarm_id, type);

            }else{
              this._snackBar.open("Error occured while adding mobile Number", "Error", {
                duration: 5000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
              });
            }
          });


        }
      }else{

      }
    });
  }


  compareByPlant(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }
  compareByLine(f1: any, f2: any) {
    return f1 && f2 && f1.LineId === f2.LineId;
  }
  compareBySubType(f1: any, f2: any) {
    return f1 && f2 && f1.subtype_id === f2.subtype_id;
  }

  compareByPlantLine(f1: any, f2: any){
    return f1 && f2 && f1.name === f2.name;
  }
  compareDynamicValue(f1: any, f2: any){
    return f1 && f2 && f1.id === f2.id;
  }

  getAlarmData(){
    this.columns=[];
    this.alarmData=[];
    this.tableShow=false;

    let subtypeID="";
    if(this.formGroup.get('subTypeControl') && this.formGroup.get('subTypeControl').value){
      subtypeID = this.formGroup.get('subTypeControl').value.subtype_id;
    }
    //subtypeID
    let System_Id=this.formGroup.get('system').value;
    let inputParams={};
    inputParams['system_id']=System_Id;
    inputParams['subtype_id']=subtypeID;
    for(let inputListValues of this.inputList){
      if(this.formGroup.get(inputListValues['Name']) && this.formGroup.get(inputListValues['Name']).value){
        inputParams[inputListValues['Name']]=this.formGroup.get(inputListValues['Name']).value.id;
      }
    }

    this.alarmService.filterConfigerList(inputParams).subscribe(data => {
      this.alarmData=[];
      this.alarmData=data;
      if(this.alarmData.length<=0)
      {
        this._snackBar.open("No Data."," ", {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
      else{
        this.http.get(this.AlarmSubscriptionList).toPromise().then(subscriptions => {
          this.list = data as Alarm[];

          this.subscription = subscriptions as any[];

          for (let alarm of this.alarmData) {
            if(alarm.disableAlarm == 0){
              alarm.disableAlarmFlag = true;
            }
            else{
              alarm.disableAlarmFlag = false;
            }
            if (alarm.subscriptions == undefined) {
              alarm.subscriptions = new Subscription();
              alarm.subscriptions.email = false;
              alarm.subscriptions.sms = false;
            }
            for (let data of this.subscription) {
              if (data.alarm_id == alarm.alarm_id) {
                if (data.Protocol == 'email') {
                  alarm.subscriptions.email = true;
                }
                if (data.Protocol == 'sms') {
                  alarm.subscriptions.sms = true;
                }
              }
            }
          }
        });


          var latestUpdatedIndex;
          for(var i=0;i<this.alarmData.length;i++){
            var x=JSON.parse(this.alarmData[i].criterion);
            latestUpdatedIndex=i;
           if(x.length>latestUpdatedIndex){
            latestUpdatedIndex=i;
           }
          }
            this.columns=[];
            for (var key in JSON.parse(this.alarmData[latestUpdatedIndex].criterion)) {
            if(key !="Plant" && key!="Line"){
              this.columns.push(key);
            }
          }

          for(var i=0;i<this.alarmData.length;i++){
            this.alarmData[i].criterion=JSON.parse(this.alarmData[i].criterion);
            for (var key in this.alarmData[i].criterion) {
              if(key !="Plant" && key!="Line"){
                this.alarmData[i][key]=this.alarmData[i].criterion[key];

              }

            }
          }

          setTimeout(() => {
            this.tableShow=true;
          }, 600);


      }

   });
  }


  onSystemSelect(){ 

    let plantList=this.alarmService.alarmSiteData;
    let filteredPlantList=[];
    plantList.forEach((element,index) => {
      if(element['alarmSystemId'].includes(this.formGroup.get('system').value)){
        filteredPlantList.push(element);
      }
    });
    this.siteData=filteredPlantList;

    this.alarmService.setSystemSelection();
    this.alarmService.setSelectedSystem(this.formGroup.get('system').value);
    this.tableShow=false;

    for(let r of this.inputList){
      this.formGroup.removeControl(r['Name']);
    }
    this.inputList=[];
    for(var i=0;i<this.alarmService.systemConfigList.length;i++){
      if(this.alarmService.systemConfigList[i]['System ID']==this.formGroup.get('system').value){
        this.inputList.push(this.alarmService.systemConfigList[i]);
      }
    }

    //adding subtype to the list
    let subtypeObj={};

    subtypeObj['API Binding']=1;
    subtypeObj['API Binding Attribute']=this.subtypeURL;
    subtypeObj['API Key']="";
    subtypeObj['Display Order']=this.inputList.length+1;
    subtypeObj['Name']="subTypeControl";
    subtypeObj['Parametr type']="set"
    subtypeObj['System Attribute']=null;
    subtypeObj['System ID']=this.formGroup.get("system").value;
    subtypeObj['User Display Field']="displayName";

    this.inputList.push(subtypeObj);
    this.inputList.sort(this.alarmService.SortOrder("Display Order"));


    this.inputList.forEach(element => {
      if(element['Name']=="plant"){
        element['Name']="Plant";
      }
    });
   
    for(let r of this.inputList){
      this.formGroup.addControl(r['Name'],new UntypedFormControl())
    }
    this.alarmService.preselectionList=[];
    this.routeParams={};
    //this.getOptions();
    if(this.formGroup.get('Plant')){
      this.formGroup.get('Plant').setValue(null);
    }if(this.formGroup.get('Line')){
      this.formGroup.get('Line').setValue(null);
    }

    this.storePreSelections(0);
  }

  compareBySystem(f1: any, f2: any) {
    return f1 && f2 && f1 === f2;
  }

  checkForConfig(key){
    let check=this.alarmService.systemConfigList.some(config => config.Name === key && config['System ID']==this.formGroup.get('system').value)
    return check;
  }


  checkGetData(displaOrder,i){
    this.tableShow=false;
    for(var k=i+1;k<=this.inputList.length-1;k++){
      this.formGroup.get(this.inputList[k]['Name']).setValue(null);
    }
    this.checkForDataBindingAPIS(i);
    if(displaOrder==this.inputList.length){
      this.getAlarmData();
    }

    this.storePreSelections(i);
    this.subTypeService.selectedSubType=undefined;
    this.formGroup.get('subTypeControl').setValue(null);
  }

  checkForDataBindingAPIS(ind){
    let inMandatoryFilled=false;
    let inputJson={};
   for(var i=0;i<this.inputList.length;i++){
     if(!this.inputList[i]['API Binding Attribute']){
      if(this.formGroup.get(this.inputList[i].Name).value){
        inMandatoryFilled=true;
      }else{
        inMandatoryFilled=false;
      }
     }

   }

   if(inMandatoryFilled ){
    for(var i=0;i<this.inputList.length;i++){
        if(this.formGroup.get(this.inputList[i].Name).value){

          if(this.inputList[i]['Name']=="Plant" || this.inputList[i]['Name']=="Line"){
            inputJson[this.inputList[i].Name]=this.formGroup.get(this.inputList[i].Name).value.id;
          }else{
            inputJson[this.inputList[i].Name]=this.formGroup.get(this.inputList[i].Name).value;
          }

      }
     }

    for (var i=0; i<this.inputList.length;i++) {
      var value= this.inputList[i]['API Binding Attribute'];
        if(value != null && i!=ind){
             this.getTestCodes(inputJson,value,i);
         }
      }

   }

  }

  getTestCodes(inputJson,lamdaname,indexValue){
    let checkTestCodesURL;
    if(this.inputList[indexValue]['Name']=="subTypeControl"){
      checkTestCodesURL =environment.baseURL+"/"+lamdaname;

    let System_Id=this.formGroup.get('system').value;
    let inputParams={};
    inputParams['system_id']=System_Id;
    for(let inputListValues of this.inputList){
      if(this.formGroup.get(inputListValues['Name']) && this.formGroup.get(inputListValues['Name']).value){
        inputParams[inputListValues['Name']]=this.formGroup.get(inputListValues['Name']).value.id;
      }
    }
      this.alarmService.subtypeList(inputParams).subscribe(data => {
        this.inputList[indexValue]['List']=data;
        if(this.alarmService.dropDownSelections.subtype){
          let subtypeSlection=this.inputList[indexValue]['List'].find(x => x.sub_type === this.alarmService.dropDownSelections.subtype['sub_type']);
          this.formGroup.get('subTypeControl').setValue(subtypeSlection);
            this.getAlarmData();
            this.storeSubtypeSelection();
        }

        if(this.routeParams.subtype){
          for(var i=0;i<this.inputList[indexValue]['List'].length;i++){
            if(this.inputList[indexValue]['List'][i].sub_type==this.routeParams.subtype){
              this.formGroup.get('subTypeControl').setValue(this.inputList[indexValue]['List'][i]);
              this.getAlarmData();
              this.storeSubtypeSelection();
            }
           }
        }
        

         if(data['length']<=0)
         {
            this._snackBar.open("No Subtypes."," ", {
              duration: 3000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
         }
        });

    }else{
      checkTestCodesURL =environment.baseURL+"/"+lamdaname;
      this.http.post(checkTestCodesURL,inputJson).toPromise().then(data => {
        this.inputList[indexValue]['List']=data['Body']

        // Sorting list alphabatically
        this.inputList[indexValue]['List'].sort(function (x, y) {
          let a = x.Description.toUpperCase(),
          b = y.Description.toUpperCase();
         return a == b ? 0 : a > b ? 1 : -1;
       });

       if(this.routeParams.area){
        for(var i=0;i<this.inputList[indexValue]['List'].length;i++){
          if(this.inputList[indexValue]['List'][i]['id']==this.routeParams.area){
             this.formGroup.get(this.inputList[indexValue]['Name']).setValue(this.inputList[indexValue]['List'][i]);
             this.checkGetData(this.inputList[indexValue]['Display Order'],indexValue)
          }
        }

      }

      if(this.alarmService.dropDownSelections.area){
        for(var i=0;i<this.inputList[indexValue]['List'].length;i++){
          if(this.inputList[indexValue]['List'][i]['id']==this.alarmService.dropDownSelections.area.id){
             this.formGroup.get(this.inputList[indexValue]['Name']).setValue(this.inputList[indexValue]['List'][i]);
             this.checkGetData(this.inputList[indexValue]['Display Order'],indexValue)
          }
        }

      }


    });
    }


  }

  storeSubtypeSelection(){
    this.subTypeService.setSelectedSubType(this.formGroup.get('subTypeControl').value);
  }

  checkPreSelection(){
    this.alarmService.setSystemSelection();
    this.alarmService.setSelectedSystem(this.formGroup.get('system').value);
    this.tableShow=false;

    for(let r of this.inputList){
      this.formGroup.removeControl(r['Name']);
    }
    this.inputList=[];
    for(var i=0;i<this.alarmService.systemConfigList.length;i++){
      if(this.alarmService.systemConfigList[i]['System ID']==this.formGroup.get('system').value){
        this.inputList.push(this.alarmService.systemConfigList[i]);
      }
    }

    //adding subtype to the list
    let subtypeObj={};

    subtypeObj['API Binding']=1;
    subtypeObj['API Binding Attribute']=this.subtypeURL;
    subtypeObj['API Key']="";
    subtypeObj['Display Order']=this.inputList.length+1;
    subtypeObj['Name']="subTypeControl";
    subtypeObj['Parametr type']="set"
    subtypeObj['System Attribute']=null;
    subtypeObj['System ID']=this.formGroup.get("system").value;
    subtypeObj['User Display Field']="displayName";

    this.inputList.push(subtypeObj);
    this.inputList.sort(this.alarmService.SortOrder("Display Order"));

    this.inputList.forEach(element => {
      if(element['Name']=="plant"){
        element['Name']="Plant";
      }
    });

    for(let r of this.inputList){
      this.formGroup.addControl(r['Name'],new UntypedFormControl())
    }

    if(this.formGroup.get('Plant')){
      this.formGroup.get('Plant').setValue(null);
    }if(this.formGroup.get('Line')){
      this.formGroup.get('Line').setValue(null);
    }
  }

  storePreSelections(i){
    //clearing already selected next dropdowns from current dropdown
    for(var k=i+1;k<=this.inputList.length-1;k++){
      this.formGroup.get(this.inputList[k]['Name']).setValue(null);
    }

    //storing dropdown selectiosn for pre populatin in other commponents
    let tempArr=[];
    for(let r of this.inputList){
      if(this.formGroup.get(r['Name']) && this.formGroup.get(r['Name']).value){
        tempArr.push(this.formGroup.get(r['Name']).value);
      }
    }

    this.alarmService.setPreSelections(tempArr);
  }

  

    storePreSelections2(i){

      //storing dropdown selectiosn for pre populatin in other commponents
      let tempArr=[];
      for(let r of this.inputList){
        if(this.formGroup.get(r['Name']) && this.formGroup.get(r['Name']).value){
          tempArr.push(this.formGroup.get(r['Name']).value);
        }
      }
      this.alarmService.setPreSelections(tempArr);
    }

    onPlantSelect(){
      this.lineData=this.siteData.find(x => x.id === this.formGroup.get('Plant').value.id).lines;
    }

      plantPreSelection(){

        if(this.alarmService.dropDownSelections.plant){
          let plantSelection=this.siteData.find(x => x.name == this.alarmService.dropDownSelections.plant.name);
          this.formGroup.get('Plant').setValue(plantSelection);
          this.onPlantSelect();
          if(this.routeParams.line || this.alarmService.dropDownSelections.line){
            this.linePreSelection();
          }
          this.checkForDataBindingAPIS(0);
        }

        if(this.routeParams.plant){
          let plantSelection=this.siteData.find(x => x.name == this.routeParams.plant);
          this.formGroup.get('Plant').setValue(plantSelection);
          this.onPlantSelect();
          if(this.routeParams.line || this.alarmService.dropDownSelections.line){
            this.linePreSelection();
          }
          this.checkForDataBindingAPIS(0);
         
        }
      }

      linePreSelection(){

        if(this.alarmService.dropDownSelections.line){
          let lineSelection=this.lineData.find(x => x.name == this.alarmService.dropDownSelections.line.name);
           this.formGroup.get('Line').setValue(lineSelection);
           this.checkForDataBindingAPIS(1);
           this.storePreSelections2(1);
        }

        if(this.routeParams.line){
          let lineSelection=this.lineData.find(x => x.name == this.routeParams.line);
           this.formGroup.get('Line').setValue(lineSelection);
           this.checkForDataBindingAPIS(1);
           this.storePreSelections2(1);
        }
      }

}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {  MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import {  MatLegacyCheckbox as MatCheckbox } from '@angular/material/legacy-checkbox';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { environment } from '../../../src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SubTypeService } from '../alarms/subtypes/subtype.service';
import { AlarmService } from '../alarms/alarm.service';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { Globals } from '../app.globals';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ConfirmationDialog } from '../confirmation-dialog.component';
import { Alarm } from '../alarms/alarm.model';
import { ConfirmationDialog2 } from '../confirmation-dialog2.component';
import { VerifyUserDLPopup } from '../verify-user-dl-popup/verify-user-dl-popup.component';
import { Observable, Subject, ReplaySubject, Subscription } from 'rxjs';
import { tap, map, filter, pluck, first } from 'rxjs/operators';
import { SelectUserGroupPopup } from '../select-usergroups-popup/select-usergroups-popup.component';
import { AlarmRepeatPopup } from '../alarm-repeat-popup/alarm-repeat-popup.component';


@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class SummaryComponent implements OnInit {

  
  formGroup: UntypedFormGroup;
  alarmTableShow:boolean =false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  siteData:any=[];
  lineData:any=[];
  comparePlant: ((f1: any, f2: any) => boolean) | null = this.compareByPlant;
  compareLine: ((f1: any, f2: any) => boolean) | null = this.compareByLine;
  compareSubType: ((f1: any, f2: any) => boolean) | null = this.compareBySubType;
  compareSystem:((f1: any, f2: any) => boolean) | null = this.compareBySystem;
  comparePlantLine:  ((f1: any, f2: any) => boolean) | null = this.compareByPlantLine;
  compareDynamic: ((f1: any, f2: any) => boolean) | null = this.compareDynamicValue;
  subscribe_list:any[];
  displayedColumns: any[] = ['sub_type', 'alarmCount', 'emailCount', 'smsCount'];
  total_res: any;
  subtypeFromSummary: any = {};
  mobilenumberCount: number = 0;
  emailSubsription:boolean=false;
  subscriberList:boolean = false;
  dataGroupByAlarmName: any =[];
  dataGroupByAlarm:any;
  staticData:any={};
  showTableTypeCheckbox: boolean =false;
  inputList:any=[];
  dynDropDownData:any={};
  tempVarible:any={};
  routeParams:any=[];
  adminSub$: Subscription;
  showSystem:boolean=false;
  dataGroupBySystem:any=[];
  checkAlarmSummaryData:boolean=false;
  readonly checkMobileNumber = environment.userProfileBaseUrl + "/AlarmUserProfileList?Protocol=sms&type=check";
  subscriptionData: any=[];
  filterSystemData: any=[];

  constructor(public alarmService: AlarmService,
    private router: Router,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public subTypeService: SubTypeService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    public _global: Globals,
    public geaMesCognitoAuthService: GeaMesCognitoAuthService,
    private http: HttpClient
  ) {

    this.formGroup = this.formBuilder.group({
      alarm_id: [],
      subtype_id: [],
      desc: [],
      updated_by: [],
      subTypeControl: [],
      criterion: [],
      plantName: [],
      lineName: [],
      system:[],

    });

  }

  ngOnInit() {

    this.route.queryParams.subscribe((params) => {
      this.routeParams=params;
    });

    this.createInputList();

    this.alarmService.getSitesData().subscribe(res => {
    this.siteData=res['Body'];
    this.alarmService.alarmSiteData=res['Body'];  

    // Check for Plant Pre Selection while Navigatiion from Other compoenents
    if(this.alarmService.dropDownSelections.plant){
      this.formGroup.get('Plant').setValue(this.alarmService.dropDownSelections.plant);
      this.getCountData(); // To get Data on screen After plant pre Selection 
      this.onPlantSelect();
    }

    
    // Check For Plant Pre-Selection From URL
    if(this.routeParams.plant){
      let plantSelection=this.siteData.find(x => x.name == this.routeParams.plant);
      if(this.formGroup.get('Plant')){
        this.formGroup.get('Plant').setValue(plantSelection);
        this.alarmService.dropDownSelections.plant=plantSelection;
        this.getCountData(); // To get Data on screen After plant pre Selection  
        this.onPlantSelect();
      } 
    }



    });


    this.alarmService.selectedSystem = undefined;
    
    if(this.alarmService.selectedSystem!=undefined){
          this.showSystem=true;
          this.formGroup.get('system').setValue(this.alarmService.selectedSystem);
    }

    
    // Check to see if this user is an admin
    this.adminSub$ = this.geaMesCognitoAuthService.isUserInRole("Admin").pipe(first()).subscribe( out => {
      this._global.admin = out;
      if (this.adminSub$) this.adminSub$.unsubscribe(); // We've got an answer, can unsubscribe now
    });

    this.http.get(this.checkMobileNumber).toPromise().then(data => {
      let totalresp = data['Body'][0]['ProfileData'];
      for (var i = 0; i < totalresp.length; i++) {
        if (totalresp[i].Protocol == "sms") {
          this.mobilenumberCount++;
        }
        if (totalresp[i].Protocol == "email") {
          this.emailSubsription=true;
        }
      }
    });

  }

  ngOnDestroy() {

    if(this.formGroup.get('Plant')){
      this.alarmService.dropDownSelections.plant=this.formGroup.get('Plant').value;
    }

   if(this.formGroup.get('system')){
      this.alarmService.dropDownSelections.system=this.formGroup.get('system').value;
    }

    if(this.formGroup.get('Line')){
      this.alarmService.dropDownSelections.line=this.formGroup.get('Line').value;
    }

    if(this.formGroup.get('Area')){
      this.alarmService.dropDownSelections.area=this.formGroup.get('Area').value;
    }

  }

  openDialog() {

      const dialogRef = this.dialog.open(ConfirmationDialog, {
        data: {
          message: this.alarmService.emailConfigPopupText,
          buttonText: {
            ok: 'OK'
          }
        }
      });
  }


  


  subscribeAlarm(alarm_id, type,ImmediateAlarm,RepeatInterval) {

    this.alarmService.subscribeAlarm(alarm_id, type,ImmediateAlarm,RepeatInterval).subscribe(res => {
      if (res['errorMessage'] != "") {
        this._snackBar.open("Error occured while Alarm subscription", "Error", {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      } else {
        this._snackBar.open("Alarm subscription", "Success", {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          panelClass: ['Success']
        });
        this.getCountData();
      }
    });

  }

  onSubscriptionTypeSelect(check: MatCheckbox, type: string, alarm: Alarm) {
    let RepeatEnable=false;
    if(alarm['criterion']['RepeatEnable'] && alarm['criterion']['RepeatEnable']=="1"){
      RepeatEnable=true;
    }
    if (check.checked) {

      if (type == "sms") {
          this.openDialog2(alarm, type,RepeatEnable);
      } else {
        this.showAlarmRepeatPopupForEmail(alarm,type,RepeatEnable)
      }

    } else {
      //Add code to unsbscribe
      this.alarmService.unsubscribeAlarm(alarm.alarm_id, type).subscribe(res => {
        let statusMessage = res['StatusMessage'];
        if (res['errorMessage'] != "") {
          this._snackBar.open(statusMessage, "", {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          this.getCountData()
        } else {
          this._snackBar.open("Alarm Unsubscription", "Success", {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['Success'],

          });


        }
      });
    }
  }


  openDialog2(alarm, type,RepeatEnable) {
    if(RepeatEnable){
    if (this.mobilenumberCount > 0) {
      this.showAlarmRepeatPopupForSMS(alarm['alarm_id'],type)
      //this.subscribeAlarm(alarm_id, type,0,0);

    } else {
      
      const dialogRef2 = this.dialog.open(ConfirmationDialog2, {
        width: '480px',
        disableClose: true,
        data: {
          message: 'Please Enter Mobile Number.',
          showRepeatEnableFields:RepeatEnable,
          buttonText: {
            ok: 'Submit',
            cancel: 'Cancel'
          }
        }
      });
  
  
  
      dialogRef2.afterClosed().subscribe(result => {
        if (result != null && result != "" && result != undefined) {
          if (result.data.Protocol != "" && result.data.EndPoint != "") {
            let immediate_alarmCheck="0";
            if(result['data']['ImmediateAlarm']){        
            immediate_alarmCheck="1";
            }
            this.alarmService.addMobileNumber(result.data).subscribe(res => {
              if (res['statusMessage'] == "UserProfile Updated Successfully") {
                this.mobilenumberCount++;
                this.subscribeAlarm(alarm['alarm_id'], type,immediate_alarmCheck,result.data.repaetIntervelValue);
  
              } else {
                this._snackBar.open("Error occured while adding mobile Number", "Error", {
                  duration: 5000,
                  horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,
                });
              }
            });
  
  
          }
        } 
      });
    }
   }else{
    if (this.mobilenumberCount > 0) {
      this.subscribeAlarm(alarm['alarm_id'], type,"","");
    }else{
      const dialogRef2 = this.dialog.open(ConfirmationDialog2, {
        width: '480px',
        disableClose: true,
        data: {
          message: 'Please Enter Mobile Number.',
          showRepeatEnableFields:RepeatEnable,
          buttonText: {
            ok: 'Submit',
            cancel: 'Cancel'
          }
        }
      });
  
  
  
      dialogRef2.afterClosed().subscribe(result => {
        if (result != null && result != "" && result != undefined) {
          if (result.data.Protocol != "" && result.data.EndPoint != "") {
  
            this.alarmService.addMobileNumber(result.data).subscribe(res => {
              if (res['statusMessage'] == "UserProfile Updated Successfully") {
                this.mobilenumberCount++;
                this.subscribeAlarm(alarm['alarm_id'], type,"","");
  
              } else {
                this._snackBar.open("Error occured while adding mobile Number", "Error", {
                  duration: 5000,
                  horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,
                });
              }
            });
  
  
          }
        } 
      });
    }
   }
    
  }

  getCountData() {
    this.dynDropDownData={};
    this.showSystem=true;
    this.alarmTableShow = false;
    this.showTableTypeCheckbox=false;
    this.dataGroupByAlarmName = [];
    this.dataGroupBySystem=[];

    let System_Id=this.formGroup.get('system').value;
    let inputParams={};
    inputParams['system_id']=System_Id;
    for(let inputListValues of this.inputList){
      if(this.formGroup.get(inputListValues['Name']) && this.formGroup.get(inputListValues['Name']).value){
        inputParams[inputListValues['Name']]=this.formGroup.get(inputListValues['Name']).value.id;
      }
    }
    
      this.alarmService.subScribeCountUrl(inputParams).subscribe(data => {
      let totalResp = data['Body'];
      this.dynDropDownData=data['Body'];
    
      
      this.groupDataByAlarm(data['Body']['systemdata']);
      if(this.routeParams.system || this.alarmService.dropDownSelections.system){
        this.preSelectSystem();
      }
      
    });

  }
  navigateToalarm(subtypeData,sysId) {
    this.alarmService.setSystemSelection();
    this.alarmService.setSelectedSystem(sysId);
    this.alarmService.setSelectedAlarm(subtypeData);
    this.subtypeFromSummary['subtype_id'] = subtypeData.subtype_id;
    this.subtypeFromSummary['sub_type'] = subtypeData.sub_type;
    this.subTypeService.setSelectedSubType(this.subtypeFromSummary);
    if(this._global.admin){
      this.router.navigate(["/update-alarm"]);
      this.alarmService.navigatedToUpdateAlramsFrom="summary";
      this.alarmService.navigationSource="summary";
    }

  }

  compareByPlant(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }
  compareByLine(f1: any, f2: any) {
    return f1 && f2 && f1.LineId === f2.LineId;
  }
  compareBySubType(f1: any, f2: any) {
    return f1 && f2 && f1.subtype_id === f2.subtype_id;
  }
  compareByPlantLine(f1: any, f2: any){
    return f1 && f2 && f1.name === f2.name;
  }
  compareDynamicValue(f1: any, f2: any){
    return f1 && f2 && f1.id === f2.id;
  }

  groupDataByAlarm(data){
    data.forEach(systemData => {
      systemData['alamData'].forEach(alarmdata => {
        alarmdata['Sms']=Boolean(alarmdata['Sms'])
        alarmdata['email']=Boolean(alarmdata['email']);
        alarmdata['group']=Boolean(alarmdata['group'])
      });
    
    });
    if(data.length>0){
      this.checkAlarmSummaryData =false;
      this.showSystem = true;
      for (var i = 0; i < data.length; i++) {
        this.dataGroupBySystem.push(data[i]);
        for (var j = 0; j < data[i].alamData.length; j++) {
          let alarmObject=data[i].alamData[j];
          this.dataGroupByAlarmName.push(alarmObject)
        }
      }
        for (var i = 0; i<this.dataGroupByAlarmName.length; i++) {
          this.dataGroupByAlarmName[i]['subscriptionData'].sort(function (x, y) {
              let a="";
              let b="";
              if(x.FirstName){
                a= x.FirstName.toUpperCase(); 
              }else if(x.GroupName){
                a= x.GroupName.toUpperCase();
              }
              if(y.FirstName){
                b= y.FirstName.toUpperCase(); 
              }else if(y.GroupName){
                b= y.GroupName.toUpperCase(); 
              }
              return a == b ? 0 : a > b ? 1 : -1;
            
           });
           this.dataGroupByAlarmName[i]['subscriptionData'].forEach(subscription_data => {
             subscription_data['Sms']=Boolean(Number(subscription_data['Sms']))
             subscription_data['email']=Boolean(Number(subscription_data['email']));
          });
        }
    }else{
        this.checkAlarmSummaryData = true;
        this.showSystem = false;
    }
    this.alarmTableShow=true;

}

filterDataOnPreSelection(data){
  this.dataGroupBySystem=[];
  if(data.length>0){
    this.checkAlarmSummaryData =false;
    this.showSystem = true;
    for (var i = 0; i < data.length; i++) {
      this.dataGroupBySystem.push(data[i]);
      for (var j = 0; j < data[i].alamData.length; j++) {
        let alarmObject=data[i].alamData[j];
        this.dataGroupByAlarmName.push(alarmObject)
      }
    }
      for (var i = 0; i<this.dataGroupByAlarmName.length; i++) {
           this.dataGroupByAlarmName[i]['subscriptionData'].sort(function (x, y) {
              let a="";
              let b="";
              if(x.FirstName){
                a= x.FirstName.toUpperCase(); 
              }else if(x.GroupName){
                a= x.GroupName.toUpperCase();
              }
              if(y.FirstName){
                b= y.FirstName.toUpperCase(); 
              }else if(y.GroupName){
                b= y.GroupName.toUpperCase(); 
              }
              return a == b ? 0 : a > b ? 1 : -1;
          });

      }
  }else{
      this.checkAlarmSummaryData = true;
      this.showSystem = false;
  }
  this.alarmTableShow=true;

}


showSubscribersTable(index,index2){
  for(var i=0;i<this.dataGroupBySystem.length;i++){
    if(i!=index){
      this.dataGroupBySystem[i].showSubscribersTable=false
    }

  }
  if(this.dataGroupBySystem[index].alamData[index2].subscriptionData.length>0){
    if (this.dataGroupBySystem[index].alamData[index2].showSubscribersTable) {
      this.dataGroupBySystem[index].alamData[index2].showSubscribersTable=false;
      return;
  } else {
    this.dataGroupBySystem[index].alamData[index2].showSubscribersTable=true;
  }

  }



}



onSystemSelect(){
  this.showTableTypeCheckbox=false;
  this.alarmService.setSystemSelection();
  this.alarmService.setSelectedSystem(this.formGroup.get('system').value);
  if(this.formGroup.get('Line')){
    this.formGroup.get('Line').setValue(null)
  }
  

  var x=this.alarmService.systemConfigList;
  this.routeParams={};
  this.updateInputList();
}

compareBySystem(f1: any, f2: any) {
  return f1 && f2 && f1 === f2;
}

checkForConfig(key){
  let check=this.alarmService.systemConfigList.some(config => config.Name === key && config['System ID']==this.formGroup.get('system').value);
  return check;
}


 SortOrder(prop) {
  return function(a, b) {
      if (a[prop] > b[prop]) {
          return 1;
      } else if (a[prop] < b[prop]) {
          return -1;
      }
      return 0;
  }
}


checkForDataBindingAPIS(ind){
  let inMandatoryFilled=false;
  let inputJson={};
 for(var i=0;i<this.inputList.length;i++){
   if(!this.inputList[i]['API Binding Attribute']){
    if(this.formGroup.get(this.inputList[i].Name).value){
      inMandatoryFilled=true;
    }else{
      inMandatoryFilled=false;
    }
   }

 }

 if(inMandatoryFilled ){
  for(var i=0;i<this.inputList.length;i++){
      if(this.formGroup.get(this.inputList[i].Name).value){

        if(this.inputList[i]['Name']=="Plant" || this.inputList[i]['Name']=="Line"){
          inputJson[this.inputList[i].Name]=this.formGroup.get(this.inputList[i].Name).value.id;
        }else{
          inputJson[this.inputList[i].Name]=this.formGroup.get(this.inputList[i].Name).value;
        }

    }
   }

  for (var i=0; i<this.inputList.length;i++) {
    var value= this.inputList[i]['API Binding Attribute'];
      if(value != null && i!=ind){
          this.getTestCodes(inputJson,value,i);
       }
    }

 }


}

getTestCodes(inputJson,lamdaname,indexValue){
  let checkTestCodesURL =environment.baseURL+"/"+lamdaname;
  this.http.post(checkTestCodesURL,inputJson).toPromise().then(data => {
    this.inputList[indexValue]['List']=data['Body'];

    if(this.alarmService.dropDownSelections.area ||  this.routeParams.area){
      this.areaPreSelection(indexValue);
      }

    // Sorting list alphabatically
    this.inputList[indexValue]['List'].sort(function (x, y) {
      let a = x.Description.toUpperCase(),
      b = y.Description.toUpperCase();
     return a == b ? 0 : a > b ? 1 : -1;
   });

});
}

storePreSelections(i){
  //clearing already selected next dropdowns from current dropdown
  for(var k=i+1;k<=this.inputList.length-1;k++){
    this.formGroup.get(this.inputList[k]['Name']).setValue(null);
  }

  //storing dropdown selectiosn for pre populatin in other commponents
  let tempArr=[];
  for(let r of this.inputList){
    if(this.formGroup.get(r['Name']) && this.formGroup.get(r['Name']).value){
      tempArr.push(this.formGroup.get(r['Name']).value);
    }
  }

  this.alarmService.setPreSelections(tempArr);
}

checkPreSelection(){
  this.alarmTableShow = false;
  this.showTableTypeCheckbox=false;
  this.alarmService.setSystemSelection();
  this.alarmService.setSelectedSystem(this.formGroup.get('system').value);

  var x=this.alarmService.systemConfigList;

  for(let r of this.inputList){
    this.formGroup.removeControl(r['Name']);
  }

  this.inputList=[];
  for(var i=0;i<this.alarmService.systemConfigList.length;i++){
    if(this.alarmService.systemConfigList[i]['System ID']==this.formGroup.get('system').value){
      this.inputList.push(this.alarmService.systemConfigList[i]);
    }
  }

  this.inputList.sort(this.SortOrder("Display Order"));
  for(let r of this.inputList){
    this.formGroup.addControl(r['Name'],new UntypedFormControl())
  }


  if( this.formGroup.get('Plant')){
    this.formGroup.get('Plant').setValue(null);
  }if(this.formGroup.get('Line')){
    this.formGroup.get('Line').setValue(null);
  }

}



storePreSelections2(i){

  //storing dropdown selectiosn for pre populatin in other commponents
  let tempArr=[];
  for(let r of this.inputList){
    if(this.formGroup.get(r['Name']) && this.formGroup.get(r['Name']).value){
      tempArr.push(this.formGroup.get(r['Name']).value);
    }
  }

  this.alarmService.setPreSelections(tempArr);
}

onPlantSelect(){
this.alarmService.dropDownSelections.plant=this.formGroup.get('Plant').value;
this.filterSystemData =[];
this.inputList.splice(2, this.inputList.length);
this.lineData=this.siteData.find(x => x.id === this.formGroup.get('Plant').value.id).lines;
}

createInputList(){
  this.alarmService.getSystemList().subscribe(res => {
    this.alarmService.saveSystemList(res);
    this.inputList=[];
    for(var i=0;i<this.alarmService.systemConfigList.length;i++){
      if(this.alarmService.systemConfigList[i]['Name']=="plant" || this.alarmService.systemConfigList[i]['Name']=="Plant" ){
        this.inputList.push(this.alarmService.systemConfigList[i]);
        break;
      }
    }
    this.inputList[0].name="Plant";
    this.formGroup.addControl(this.inputList[0].name,new UntypedFormControl())

    let systemObject={
      "API Binding": 1,
      "API Binding Attribute": null,
      "API Key": "",
      "Display Order": 2,
      "Name": "system",
      "Parametr type": "",
      "System Attribute": "",
      "System ID": 1
    }
      this.inputList.push(systemObject);
      this.formGroup.addControl('system',new UntypedFormControl())
  });
}

updateInputList(){
 
   this.inputList.splice(2, this.inputList.length);
  for(var i=0;i<this.alarmService.systemConfigList.length;i++){
    if(this.alarmService.systemConfigList[i]['System ID']==this.formGroup.get('system').value && this.alarmService.systemConfigList[i]['Name']!="Plant"   && this.alarmService.systemConfigList[i]['Name']!="plant"){
      this.inputList.push(this.alarmService.systemConfigList[i]);
    }
  }
  
  this.inputList.sort(this.SortOrder("Display Order"));
  for(var i=1;i<this.inputList.length;i++){
    this.formGroup.addControl(this.inputList[i]['Name'],new UntypedFormControl())
  }
}


filterLinetList(){
  let tempLineData=[];
  for(var i=0;i<this.lineData.length;i++){
    if(this.dynDropDownData['lineList'].includes(this.lineData[i]['id'].toString())){
      tempLineData.push(this.lineData[i]);
    }
  }
  return tempLineData;
  //return data;
}

filterDynamicDropDownList(ind){
  let name=this.inputList[ind]['Name'];
  let data=this.inputList[ind]['List'];
  let filteredList=[];
  for(var i=0;i<data.length;i++){
    if(this.dynDropDownData[name].includes(data[i].id.toString())){
      filteredList.push(data[i]);
    }
  }
  this.inputList[ind]['List']=filteredList;

  
}


lineSelected(){  
       this.lineData=this.filterLinetList()
  
}

systemSelected(){
delete this.alarmService.dropDownSelections['system'];
 var  response = this.alarmService.systemList;
 this.filterSystemData =[];
 for(var i=0;i<response.length;i++){
   if(this.dynDropDownData['systemList'].includes(response[i]['system_id'])){
    this.filterSystemData.push(response[i]);
   } 
  }

  
}

preSelectSystem(){
  var  response = this.alarmService.systemList;
  this.filterSystemData =[];
  for(var i=0;i<response.length;i++){
    if(this.dynDropDownData['systemList'].includes(response[i]['system_id'])){
     this.filterSystemData.push(response[i]);
    } 
   }
  
  // Check for Plant Pre Selection while Navigatiion from Other compoenents
  if(this.alarmService.dropDownSelections.system){
    let systemSelection=this.filterSystemData.find(x => x.system_id == this.alarmService.dropDownSelections.system);
    this.formGroup.get('system').setValue(systemSelection.system_id);
    this.updateInputList();
    if(this.alarmService.dropDownSelections.line ||  this.routeParams.line){
    this.lineSelected();
    this.linePreSelection();
    }
    this.checkForDataBindingAPIS(1);
    this.refreshDataOnPreSelection(); // To get Data on screen After plant pre Selection 
   
  }

  if(this.routeParams.system){
      let systemSelection=this.filterSystemData.find(x => x.system_id == this.routeParams.system);
      this.formGroup.get('system').setValue(systemSelection.system_id);
      this.updateInputList();
     if(this.alarmService.dropDownSelections.line ||  this.routeParams.line){
      this.lineSelected();
      this.linePreSelection();
     }
     this.checkForDataBindingAPIS(1);
     this.refreshDataOnPreSelection();
     

  }

 
  
}

refreshDataOnPreSelection(){
  this.dynDropDownData={};
  this.showSystem=true;
  this.alarmTableShow = false;
  this.showTableTypeCheckbox=false;
  this.dataGroupByAlarmName = [];
  this.dataGroupBySystem=[];

  let System_Id=this.formGroup.get('system').value;
  let inputParams={};
  inputParams['system_id']=System_Id;
  for(let inputListValues of this.inputList){
    if(this.formGroup.get(inputListValues['Name']) && this.formGroup.get(inputListValues['Name']).value){
      inputParams[inputListValues['Name']]=this.formGroup.get(inputListValues['Name']).value.id;
    }
  }
  this.alarmService.subScribeCountUrl(inputParams).subscribe(data => {
    let totalResp = data['Body'];
    this.dynDropDownData=data['Body'];
    this.filterDataOnPreSelection(data['Body']['systemdata']);
  });
}

linePreSelection(){
  if (this.inputList.some(e => e.Name === 'Line')) {
   if(this.alarmService.dropDownSelections.line){
      let lineSelection=this.lineData.find(x => x.name == this.alarmService.dropDownSelections.line.name);
      this.formGroup.get('Line').setValue(lineSelection);
      delete this.alarmService.dropDownSelections.line;
    }

   if(this.routeParams.line){
    for(let r of this.lineData){
      if(r['name']==this.routeParams.line){
       this.formGroup.get('Line').setValue(r);
       delete this.alarmService.dropDownSelections.line;
       break;
      }
    }
   }
  }
}

areaPreSelection(indexValue){
  if (this.inputList.some(e => e.Name === 'Area')) {
    if(this.alarmService.dropDownSelections.area){
      let areaSelection=this.inputList[indexValue]['List'].find(x => x.id == this.alarmService.dropDownSelections.area.id);
      this.formGroup.get('Area').setValue(areaSelection);
      delete this.alarmService.dropDownSelections.area;
      this.refreshDataOnPreSelection(); // To get Data on screen After plant pre Selection 
    }

    if(this.routeParams.area){
      let areaSelection=this.inputList[indexValue]['List'].find(x => x.id == this.routeParams.area);
      this.formGroup.get('Area').setValue(areaSelection);
      delete this.alarmService.dropDownSelections.area;
      this.refreshDataOnPreSelection(); // To get Data on screen After plant pre Selection 
    }  
  }
}

clearSelection(ind){
    this.alarmService.dropDownSelections={};
    this.routeParams={};
    for(var i=ind+1;i<this.inputList.length;i++){
      this.formGroup.get(this.inputList[i]['Name']).setValue(null);
    }
    this.getCountData();
  
}

onUserGroupSelect(check: MatCheckbox, alarm: Alarm){
  if (check.checked) {
    this.showUserGroupSelectionPopup(alarm)
  }else{
    let outJson={};
    outJson['groupIDList']=[];
    outJson['alarmId']=alarm['alarm_id'];
    this.alarmService.AlarmGroupSubscriptionUpdate(outJson).subscribe(res => {
      if(res['statusCode']==1){
        this._snackBar.open("Alarm UserGroup Subscription", "Success", {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          panelClass: ['Success']
        });
        this.getCountData();
      }
     });
  }  
}

showUserGroupSelectionPopup(alarm: Alarm){
  let RepeatEnable=false;
  if(alarm['criterion']['RepeatEnable'] && alarm['criterion']['RepeatEnable']=="1"){
    RepeatEnable=true;
  }
  const dialogRef3 = this.dialog.open(SelectUserGroupPopup, {
    width: '480px',
    disableClose: true,
    data: {
      Plant : this.formGroup.get('Plant').value.name,
      alarm_data:alarm,
      showRepeatEnableFields:RepeatEnable,
    }
  });

  dialogRef3.afterClosed().subscribe(popupData => {
    if(popupData){
       let outJson=popupData['data'] ;
       this.alarmService.AlarmGroupSubscriptionUpdate(outJson).subscribe(res => {
        if(res['statusCode']==1){
          this._snackBar.open("Alarm UserGroup Subscription", "Success", {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['Success']
          });
          this.getCountData();
        }
       });
    }else{

    } 
  }); 
}

showAlarmRepeatPopupForEmail(alarm: Alarm,type,RepeatEnable){
  if(RepeatEnable){
  const dialogRef = this.dialog.open(AlarmRepeatPopup, {
    width: '480px',
    disableClose: true,
    data: {}
  });

  dialogRef.afterClosed().subscribe(popupData => {
      if(!this.emailSubsription){
        let json={};
        json['EndPoint']="";
        json['Protocol']="email";
        let immediate_alarmCheck="0";
        if(popupData['data']['ImmediateAlarm']){        
         immediate_alarmCheck="1";
        }this.alarmService.addMobileNumber(json).subscribe(res => {
           if (res['statusMessage'] == "UserProfile Updated Successfully") {
              this.emailSubsription=true;
              this.openDialog();
              this.subscribeAlarm(alarm.alarm_id, type,immediate_alarmCheck,popupData['data']['repaetIntervelValue']);
          } else {
            this._snackBar.open("Error occured while crearting email subscription", "Error", {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
          }
        });

        
      }else{
        let immediate_alarmCheck="0";
        if(popupData['data']['ImmediateAlarm']){        
         immediate_alarmCheck="1";
        }
        this.subscribeAlarm(alarm.alarm_id, type,immediate_alarmCheck,popupData['data']['repaetIntervelValue']);
      }
    
  });
}else{
  if(!this.emailSubsription){
    let json={};
    json['EndPoint']="";
    json['Protocol']="email";
    this.alarmService.addMobileNumber(json).subscribe(res => {
       if (res['statusMessage'] == "UserProfile Updated Successfully") {
          this.emailSubsription=true;
          this.openDialog();
          this.subscribeAlarm(alarm.alarm_id, type,"","");
      } else {
        this._snackBar.open("Error occured while crearting email subscription", "Error", {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    });

    
  }else{
    this.subscribeAlarm(alarm.alarm_id, type,"","");
  }
}

}

showAlarmRepeatPopupForSMS(alarm_id,type){
  const dialogRef = this.dialog.open(AlarmRepeatPopup, {
    width: '480px',
    disableClose: true,
    data: {}
  });

  dialogRef.afterClosed().subscribe(popupData => {
    let immediate_alarmCheck="0";
        if(popupData['data']['ImmediateAlarm']){        
         immediate_alarmCheck="1";
    }
    this.subscribeAlarm(alarm_id, type,immediate_alarmCheck,popupData['data']['repaetIntervelValue']);
  });

 
  
}


}
  
import { Component, OnInit, Inject } from '@angular/core';
import {GEA_MES_NAVBAR_MENU_SERVICE} from '@gea-mes/navbar';
import {NavItemService,NavItem} from './nav-item.service';
import { BehaviorSubject,Subscription} from "rxjs";
import { map } from 'rxjs/operators';
import { AlarmService } from './alarms/alarm.service';
import { Router } from '@angular/router'
import { Location } from '@angular/common';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'brilliant-alarming';
  route_path = '';
  constructor(public alarmService: AlarmService, 
    public navService:NavItemService,
    private router: Router, 
    private location: Location,
    @Inject(GEA_MES_NAVBAR_MENU_SERVICE) private NavItem: BehaviorSubject<NavItem[]>) { 
      this.router.events.subscribe((val) => {
        this.route_path = this.location.path();
      });
    NavItem.next(navService.navItems);
    }

  ngOnInit() {}
}

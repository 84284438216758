/*
* Description: Static Application Information
*
* History:
*   12/29/2022  A Kuprion   ITMFG-32662 Initial version, needed for Angular upgrade as package.json can longer be read.
*   01/18/2022  A Kuprion ITMFG-27821 Initial version, needed because package.json can no longer be read
*
**/
export const appinfo = {
    'name': 'alarm',
    'version': '1.0.0'
}
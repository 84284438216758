<div class="container">
    
    <table matSort class="width_100 AlarmTable" matSort>
      <thead>
        <tr>
          <th (click)="sort('plantName',booleanValue)" mat-sort-header = "'plantName'">Plant</th>
          <th (click)="sort('AlarmName',booleanValue)" mat-sort-header = "'AlarmName'">Alarm Name</th>
          <th (click)="sort('system_name',booleanValue)" mat-sort-header="'system_name'">System</th> 
          <th  (click)="sort('subtypeDesc',booleanValue)" mat-sort-header="'subtypeDesc'">SubType</th>         
          <th class="coustomHeader">
            <div class="subscriptionHeading">Subscription</div>
            <div>
              <span class="emailHeading">Email</span>
              <span class="smsHeading">SMS</span>
              <span class="groupHeading hideForMobile">UserGroup</span>
              <span class="groupHeading hideForDesktop">UsrGrp</span>
            </div>
          </th>
        </tr>
      </thead>          
      <tbody>
          <tr *ngFor="let data of dataGroupByAlarmName">
            <td  style="padding-left: 5px;" width="10%">{{data.plantName}}</td>
            <td  style="padding-left: 5px;" width="20%">{{data.AlarmName}}</td>
            <td  style="padding-left: 5px;" width="20%">{{data.system_name}}</td>
           <td  style="padding-left: 5px;" width="20%">{{data.subtypeDesc}}</td>
           <td width="30%">
            <table>
              <tr>
                <td>
                  <mat-checkbox  class="" (change)="onSubscriptionTypeSelect($event,'email', data)" [checked]="data.email"> </mat-checkbox>
                  <span class="material-icons repeatIcon" [ngClass]="data['criterion']['RepeatEnable']!='1' ? 'displayHidden' : 'null'">refresh</span>
                </td>
                <td>
                  <mat-checkbox  class="" (change)="onSubscriptionTypeSelect($event,'sms', data)" [checked]="data.Sms"></mat-checkbox>
                  <span class="material-icons repeatIcon" [ngClass]="data['criterion']['RepeatEnable']!='1' ? 'displayHidden' : 'null'">refresh</span>
                </td>
                <td>
                  <mat-checkbox  class="" (change)="onUserGroupSelect($event, data)" [checked]="data.group" disabled></mat-checkbox> 
                </td>
              </tr>
            </table>
          </td>
          </tr>
          <tr *ngIf= "checkSubsriptionData">
              <td colspan ='3' style="text-align:center !important"> No Data Found</td>
          </tr>
      </tbody>
    </table>
  </div>
<div class="container enitrePage">
    <div >
        <a mat-raised-button *ngIf="this._global.admin" class="createNewButton box" style="height: 45px !important;margin: 0px 10px 0px 15px;"  [disabled]="!this._global.admin" routerLink="/userGroupsConfig-Create">Create New User Group</a>
        <a mat-raised-button *ngIf="!this._global.admin" class="createNewButton box" style="height: 45px !important;margin: 0px 10px 0px 15px;"  [disabled]="!this._global.admin" >Create New User Group</a>
    </div>
    <form [formGroup]="formGroup" class="float_right inputForm"> 
        <mat-form-field class="slicer">
            <mat-label>Plant</mat-label>
            <mat-select (selectionChange)="getAlarmGroupsInfo();onPlantSelecetd();" formControlName="Plant" required  [value]="alarmService.selectedSystem" [compareWith]="comparePlant">
            <mat-option value="null" disabled selected>Select...</mat-option>
            <mat-option *ngFor="let s of siteData" [value]="s">
                {{s.name}}
            </mat-option>
            </mat-select>
        </mat-form-field>
    </form>

    <table mat-table [dataSource] = "alarm_groups"  class="alarmsGroupListTable" matSort [matSortActive]="sort.active" matSortDirection="asc"  > 
  
        <ng-container matColumnDef = "GroupName">
           <th  mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]=" 'headerStyleGroupName'" > Group Name</th>
          
           <td  mat-cell *matCellDef = "let element" [ngClass]="'colStyleGroupName'"  style="cursor:pointer" (click)="onUserGroupClick(element)"> {{element.GroupName}} </td>
         
        </ng-container>
        <ng-container matColumnDef = "Description">
         
          <th  mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'headerStyleDescription'" >Description</th>
          <td     mat-cell *matCellDef = "let element" [ngClass]="'colStyleDescription'" style="cursor:pointer" (click)="onUserGroupClick(element)">{{element.Description}} </td>
      
        </ng-container>
        <ng-container matColumnDef = "TeamChannel">
         
            <th  mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'headerStyleTeamsChannel'" >Teams Channel</th>
            <td     mat-cell *matCellDef = "let element" [ngClass]="'colStyleTeamsChannel'" style="cursor:pointer" (click)="onUserGroupClick(element)">{{element.TeamChannel}} </td>
        
          </ng-container>
        <tr mat-header-row *matHeaderRowDef = "displayedColumns"></tr>
        <tr mat-row *matRowDef = "let row; columns: displayedColumns;" ></tr>
      </table>
</div>
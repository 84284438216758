import { Component } from "@angular/core";
import { Globals } from "../app.globals";
import { Router } from '@angular/router'
import { Location } from '@angular/common';

@Component({
  selector: "app-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.scss"],
})
export class SideNavComponent {
  path = '';
  constructor(
    public _global: Globals,
    private router: Router, 
    private location: Location
    ) {
      this.router.events.subscribe((val) => {
        this.path = this.location.path();
      });
    }
}

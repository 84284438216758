
<mat-dialog-content>
    <span style="text-align: center;" class="font_size" *ngIf="user_data.data.Type=='DL'"><h4 ><b>Not Confirmed User List</b></h4></span>

    <div *ngIf="user_data.type=='DL'">
        <table align="center"  style="margin-top: 5px;height: 100px;width:100%;" class="dltable">
            <tr>
                <td class="dltabletd1 boldFont"> DL Name: </td>
                <td class="dltabletd2"> {{user_data.data.DLName}} </td>
                <td class="dltabletd3"> </td>
            </tr> 
            <tr>
                <td class="boldFont"> DL Users:</td>
                <td>
                <div class="DlUserListDiv">
                    <div *ngFor="let user of user_data['data']['DLUsers']">
                        <div (click)="onUserSelect(user)" class="cursorPointer" [ngClass]="user['selectedForRetrySubscription']==true ? 'selectedOption' : 'nonSelectedOption'" *ngIf="user.Active==0">{{user.First}} {{user.Last}}</div>
                </div></div>
                </td>
                <td></td>
            </tr>
        </table>
    </div>

    <div *ngIf="user_data.type=='User'" class="singleUserConent">
       Are you sure you want to resend confirmation email ?
    </div>
        
</mat-dialog-content>


<mat-dialog-actions align="center" style="margin-top: 25px !important;margin-bottom:5px">
  	<button class="submit"  mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1"  [disabled]="!isRetryValid">Resend Confirmation</button>
    <button class="cancel"  mat-raised-button color="primary" (click)="onCancelClick()" tabindex="2">Cancel</button>
</mat-dialog-actions>
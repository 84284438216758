<h1 style="text-align:center;color:#337ab7" *ngIf="type">Create Alarm</h1>
<div style="color:#337ab7;width: 100%;display: flex;" *ngIf="!type">
  <div style="width: 50%;text-align: right;font-size: 24px;">Update Alarm</div>
  <div style="width: 50%;text-align: right;padding-right: 4%;" *ngIf="alarmService.selectedAlarm">Expiration Date : {{alarmService.selectedAlarm['expiration_date']}}
    <button style="margin-bottom: 10px;background: #337ab7 !important;line-height: 25px !important;" mat-raised-button color="primary" (click)="onExpireAlarmUpdate()" class="update" >Renew</button>
  </div>
</div>

<form [formGroup]="formGroup" (ngSubmit)="onFormSubmit(formGroup)">
  <div class="parent">
    <input type="hidden" formControlName="alarm_id">
    <input type="hidden" formControlName="subtype_id">
    <input type="hidden" formControlName="criterion">
    <input type="hidden" formControlName="desc">
    <table class="plantTable">

      <!--System Dropdown-->
      <tr>
        <td >
          <mat-form-field class="slicer">
            <mat-label>System</mat-label>
            <mat-select (selectionChange)="onSystemSelect()" formControlName="system" [compareWith]="compareSystem" >
              <mat-option value="null" disabled selected>Select...</mat-option>
              <mat-option *ngFor="let sys of alarmService.systemList" [value]="sys.system_id">
                {{sys.system_name}}
              </mat-option>
            </mat-select>
             <mat-hint>Select System</mat-hint> 
          </mat-form-field>
        </td>
      </tr>

       <!--Subtype Dropdown-->
      <tr>
        <td *ngIf="isSytemSelected">
          <mat-form-field class="slicer">
            <mat-label>Sub Type</mat-label>
            <mat-select (selectionChange)="onSubTypeSelect()" formControlName="subTypeControl" [compareWith]="compareFn" required>
              <mat-option value="null" disabled selected>Select...</mat-option>
              <mat-option *ngFor="let subType of subtypeList" [value]="subType">
                {{subType.short_desc}}
              </mat-option>
            </mat-select>
            <mat-hint>{{formGroup.get('subTypeControl').value?.long_desc}}</mat-hint>
          </mat-form-field>

        </td>
      </tr>
      
      <tr *ngFor="let subTypeParam of subTypeParamslist; index as i">
        <!--Input Feilds For Create Alarm-->
        <td *ngIf="!subTypeParam.API_Binding && type && !subTypeParam.API_Binding_Attribute">
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>{{subTypeParam.desc}}</mat-label>
            <!-- (blur)="checkIsFiledIsMandatory(subTypeParam.mandatory_field);" -->
            <input [required]="subTypeParam.mandatory_field==1" [formControlName]="subTypeParam.name" matInput [placeholder]="subTypeParam.desc" [pattern]="subTypeParam.validation_rules" (keypress)="restrictSpecialCharachters($event,subTypeParam.name)"  autocomplete="off">
            <mat-hint>{{subTypeParam.help_text}}</mat-hint>
          </mat-form-field>
          <mat-error>
            <span *ngIf="formGroup.get(subTypeParam.desc) && !formGroup.get(subTypeParam.desc).valid && formGroup.get(subTypeParam.desc).touched">Please enter {{subTypeParam.desc}} !!!</span>
          </mat-error>
        </td>

      
        <!--Multi Select Dropdowns for Create Alarm-->
        <td *ngIf="!subTypeParam.API_Binding && type && subTypeParam.API_Binding_Attribute 
        && subTypeParam.paramType==='set'">
          <mat-list-item  role="listitem" class="multiselectDropdowm"  (click)="checkForDataBindingAPIS(i)">
            <mat-form-field class="select-form multiselect">
                <mat-select  
                placeholder={{subTypeParam.desc}} 
                name="year" 
                class="filter-select" 
                [formControlName]="subTypeParam.name"             
                multiple 
                #yearSelect            
                >
                  <mat-option disabled="disabled" class="filter-option">
                    <button 
                    mat-raised-button 
                    class="mat-accent fill text-sm update" 
                    (click)="yearSelect.close()">
                    Cancel
                    </button>
                    <button 
                    mat-raised-button 
                    class="mat-accent fill text-sm update" 
                    (click)="deselectAll(subTypeParam.name)">
                    Cancel
                    </button>
                  </mat-option>
                  <mat-option *ngFor="let s of subTypeParam.listValue" [value]="s">
                     {{s.Description}}
                  </mat-option>
                </mat-select>
                <mat-hint>{{subTypeParam.help_text}}</mat-hint>
            </mat-form-field>
          </mat-list-item>
        </td>
        <!--Single Select Dropdowns for Create Alarm-->
        <td *ngIf="!subTypeParam.API_Binding && type && subTypeParam.API_Binding_Attribute
         && subTypeParam.paramType==='scalar'">
            <mat-form-field class="select-form multiselect" (click)="checkForDataBindingAPIS(i)">
                <mat-select [required]="subTypeParam.mandatory_field==1" placeholder={{subTypeParam.desc}} 
                [formControlName]="subTypeParam.name" (selectionChange)="clearLists(i)">
                <mat-option value="null" disabled selected>Select...</mat-option>
                  <mat-option *ngFor="let s of subTypeParam.listValue" [value]="s">
                     {{s.Description}}
                  </mat-option>
                </mat-select>
                <mat-hint>{{subTypeParam.help_text}}</mat-hint>
            </mat-form-field>
        </td>  
        <!--Plant / Line for Create Alarm From appSync -->
        <td *ngIf="subTypeParam.paramType==='scalar'">
          
          <ng-container *ngIf=" subTypeParam.API_Binding && type && !subTypeParam.API_Binding_Attribute && subTypeParam.name=='Plant'" >
            <mat-form-field class="slicer">
              <mat-label>{{subTypeParam.desc}}</mat-label>
              <mat-select [required]="subTypeParam.mandatory_field==1" (selectionChange)="clearLists(i);onPlantSelect()" [formControlName]="subTypeParam.name" [compareWith]="comparePlantLine">
                <mat-option value="null" disabled selected>Select...</mat-option>
                <mat-option *ngFor="let s of siteData" [value]="s">
                  {{s.name}}
                </mat-option>
              </mat-select>
              <mat-hint>{{subTypeParam.help_text}}</mat-hint>
            </mat-form-field>
          </ng-container>

          <ng-container *ngIf=" subTypeParam.API_Binding && type && !subTypeParam.API_Binding_Attribute && subTypeParam.name=='Line'">
            <mat-form-field class="slicer">
              <mat-label>{{subTypeParam.desc}}</mat-label>
              <mat-select [required]="subTypeParam.mandatory_field==1" (selectionChange)="clearLists(i)" [formControlName]="subTypeParam.name" [compareWith]="comparePlantLine">
                <mat-option value="null" disabled selected>Select...</mat-option>
                <mat-option *ngFor="let s of lineData" [value]="s">
                  {{s.name}}
                </mat-option>
              </mat-select>
              <mat-hint>{{subTypeParam.help_text}}</mat-hint>
            </mat-form-field>
          </ng-container>

    </td>
      </tr>
      <tr *ngFor="let subTypeParam of subTypeParamslist; index as i">
        <td *ngIf="!type">
          <ng-container *ngIf="!subTypeParam.API_Binding_Attribute">
            <mat-form-field class="demo-full-width" appearance="outline">
              <mat-label>{{subTypeParam.desc}}</mat-label>
              <input [formControlName]="subTypeParam.name" matInput [placeholder]="subTypeParam.desc" [pattern]="subTypeParam.validation_rules" autocomplete="off">
              <mat-hint>{{subTypeParam.help_text}}</mat-hint>
            </mat-form-field>
            <mat-error>
              <span *ngIf="formGroup.get(subTypeParam.desc) && !formGroup.get(subTypeParam.desc).valid && formGroup.get(subTypeParam.desc).touched">Please enter {{subTypeParam.desc}} !!!</span>
            </mat-error>
          </ng-container>
          
          <ng-container *ngIf="subTypeParam.API_Binding_Attribute && subTypeParam.paramType==='set'">
            <mat-list-item  role="listitem" class="multiselectDropdowm" (click)="checkForDataBindingAPIS(i)">
                <mat-form-field class="select-form multiselect">
                    <mat-select 
                    placeholder={{subTypeParam.desc}} 
                    name="year" 
                    class="filter-select" 
                    [formControlName]="subTypeParam.name"
                    multiple 
                    #yearSelect>
                      <mat-option disabled="disabled" class="filter-option">
                       
                        <button 
                        mat-raised-button 
                        class="mat-accent fill text-sm update" 
                        (click)="deselectAll(yearSelect)">
                        Cancel
                        </button>
                      </mat-option>
                      <mat-option *ngFor="let s of subTypeParam.listValue" [value]="s">
                         {{s.Description}}
                      </mat-option>
                    </mat-select>
                    <mat-hint>{{subTypeParam.help_text}}</mat-hint>
                </mat-form-field>
              </mat-list-item>        
          </ng-container>

          <ng-container *ngIf="subTypeParam.API_Binding_Attribute && subTypeParam.paramType==='scalar'">
              <mat-form-field class="select-form multiselect" (click)="checkForDataBindingAPIS(i)">
                <mat-select  placeholder={{subTypeParam.desc}} 
                [formControlName]="subTypeParam.name"  [compareWith]="compareDynamic" (selectionChange)="clearLists(i)">
                <mat-option value="null" disabled selected>Select...</mat-option>
                  <mat-option *ngFor="let s of subTypeParam.listValue" [value]="s">
                    {{s.Description}}
                  </mat-option>
                </mat-select>
                <mat-hint>{{subTypeParam.help_text}}</mat-hint>
            </mat-form-field>                
          </ng-container>
  
        </td>
      </tr>


      <div style="width:100%;margin-top: 20px !important" class="buttonGroup">
        <button mat-raised-button color="primary" [disabled]="!isSubtypeSelected" class="update" *ngIf="type">Save</button>
        <button mat-raised-button color="primary" [disabled]="!isSubtypeSelected" class="update"  *ngIf="!type">Update</button>
        <button mat-raised-button color="primary" (click)="checknavigation()"  type="reset" class="cancel" >Cancel</button>
      </div>
    </table>
  </div>
</form>
import { Component, Inject } from '@angular/core';
import {  MatLegacyDialogRef as MatDialogRef,  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {  UntypedFormGroup } from '@angular/forms';
import { AlarmService } from '../alarms/alarm.service';
import {  MatLegacyCheckbox as MatCheckbox } from '@angular/material/legacy-checkbox';

@Component({
  selector: 'alarm-repeat-popup',
  templateUrl: 'alarm-repeat-popup.html',
  styleUrls: ['alarm-repeat-popup.css']

})
export class AlarmRepeatPopup {
  formGroup: UntypedFormGroup;
  showSpinner:boolean=false;
  checkUserGroupData:boolean;
  repaetIntervelValue:number=0;
  ImmediateAlarm :boolean=false;
  isrepaetIntervelValueValid:boolean=true;
  alarm_data;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public alarmService: AlarmService,
    private dialogRef: MatDialogRef<AlarmRepeatPopup>) {
      let plant_name=this.data.Plant;
      this.alarm_data=data.alarm_data;
      let inputParams = {};
      inputParams['Plant']=plant_name;
      this.showSpinner=true;
      
  }

  userGroupSelectionChange(check: MatCheckbox,user_group){
    if(check.checked){
      user_group['checked']=1;
    }else{
      user_group['checked']=0; 
    }
  }
  
  repaetIntervelValueCheck(val){
   if (this.repaetIntervelValue>=5 || this.repaetIntervelValue==0) {
        this.isrepaetIntervelValueValid=true;
    }else if(this.repaetIntervelValue==null){
	    this.isrepaetIntervelValueValid=true;
	    this.repaetIntervelValue=0;
    }
    else{
    	this.isrepaetIntervelValueValid=false;
    }
  }


  onConfirmClick(): void {
    let outJson={};
    outJson['repaetIntervelValue']=this.repaetIntervelValue;
    outJson['ImmediateAlarm']=this.ImmediateAlarm;
    this.dialogRef.close({event:true,data:outJson});
    
  }

  onCancelClick():void{

  }



}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlarmsComponent } from './alarms/alarms.component';
import { AlarmManagerComponent } from './alarms/alarm-manager/alarm-manager.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import {GEA_MES_NAVBAR_COGNITO_CONFIG,GEA_MES_NAVBAR_APPLICATION_NAME,GEA_MES_NAVBAR_COGNITO_AUTH_SERVICE,GEA_MES_NAVBAR_MENU_SERVICE,GEA_MES_NAVBAR_ENV_PROD_STATUS,GEA_ENVIROMENT,GEA_MES_NAVBAR_APP_ID_FOR_WHATSNEW_TEXT} from '@gea-mes/navbar';
import { GeaMesLoginComponent, GeaMesCognitoAuthGuard, GeaMesCognitoModule, COGNITO_CONFIG, GeaMesAuthInterceptor,GeaMesCognitoAuthService, APPLICATION_NAME, APIGATEWAY_CONFIG} from '@gea-mes/cognito';
import {NavItem} from './nav-item.service';
import {BehaviorSubject} from 'rxjs';
import { environment } from 'src/environments/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SummaryComponent } from './summary/summary.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserGroupsComponent } from './user-groups/user-groups.component';
import { UserGroupsConfigComponent } from './user-groups-config/user-groups-config.component';
import { appinfo } from './appinfo';
import { MyalarmsComponent } from './myalarms/myalarms.component';
import { LogOutPageComponent} from './log-out-page/log-out-page.component'
import { HelpDocumentComponent } from './help-document/help-document.component'

const routes: Routes = [
  { path: 'login', component: GeaMesLoginComponent},
  { path: 'logout', component: LogOutPageComponent},

  {
    path: '', canActivate:[GeaMesCognitoAuthGuard],
    children: [
      { path: '', redirectTo: '/summary', pathMatch: 'full' },
      { path: 'alarms', component: AlarmsComponent },
      { path: 'create-alarm', component: AlarmManagerComponent, data: { type: 'create' } },
      { path: 'update-alarm', component: AlarmManagerComponent, data: { type: 'update' } },
      { path: 'summary', component: SummaryComponent },
      { path: 'summary/:plant', component: SummaryComponent },
      { path: 'summary/:plant/:line', component: SummaryComponent },
      { path: 'userProfile', component: UserProfileComponent },
      { path: 'userGroups', component: UserGroupsComponent },
      { path: 'userGroupsConfig-Create', component: UserGroupsConfigComponent, data: { type: 'create' } },
      { path: 'userGroupsConfig-Update', component: UserGroupsConfigComponent, data: { type: 'update' } },
      { path: 'myAlarms', component: MyalarmsComponent},
      { path: 'helpDocument', component: HelpDocumentComponent}
    ]
  },
  { path: '**', redirectTo: '/summary' }
];


@NgModule({
  imports: [GeaMesCognitoModule, RouterModule.forRoot(routes, {})],
  providers: [
    { provide: GEA_MES_NAVBAR_APPLICATION_NAME, useValue: "Alarm Manager" },
    { provide: GEA_MES_NAVBAR_COGNITO_CONFIG, useValue: environment.cognitoOptions },
    { provide: GEA_MES_NAVBAR_COGNITO_AUTH_SERVICE, useClass: GeaMesCognitoAuthService },
    { provide: GEA_MES_NAVBAR_ENV_PROD_STATUS, useValue: environment.production },
    { provide: COGNITO_CONFIG, useValue: environment.cognitoOptions },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: GeaMesAuthInterceptor, multi: true },
    { provide: APPLICATION_NAME, useValue: appinfo.name.toUpperCase() },
    { provide: APIGATEWAY_CONFIG, useValue: environment.apiGateway },
    { provide: GEA_ENVIROMENT, useValue: environment },
    { provide: GEA_MES_NAVBAR_MENU_SERVICE, useValue: new BehaviorSubject<NavItem[]>([]) },
    { provide: GEA_MES_NAVBAR_APP_ID_FOR_WHATSNEW_TEXT,useValue:"Test Alarms"}
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { SubType } from './subtype.model';
import { Plant } from './plant.model';
import { Line } from './line.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubTypeService {
  list: SubType[];
  selectedSubType: SubType;
  selectedPlant:Plant;
  selectedLine:Line;
  globalTestCodeList:any[];
  readonly rootURL = environment.apiGwUrl + "/AlarmListingSubTypes?alarm_id="
  constructor(private http: HttpClient) { }

  refreshList(alarm_id: number) {
    this.http.get(this.rootURL + alarm_id)
      .toPromise().then(res => this.list = res as SubType[]);
  }
  getSubtypes(alarm_id: number) {
  return this.http.get(this.rootURL + alarm_id);   
  }

  aSynchRefreshList(alarm_id: number) {
    return this.http.get(this.rootURL + alarm_id);
  }

  setSelectedSubType(subType: SubType) {
    this.selectedSubType = subType;
  }

  setSelectedPlant(plant: Plant) {
    this.selectedPlant = plant;
  }

  setSelectedLine(line: Line) {
    this.selectedLine = line;
  }
}

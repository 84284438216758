

<mat-dialog-content>
    <span style="text-align: center;" class="font_size"><h4 ><b>Select User Groups</b></h4></span>
        <mat-spinner [diameter]="30" *ngIf="showSpinner" strokeWidth="15"></mat-spinner>
        <table *ngIf = "!checkUserGroupData" align="center" style="height: 22vh;" class="UserGroupListTable">
          <thead *ngIf="!showSpinner">
            <tr>
              <th class="group_checkbox_column"></th>
              <th class="group_namedisplay_column"></th>
              <th class="group_immediate_column" *ngIf="showRepeatEnableFields">Immediate</th>
              <th class="group_repeat_column"  *ngIf="showRepeatEnableFields">Repeat (m)</th>
            </tr>
          </thead>
          <tr *ngFor="let userGroup of userGroupList">
            <td class="group_checkbox_column"><mat-checkbox class="email_checkbox" (change)="userGroupSelectionChange($event,userGroup)" [checked]="userGroup.checked"> </mat-checkbox></td>
            <td class="userGroupName group_namedisplay_column">{{userGroup.GroupName}}</td>
            <td class="group_immediate_column"  *ngIf="showRepeatEnableFields"><mat-checkbox class="email_checkbox" (change)="ImmediateAlarmchange($event,userGroup)"  [checked]="userGroup.EmailImmedidateAlarm"></mat-checkbox></td>
            <td class="group_repeat_column"  *ngIf="showRepeatEnableFields"><input type="number" class="repaet_input" [(ngModel)]="userGroup.EmailRepeatInterval" (keyup)="repaetIntervelValueCheck(userGroup)" (change)="repaetIntervelValueCheck(userGroup)"/></td>
          </tr>
        </table> 
        <table *ngIf = "checkUserGroupData"  class="no_group_data" > 
         <td align = "center" style= "padding-right: 5px; text-align:center;"> No User Group Data Found </td>
        </table> 
</mat-dialog-content>


<mat-dialog-actions align="center" style="margin-top: 25px !important;" >
<div class="warn_text" *ngIf="showRepeatEnableFields"> Note: Repeat Interval value supports >5 0r =0 values only.</div>
  <button *ngIf = "checkUserGroupData" class="cancel"  mat-raised-button mat-dialog-close tabindex="-1" (click)="onCancelClick()">OK</button>
	<button *ngIf = "!checkUserGroupData"class="submit"  mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1" [disabled]="showRepeatEnableFields && !isrepaetIntervelValueValid">Submit</button>
	<button *ngIf = "!checkUserGroupData"class="cancel"  mat-raised-button mat-dialog-close tabindex="-1" (click)="onCancelClick()">Cancel</button>

</mat-dialog-actions>




<div class="container desktop_display" >
<mat-spinner *ngIf="progressShow"></mat-spinner>
  <div class="block_1"  [ngClass]=" progressShow ? 'loader' : 'null'">
    <table >
      <tr *ngFor="let mobile of mobileNumbers;let i=index" class="phone_tr">
        <td>
          <select class="country_code" [disabled]="mobile.readonly">
            <option value="+1">+1</option>
          </select>
        </td>
        <td>
          <input class="phone_input" maxlength="14"  [disabled]="mobile.readonly" placeholder="(xxx) xxx - xxxx" (keypress)="numberOnly($event)" [(ngModel)]="mobile.mobileNumber" (ngModelChange)="valuechange($event,i)" (keypress)="formatPhoneNumber(i)">  
        </td>
        <td  *ngIf="mobile.del"> 
          <button class="addDelButton"  mat-raised-button (click)="deleteMobileNumber(mobile.mobileNumber,i)" color="warn" >-</button>
        </td>
      </tr>
      <tr *ngIf="showSaveButton">
        <td></td>
        <td style="text-align: center;"> <button   [disabled]="progressShow" mat-raised-button class="update saveButton applyAll" (click)="onConfirmClick()" tabindex="1" >Save</button> </td>
        <td></td>
        <td></td>
      </tr>    
    </table>   
</div>

<div class="block_2" style=" display: inline-block;" [ngClass]=" progressShow ? 'loader' : 'null'">
  <span><b>Vacation Mode:</b></span> 
  <span class="toggle_Button">  <mat-slide-toggle [disabled]="progressShow" (change)="onChangeVacationMode($event)" [(ngModel)]="vacationMode" ></mat-slide-toggle> </span>
  <div><span style="color:red">Note: </span><span style="font-style: italic;">User Profile Schedule is for SMS text message schedule and emails would be 24 / 7</span></div>
  <table style="margin-top: 50px;" class="vacationTable" [ngStyle]="{'background-color': onVacation ? '#ebebeb' : ( !onVacation ? null : null) }"> 
    <tr style="text-align: center;">
        <td width="5%"></td>
        <td>   </td>
        <td class="defualt_label"> <b >Start Time</b></td>
        <td class="defualt_label"> <b >End Time</b> </td>
        <td> <b> <mat-checkbox [disabled]="onVacation" style="padding-left: 40%;" (change)="all_week_check($event)" [(ngModel)]="All_Day_Enable"></mat-checkbox> </b> &nbsp; <b>24 / 7</b> </td>
        <td width="5%">  </td>
    </tr>
    <tr>
        <td width="15%"><b>Multi Day</b></td>
        <td width="20%" class="defulatHoursTopBottom defulatHoursLeft"> Default Hours  </td>
        <td class="defulatHoursTopBottom"> 
            <div class="ngx-timepicker-field-example">
             <ngx-timepicker-field  [format]="24" [(ngModel)]="defaultStartTime" (ngModelChange)="checkMinMaxCOnditions_defualtHours('start',$event)" [disabled]="onVacation || All_Day_Enable || progressShow" ></ngx-timepicker-field>
            </div>
        </td>
        <td class="defulatHoursTopBottom"> 
         <ngx-timepicker-field [format]="24" [(ngModel)]="defaultEndTime" (ngModelChange)="checkMinMaxCOnditions_defualtHours('end',$event)" [disabled]="onVacation || All_Day_Enable || progressShow" ></ngx-timepicker-field>
      </td>
        <td class="defulatHoursTopBottom defulatHoursRight"> <button   mat-raised-button class="update applyAll" (click)="apply_All(defaultStartTime,defaultEndTime)" tabindex="1" [disabled]="disable_all || progressShow">Apply Default</button> </td>
        <td  width="5%"> </td>
    </tr>
    
    <tr *ngFor="let day of vacationData;index as i">
      <td width="15%"> 
         <mat-slide-toggle [disabled]="progressShow" (change)="ChangeSwing($event,i)" [(ngModel)]="day.enableSwing" [disabled]="onVacation || All_Day_Enable || progressShow"></mat-slide-toggle> 
      </td>
      <td> {{day.dayOfWeek}}  </td> 
      <td  class="width_30"> 
        <ngx-timepicker-field [format]="24" [(ngModel)]="day.startTime" (ngModelChange)="checkMinMaxCOnditions(i,'start',$event)" [disabled]="onVacation || All_Day_Enable || progressShow" ></ngx-timepicker-field>
      </td>
      <td  class="width_30"> 
        <ngx-timepicker-field [format]="24" [(ngModel)]="day.endTime" (ngModelChange)="checkMinMaxCOnditions(i,'end',$event)" [disabled]="onVacation || All_Day_Enable || progressShow" ></ngx-timepicker-field>
      </td>
      <td style="text-align:center"> <mat-checkbox  [disabled]="All_Day_Enable || onVacation || progressShow" [(ngModel)]="day.Enabled"></mat-checkbox> </td>
      <td style="text-align:center"  width="5%"> </td>
  </tr>
  <tr>
    <td width="15%"></td>
    <td></td>
    <td></td>
    <td style="text-align: right;padding-right: 7%;"><b><i>**EST Time Zone</i></b></td>
    <td></td>
    <td width="5%"></td>
  </tr>

  <tr>
    <td></td>
    <td colspan="5">
      <button class="update"  [disabled]="onVacation  || progressShow" mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1">{{confirmButtonText}}</button>
      <button class="update" [disabled]="onVacation || progressShow" style="margin-left:10px" mat-raised-button  tabindex="-1" (click)="cancelClick()">{{cancelButtonText}}</button>
    </td>
  </tr>
</table> 


  
</div>


</div>


<div class=" mobile_display" >
  <mat-spinner *ngIf="progressShow"></mat-spinner>

  <div class="block_1" [ngClass]=" progressShow ? 'loader' : 'null'">

  <table>
    <tr *ngFor="let mobile of mobileNumbers;let i=index" >
      <td>
        <select class="country_code" [disabled]="mobile.readonly">
        <option value="+1">+1</option>
      </select>
      </td>
        <td> <input class="phone_input" maxlength="14" [disabled]="mobile.readonly" placeholder="(xxx) xxx - xxxx" (keypress)="numberOnly($event)" [(ngModel)]="mobile.mobileNumber" (ngModelChange)="valuechange($event,i)" (keypress)="formatPhoneNumber(i)">  </td>
        <td *ngIf="mobile.del"> <button class="button_height"  mat-raised-button (click)="deleteMobileNumber(mobile.mobileNumber,i)" color="warn" >-</button> </td>
    </tr>
    <tr *ngIf="showSaveButton">
      <td></td>
      <td style="text-align: center;"> <button  [disabled]="progressShow" mat-raised-button class="update applyAll" (click)="onConfirmClick()" tabindex="1" >Save</button> </td>
      <td></td>
      <td></td>
    </tr>       
</table>   

</div>

<div class="block_2" [ngClass]=" progressShow ? 'loader' : 'null'">
  <span><b>Vacation Mode:</b></span> 
  <span class="toggle_Button"> <mat-slide-toggle [disabled]="progressShow" (change)="onChangeVacationMode($event)" [(ngModel)]="vacationMode" ></mat-slide-toggle> </span>
  <span style="margin-left: 65px;"><mat-checkbox [disabled]="onVacation" style="margin-left: 10px;" (change)="all_week_check($event)" [(ngModel)]="All_Day_Enable"><b>24 / 7</b></mat-checkbox>  </span>
  <div><span style="color:red">Note: </span><span style="font-style: italic;">User Profile Schedule is for SMS text message schedule and emails would be 24 / 7</span></div>
  <table class="vacationTable" [ngStyle]="{'background-color': onVacation ? '#ebebeb' : (!onVacation ? null : null) }">
   
    <tr class="border_tr">
        
      <td >
          <span style="display: inline-block !important; margin-right:10px;margin-left: -7px;"> <b>Default Hours</b> </span>
          <span style="float: right;">  <button class="submit"  mat-raised-button class="update dflttBtn" (click)="apply_All(defaultStartTimee,defaultEndTimee)" tabindex="1" [disabled]="disable_all">Apply Default</button> </span>
      </td>

      <td >
          <div class="mobile_startTime">
            <span class="tiemerLabel">Start time</span>
            <ngx-timepicker-field [format]="24" [(ngModel)]="defaultStartTimee" (ngModelChange)="checkMinMaxCOnditions_defualtHours('start',$event)" [disabled]="onVacation || All_Day_Enable || progressShow" ></ngx-timepicker-field>
          </div>

          <div class="mobile_endTime">
            <span class="tiemerLabel">End Time</span>
            <ngx-timepicker-field [format]="24" [(ngModel)]="defaultEndTimee" (ngModelChange)="checkMinMaxCOnditions_defualtHours('end',$event)" [disabled]="onVacation || All_Day_Enable || progressShow" ></ngx-timepicker-field>
          </div>

      </td>

      <td></td>
    </tr>
    
    <tr *ngFor="let day of vacationData;index as i" class="border_tr" >
      <td class="displa_inline"> <span><b>{{day.dayOfWeek}}</b>  </span>
         <span>  <mat-slide-toggle [disabled]="progressShow" (change)="ChangeSwing($event,i)" [(ngModel)]="day.enableSwing"  style="margin-top: -23px;margin-left: 55%;"></mat-slide-toggle> </span>
         <span><mat-checkbox  [checked]="day.Enabled" [disabled]="All_Day_Enable || onVacation || progressShow" [(ngModel)]="day.Enabled" style="float: right;margin-top: -23px;"><b>Enable</b></mat-checkbox> </span>
      </td>

    

      <td > 
       <div class="mobile_startTime">
          <span class="tiemerLabel">Start time</span>
           <ngx-timepicker-field [format]="24" [(ngModel)]="day.startTime" (ngModelChange)="checkMinMaxCOnditions_defualtHours('start',$event)" [disabled]="onVacation || All_Day_Enable || progressShow" ></ngx-timepicker-field>
       </div>

       <div class="mobile_endTime">
          <span class="tiemerLabel">End time</span>
           <ngx-timepicker-field [format]="24" [(ngModel)]="day.endTime" (ngModelChange)="checkMinMaxCOnditions_defualtHours('start',$event)" [disabled]="onVacation || All_Day_Enable || progressShow" ></ngx-timepicker-field>
       </div> 
      </td>
    
    <td></td>
    
  </tr>

  <div style="float: right;"> <b><i>**EST Time Zone</i></b></div>
  
</table> 



<div class="buttonGroup">
  <button class="update" [disabled]="onVacation || progressShow"  mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1">{{confirmButtonText}}</button>
  <button class="update" [disabled]="onVacation || progressShow"  mat-raised-button style="margin-left:10px"  (click)="cancelClick()" tabindex="-1">{{cancelButtonText}}</button>
</div>

  
</div>


</div>




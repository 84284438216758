import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlarmService } from '../alarms/alarm.service';
import { MatLegacySnackBar as MatSnackBar,MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import {GeaMesCognitoAuthService } from '@gea-mes/cognito';
import {Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Globals } from '../app.globals';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-groups.component.html',
  styleUrls: ['./user-groups.component.css']
})
export class UserGroupsComponent implements OnInit {
  formGroup: UntypedFormGroup;
  alarm_groups: any;
  comparePlant: ((f1: any, f2: any) => boolean) | null = this.compareByPlant;
  siteData:any=[];
  adminSub$: Subscription;
  displayedColumns: string[] = ['GroupName','Description','TeamChannel'];
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  showUserGroupsTable:boolean=false;

  constructor(public alarmService: AlarmService,
    private router: Router,
    private http: HttpClient,
    private formBuilder:UntypedFormBuilder,
    public geaMesCognitoAuthService: GeaMesCognitoAuthService,
    public _global: Globals,
    private _snackBar: MatSnackBar) { 
      this.formGroup = this.formBuilder.group({
      });
      
    }
  @ViewChild(MatSort, {static: true}) sort: MatSort;  

  ngOnInit() {
      this.formGroup.addControl('Plant',new UntypedFormControl());
      // Fetch Plant List
      this.alarmService.getSitesData().subscribe(res => {
        this.siteData=res['Body'];
        this.alarmService.alarmSiteData=res['Body'];
        if(this.alarmService.plantValue_UserGroups['name']){
          this.formGroup.get('Plant').setValue(this.alarmService.plantValue_UserGroups);
          this.getAlarmGroupsInfo();
        }
      });

      // Check if login user is Super User 
      this.adminSub$ = this.geaMesCognitoAuthService.isUserInRole("Admin").pipe(first()).subscribe( out => {
        this._global.admin = out;
        if (this.adminSub$) this.adminSub$.unsubscribe(); // We've got an answer, can unsubscribe now
      });

  }

  compareByPlant(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }

  getAlarmGroupsInfo(){
    let plant_name=this.formGroup.get('Plant').value.name;
    let inputParams = {};
    inputParams['Plant']=plant_name;
    // API Call To Fecth AlarmGropList
    this.alarmService.getAlarmsGrousUsersList(inputParams).subscribe(data => {
      this.alarm_groups = new MatTableDataSource(data['Body']);
      this.alarm_groups.sort=this.sort;
      if(data['Body'].length==0){
        this._snackBar.open("No User Groups."," ", {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
     
    });
    
  }

  onUserGroupClick(input){
    let group_id=input['GroupID'];
    this.alarmService.goupdID_userGropSelected=group_id;
    if(this._global.admin){
      this.router.navigate(["/userGroupsConfig-Update"]);
    }
  }

  onPlantSelecetd(){
    this.alarmService.plantValue_UserGroups=this.formGroup.get('Plant').value;
  }
  }



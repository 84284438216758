import { Component, OnInit } from '@angular/core';
import * as moment from "moment";
import { environment } from '../../../src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AlarmService } from '../alarms/alarm.service';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  mobileNumbers:any=[{
    mobileNumber: '',
    Protocol:'',
    valid:false,
    del:false,
    readonly:false
}];


defaultStartHour:any;
defaultStartMinute:any;
defaultEndHour:any;
defaultEndMinute:any;
onVacation:boolean=false;
All_Day_Enable:boolean=false;
defaultStartTime:any='00:00';
//defaultEndTime:any='23:45';
defaultEndTime:any='00:00';
defaultStartTimee:any='00:00';
//defaultEndTimee:any='23:45';
defaultEndTimee:any='00:00';
defaultmaxTime:any='00:00';
//defaultminTime:any='23:45';
defaultminTime:any='23:59';
today: string = moment().format('D MMM YYYY');
readonly checkMobileNumber =  environment.userProfileBaseUrl+"/AlarmUserProfileList?Protocol=sms&type=check";
horizontalPosition: MatSnackBarHorizontalPosition = 'center';
verticalPosition: MatSnackBarVerticalPosition = 'top';
vacationMode:any=0;
showSaveButton:any=false;
progressShow:boolean=false;

private exportTime = { hour: 7, minute: 15, meriden: 'PM', format: 24 };

vacationData:any=[{
  dayOfWeek:'Monday',
  startTime:'',
  endTime:'',
  Enabled:false,
  All_Day_weekday:false,
  maxTime:'',
  minTime:'',
  validTimeInputs:true,
  enableSwing:'0'
},{
  dayOfWeek:'Tuesday',
  startTime:'',
  endTime:'',
  Enabled:false,
  All_Day_weekday:false,
  maxTime:'',
  minTime:'',
  validTimeInputs:true,
  enableSwing:'0'
},
{
  dayOfWeek:'Wednesday',
  startTime:'',
  endTime:'',
  Enabled:false,
  All_Day_weekday:false,
  maxTime:'',
  minTime:'',
  validTimeInputs:true,
  enableSwing:'0'
},
{
  dayOfWeek:'Thursday',
  startTime:'',
  endTime:'',
  Enabled:false,
  All_Day_weekday:false,
  maxTime:'',
  minTime:'',
  validTimeInputs:true,
  enableSwing:'0'

},
{
  dayOfWeek:'Friday',
  startTime:'',
  endTime:'',
  Enabled:false,
  All_Day_weekday:false,
  maxTime:'',
  minTime:'',
  validTimeInputs:true,
  enableSwing:'0'

},
{
  dayOfWeek:'Saturday',
  startTime:'',
  endTime:'',
  Enabled:false,
  All_Day_weekday:false,
  maxTime:'',
  minTime:'',
  validTimeInputs:true,
  enableSwing:'0'
},{
  dayOfWeek:'Sunday',
  startTime:'',
  endTime:'',
  Enabled:false,
  All_Day_weekday:false,
  maxTime:'',
  minTime:'',
  validTimeInputs:true,
  enableSwing:'0'
}];

jsonData:any={
    "Protocol": "",
    "EndPoint": ""
}

isPhonenumberValid:boolean=false;
message: string = "Please Enter Your Mobile Number Below."
confirmButtonText = "Submit"
cancelButtonText = "Cancel"
breakpoint: any;
disable_all:boolean=false;
disable_hour:boolean=false;


  constructor(public alarmService: AlarmService,
    private http: HttpClient,
    private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.defaultStartHour='0';
    this.defaultStartMinute='15';
    this.defaultEndHour='23';
    this.defaultEndMinute='15';
    this.getUserProfileFullData();


  }

  checkForMobileLength(arr){
    let count=0;
      if(arr.length>0){
        for(var i=0;i<arr.length;i++){
          if(arr[i]['Protocol']=="sms"){
            count++
          }
        }      
      }
      return count;
    }

  getUserProfileFullData(){
    this.http.get(this.checkMobileNumber).toPromise().then(data => {
      let profileResp=data['Body'][0].ProfileData;
      let vacationResp=data['Body'][0].ScheduleData;
      let mobileCount=this.checkForMobileLength(profileResp);

      if(mobileCount==0){
        this.mobileNumbers=[{
          mobileNumber: '',
          Protocol:'',
          valid:false,
          del:false,
          readonly:false
        }];
      }else if(mobileCount>0){
        this.mobileNumbers=[];
        for(let r of profileResp){
          if(r.Protocol=="sms"){
            let tempObj={};
            // let mobile= r.EndPoint;
            // mobile=mobile.substr(0, mobile.length); 
            tempObj['mobileNumber']=r.EndPoint; 
            tempObj['Protocol']=r.Protocol;
            tempObj['valid']=true;
            tempObj['del']=true;
            tempObj['readonly']=true;
            this.mobileNumbers.push(tempObj);
            tempObj={};      
          }
        } 
        this.setMobileNUmberFormatForDisplay();
      }

      if(this.mobileNumbers.length==1 && !this.mobileNumbers[0].readonly){
          this.showSaveButton=true;
        }else{
          this.showSaveButton=false;
        }

      if(vacationResp.length==0 || vacationResp.length==1){
        this.All_Day_Enable=true;
        for(let r of this.vacationData){
          r['All_Day_weekday']=true;
          r['Enabled']=true;
          this.disable_all=true;
          r.startTime='00:00';
          r.endTime='00:00';
          r.maxTime='00:00';
          r.minTime='00:00';
        }
      }
      
      if(vacationResp.length>0){

        for(var i=0;i<vacationResp.length;i++){
          if(vacationResp[i].ScheduleType=="Vacation Mode" &&  vacationResp[i].DayOfWeek=="ALL" && vacationResp[i].AllDay==1){
            this.vacationMode=1;
            this.disable_all=true;
            this.onVacation=true;
          }
        }

        if(vacationResp.length>1){
          let tempVacData=[];
          for(var i=0;i<vacationResp.length;i++){
          if(vacationResp[i].ScheduleType!="Vacation Mode"){
            let tempObj={};
            vacationResp[i]['StartTime']=moment(vacationResp[i]['StartTime']).format("HH:mm");
            vacationResp[i]['EndTime']=moment(vacationResp[i]['EndTime']).format("HH:mm");
            tempObj["startTime"]=vacationResp[i].StartTime;
            tempObj["endTime"]=vacationResp[i].EndTime;
            // tempObj["maxTime"]=vacationResp[i].StartTime;
            // tempObj["minTime"]=vacationResp[i].EndTime;
            if(vacationResp[i].StartTime=="00:00" && vacationResp[i].EndTime=="00:00"){
                tempObj["maxTime"]="00:00";
                tempObj["minTime"]="23:59"
            }else{
                tempObj["maxTime"]=vacationResp[i].StartTime;
                tempObj["minTime"]=vacationResp[i].EndTime;
            }
            tempObj['All_Day_weekday']=vacationResp[i].AllDay;
            tempObj['dayOfWeek']=vacationResp[i].DayOfWeek;
            tempObj['Enabled']=vacationResp[i].Enabled;
            tempObj['validTimeInputs']=true;
            if(vacationResp[i].enableSwing){
              tempObj['enableSwing']=1;
            }else{
              tempObj['enableSwing']=0;
            }
            tempVacData.push(tempObj);
            tempObj={}
          }                  
        }

        this.vacationData=tempVacData;
        let week_arr=['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'];
        this.vacationData = this.mapOrder(this.vacationData, week_arr, 'dayOfWeek');
        this.check_for_apply_all_week();
        this.checkForAlltimeIntervels();
        this.checkSwing(this.vacationData)
        }
 
      }
      
    });
    

  }

  reFormatPhonenumbers(){
    for(var i=0;i<this.mobileNumbers.length;i++){
      let number=this.mobileNumbers[i].mobileNumber;
      let reformattedNumber=number.slice(1, 4)+number.slice(6,9)+number.slice(10,14);
      this.mobileNumbers[i].mobileNumber=reformattedNumber;    
    }
  }
  setMobileNUmberFormatForDisplay(){
    for(var i=0;i<this.mobileNumbers.length;i++){
    let number=this.mobileNumbers[i].mobileNumber; 
     if(number.charAt(0)+number.charAt(1)=="+1"){
      var reformattedNumber = "("+number.slice(2, 5)+") "+number.slice(5, 8)+"-"+number.slice(8, 12);
     }else{
      var reformattedNumber = "("+number.slice(0, 3)+") "+number.slice(3, 6)+"-"+number.slice(6, 10);
     }
    

    
    this.mobileNumbers[i].mobileNumber=reformattedNumber; 
    }
  }

  check_for_apply_all_week(){
    var count=0;
    for(let r of this.vacationData){
      if(r.All_Day_weekday){
        count++
      }

      // if(r.startTime=="00:00" && r.endTime=="00:00"){
      //   count++
      // }
    }

    if(count==7){
      this.All_Day_Enable=true;
    }else{
      this.All_Day_Enable=false;
    }

  }

  onChangeVacationMode(e: Event) {
    if (e['checked'] == true) {
      this.onVacation=true;
      this.disable_all=true;
      this.onConfirmClick();
      
    } else {
      this.onVacation=false;
      this.disable_all=false;
      this.onConfirmClick();
    }
  }

  apply_All(st,et){
    let starTimetHours=st.substr(0, st.indexOf(':')); 
    let starTimetMinutes=st.substr(st.indexOf(':')+1,st.length); 
    let endTimetHours=et.substr(0, et.indexOf(':'));
    let endTimetMinutes=et.substr(et.indexOf(':')+1,et.length); 
      for(let time of this.vacationData){
        time.startTime=st;
        time.endTime=et;
        time.maxTime=st;
        time.minTime=et;
        time.Enabled=1; 
      }

      if( (endTimetHours<starTimetHours) || (starTimetHours==endTimetHours && (endTimetMinutes<=starTimetMinutes) ) ){
       
        this.vacationData.forEach((value, index) => {
          value['enableSwing']=1;

          if(index==6){
            if(this.vacationData[0].dayOfWeek.includes("/")){
              this.vacationData[index].dayOfWeek= this.vacationData[index].dayOfWeek+" / "+ this.vacationData[0].dayOfWeek.split('/')[0];
            }else{
              this.vacationData[index].dayOfWeek= this.vacationData[index].dayOfWeek+" / "+ this.vacationData[0].dayOfWeek;
            }
          }else{
           
            if(this.vacationData[index+1].dayOfWeek.includes("/")){
              this.vacationData[index].dayOfWeek= this.vacationData[index].dayOfWeek+" / "+ this.vacationData[index+1].dayOfWeek.split('/')[0];
            }else{
              this.vacationData[index].dayOfWeek= this.vacationData[index].dayOfWeek+" / "+ this.vacationData[index+1].dayOfWeek;
            }
           
          }
      });

      }else{
        for(let day of this.vacationData){
          day['enableSwing']=0;
            if(day['dayOfWeek'].includes("/")){
              day['dayOfWeek']=day['dayOfWeek'].split('/')[0].trim();
            }
        }
      }	
  

  }

  all_week_check(e){

    if(e['checked']){
      for(let r of this.vacationData){
        r['All_Day_weekday']=true;
        r['Enabled']=true;
        this.disable_all=true;
        r.startTime='00:00';
        r.endTime='00:00';
        r.maxTime='00:00';
       // r.minTime='23:45';
       r.minTime='23:59';
      }
      this.defaultStartTimee='00:00';
      this.defaultEndTimee='00:00';
      this.defaultStartTime='00:00';
      this.defaultEndTime='00:00';
      //this.defaultminTime='23:45';
      this.defaultminTime='23:59';
      this.defaultmaxTime='00:00'

      for(let day of this.vacationData){
        day['enableSwing']=0;
        if(day['dayOfWeek'].includes("/")){
          day['dayOfWeek']=day['dayOfWeek'].split('/')[0].trim();
        }
      }
    }else{
      for(let r of this.vacationData){
        r['All_Day_weekday']=false;
        r['Enabled']=false;
        this.disable_all=false;

        r.startTime='00:00';
        r.endTime='00:00';
        r.maxTime='00:00';
       // r.minTime='23:45';
        r.minTime='23:59';
        
      }
    }
  }

  all_week_individual(e,index){

    if(!e['checked']){
      this.All_Day_Enable=false;
      this.vacationData[index].Enabled=0;
    }if(e['checked']){
      this.vacationData[index].startTime='00:00'
      this.vacationData[index].endTime='00:00';
      this.vacationData[index].maxTime='00:00'
      //this.vacationData[index].minTime='23:45';
      this.vacationData[index].minTime='23:59';
      this.vacationData[index].Enabled=1;
      
    }
    this.check_for_apply_all_week();
  }

  hourChange(a,b){
    if(a==b){
      this.disable_hour=true;
    }else{
      this.disable_hour=false;
    }
  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  checkMinMaxCOnditions(index,type,latestValue){
    
    if(type=="start"){
  
      let starTimetHours=latestValue.substr(0, latestValue.indexOf(':')); 
      let starTimetMinutes=latestValue.substr(latestValue.indexOf(':')+1,latestValue.length); 
      let finalmaxTime=starTimetHours+":"+(parseInt(starTimetMinutes)+1);
      this.vacationData[index].maxTime=finalmaxTime;
    }else if(type=="end"){
     
      let endTimetHours=latestValue.substr(0, latestValue.indexOf(':'));
      let endTimetMinutes=latestValue.substr(latestValue.indexOf(':')+1,latestValue.length); 
      let finalminTime=endTimetHours+":"+(parseInt(endTimetMinutes)-1);
      this.vacationData[index].minTime=finalminTime;

    }
  }

  checkMinMaxCOnditions_defualtHours(type,latestValue){
   
    if(type=="start"){
      
      let starTimetHours=latestValue.substr(0, latestValue.indexOf(':')); 
      let starTimetMinutes=latestValue.substr(latestValue.indexOf(':')+1,latestValue.length); 
      let finalmaxTime=starTimetHours+":"+(parseInt(starTimetMinutes)+1);
      this.defaultmaxTime=finalmaxTime;
      

    }else if(type=="end"){
     
      let endTimetHours=latestValue.substr(0, latestValue.indexOf(':'));
      let endTimetMinutes=latestValue.substr(latestValue.indexOf(':')+1,latestValue.length); 
      let finalminTime=endTimetHours+":"+(parseInt(endTimetMinutes)-1);
      this.defaultminTime=finalminTime;
    }
  }

  formatPhoneNumber(index){
   if(this.mobileNumbers[index].mobileNumber.length==3){
    this.mobileNumbers[index].mobileNumber="("+this.mobileNumbers[index].mobileNumber+") ";
   }
   if(this.mobileNumbers[index].mobileNumber.length==9){
    this.mobileNumbers[index].mobileNumber=this.mobileNumbers[index].mobileNumber+"-";
   }

  }

  deleteMobileNumber(mblnmbr,index){
    if(this.mobileNumbers[index]['readonly']){
      let obj={}
      let reformattedNumber=mblnmbr.slice(1, 4)+mblnmbr.slice(6,9)+mblnmbr.slice(10,14);

    obj['EndPoint']="+1"+reformattedNumber;

    this.alarmService.deleteMobileNumber(obj).subscribe(res => {
      if(res['StatusMessage']==" UserProfile Deleted successfully "){
        this._snackBar.open("User profile mobile number delete", "Success", {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['Success'],
        
        });
        this.getUserProfileFullData();
      }else{
        this._snackBar.open("UserProfile Delete", "Failed", {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        this.getUserProfileFullData();
      }

    } );

    }else{
      this.deletePhoneNumber(index)
    }
    
  }
  onConfirmClick(): void {

    this.validatePhoneNumbers();
  
    if(this.isPhonenumberValid){
          this.reFormatPhonenumbers();
          for(var i=0;i<this.mobileNumbers.length;i++){    
            if( this.mobileNumbers[i].mobileNumber!=""){
              this.mobileNumbers[i].mobileNumber="+1"+ this.mobileNumbers[i].mobileNumber; 
            }  
          }
          
          let Protocol=this.mobileNumbers[0].Protocol;
          let EndPoint=this.mobileNumbers[0].mobileNumber;
        
      
          for(var i=1;i<this.mobileNumbers.length;i++){
            if(this.mobileNumbers[i].mobileNumber!=""){
              Protocol+=","+this.mobileNumbers[i].Protocol
              EndPoint+=","+this.mobileNumbers[i].mobileNumber
            }
            
          }
      
          this.jsonData.Protocol=Protocol;
          this.jsonData.EndPoint=EndPoint;
          this.jsonData.onVacation=this.onVacation;
          this.jsonData.All_Day_Enable=this.All_Day_Enable;
          let newVacationData=[];
          if(this.onVacation){

            let tempObj={};
            tempObj['startTime']="0001-01-01 00:00:00"
            tempObj['endTime']="0001-01-01 00:00:00"
            tempObj['Enabled']=false
            //tempObj['All_Day_weekday']=false
            tempObj['dayOfWeek']="All"
            newVacationData.push(tempObj);
            tempObj={};
            this.jsonData.vactionMode=newVacationData;
          }else{

            for(var i=0;i<this.vacationData.length;i++){
              let tempObj={};
              tempObj['startTime']=moment(this.vacationData[i].startTime, "HH:mm").format('0001-01-01 HH:mm:00');
              tempObj['endTime']=moment(this.vacationData[i].endTime, "HH:mm").format('0001-01-01 HH:mm:00');
              tempObj['dayOfWeek']=this.vacationData[i].dayOfWeek;
              tempObj['Enabled']=this.vacationData[i].Enabled;
              if(this.vacationData[i].startTime =="00:00" && this.vacationData[i].endTime=="00:00" && this.vacationData[i].Enabled){
                tempObj['All_Day_weekday']=true;
              }else{
                tempObj['All_Day_weekday']=false;
              }
              //tempObj['All_Day_weekday']=this.vacationData[i].All_Day_weekday;
              newVacationData.push(tempObj);
              tempObj={};
            }
            this.jsonData.vactionMode=newVacationData;
          }
          
 
          this.progressShow=true;
          this.reformatDays(this.jsonData.vactionMode);
          this.alarmService.addMobileNumber(this.jsonData).subscribe(res => {
          if(res['statusMessage']=="UserProfile Updated Successfully"){
            this._snackBar.open("UserProfile Update", "Success", {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['Success'],
            });
            this.getUserProfileFullData();
            this.progressShow=false;
          }else{
            this._snackBar.open("Error Occured Ehile Updating User Profile", "Error", {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              
            });
            this.progressShow=false;
          }
          });

    }else{
      this._snackBar.open("Please Enter 10 Digit Phone Number", "", {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
    }
  }

  deletePhoneNumber(index){
    this.mobileNumbers.splice(index, 1);
    if(this.mobileNumbers.length==1){
      this.mobileNumbers[0].del=false; 
    }
  }

  mapOrder (array, order, key) {
    array.sort( function (a, b) {
      var A = a[key], B = b[key];    
      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });   
    return array;
  };

  validatePhoneNumbers(){

    for(var i=0;i<this.mobileNumbers.length;i++){
      if( !(this.mobileNumbers[i].readonly) && this.mobileNumbers[i].mobileNumber!= "" && this.mobileNumbers[i].mobileNumber.length<14 ){
        this.isPhonenumberValid=false;
      }else{
        this.isPhonenumberValid=true;
      }
      
    }
  }

  valuechange(newValue,index) {
   
    let mymodel = newValue;
    if(this.mobileNumbers[index].mobileNumber.length==14){
      this.mobileNumbers[index].valid=true;
    }else{
      this.mobileNumbers[index].valid=false;
    }

    if(this.mobileNumbers[index].mobileNumber!=""){
      this.mobileNumbers[index].Protocol="sms";
    }else{
      this.mobileNumbers[index].Protocol="";
    }
   
  }

  cancelClick(){
    this.disable_all=false;
    this.getUserProfileFullData();
    this.defaultmaxTime='00:00';
    this.defaultminTime='23:59';
    this.defaultStartTimee='00:00';
    this.defaultEndTimee='00:00';
    this.defaultStartTime='00:00';
    this.defaultEndTime='00:00';
  }

  checkForAlltimeIntervels(){
    var invalidCOunt=0;
    for(var i=0;i<this.vacationData.length;i++){
      var valid=false;
      let st=this.vacationData[i].startTime;
      let et=this.vacationData[i].endTime;
      let starTimetHours=st.substr(0, st.indexOf(':')); 
      let starTimetMinutes=st.substr(st.indexOf(':')+1,st.length); 
      let endTimetHours=et.substr(0, et.indexOf(':'));
      let endTimetMinutes=et.substr(et.indexOf(':')+1,et.length); 
  
      if(st=="00:00" && et=="00:00"){
        this.vacationData[i].validTimeInputs=true;
        valid=true;
      }else if( (endTimetHours<starTimetHours) || (starTimetHours==endTimetHours && (endTimetMinutes<=starTimetMinutes) ) ){
        this.vacationData[i].validTimeInputs=false;
        valid=false;
        invalidCOunt++
      }else{
        this.vacationData[i].validTimeInputs=true;
        valid=true;
      }
    }

    if(invalidCOunt>0){
      return false;
    }else{
      return true;
    }
   
  }

  ChangeSwing(e,i){
    if (e['checked'] == true) {
     this.vacationData[i].enableSwing=1;   
      if(i==6){
        if(this.vacationData[0].dayOfWeek.includes("/")){
          this.vacationData[i].dayOfWeek= this.vacationData[i].dayOfWeek+" / "+ this.vacationData[0].dayOfWeek.split('/')[0];
        }else{
          this.vacationData[i].dayOfWeek= this.vacationData[i].dayOfWeek+" / "+ this.vacationData[0].dayOfWeek;
        }
      }else{
       
        if(this.vacationData[i+1].dayOfWeek.includes("/")){
          this.vacationData[i].dayOfWeek= this.vacationData[i].dayOfWeek+" / "+ this.vacationData[i+1].dayOfWeek.split('/')[0];
        }else{
          this.vacationData[i].dayOfWeek= this.vacationData[i].dayOfWeek+" / "+ this.vacationData[i+1].dayOfWeek;
        }
       
      }
    } else {
      this.vacationData[i].enableSwing=0;
      this.vacationData[i].dayOfWeek= this.vacationData[i].dayOfWeek.split('/')[0];

      let st=this.vacationData[i].startTime;
      let et=this.vacationData[i].endTime;
      let starTimetHours=st.substr(0, st.indexOf(':')); 
      let starTimetMinutes=st.substr(st.indexOf(':')+1,st.length); 
      let endTimetHours=et.substr(0, et.indexOf(':'));
      let endTimetMinutes=et.substr(et.indexOf(':')+1,et.length); 
	  
      if( (endTimetHours<starTimetHours) || (starTimetHours==endTimetHours && (endTimetMinutes<=starTimetMinutes) ) ){
          this.vacationData[i].startTime="00:00";
          this.vacationData[i]. endTime="00:00";
        }	  

    }
  }

  reformatDays(arr){
    for(var i=0;i<arr.length;i++){
      if(arr[i].dayOfWeek.includes("/")){
        arr[i].dayOfWeek=arr[i].dayOfWeek.split('/')[0].trim();
      }
    }
  }

  checkSwing(arr){
    for(var i=0;i<this.vacationData.length;i++){
       if(i==6){
        if(!this.vacationData[i].validTimeInputs){
          this.vacationData[i].enableSwing=1;
          if(this.vacationData[0].dayOfWeek.includes("/")){
            this.vacationData[i].dayOfWeek= this.vacationData[i].dayOfWeek+" / "+ this.vacationData[0].dayOfWeek.split('/')[0];
          }else{
            this.vacationData[i].dayOfWeek=this.vacationData[i].dayOfWeek+" / "+this.vacationData[0].dayOfWeek
          }
        }else{
          this.vacationData[i].enableSwing=0;
        }
      }
      else{
        if(!this.vacationData[i].validTimeInputs){
          this.vacationData[i].enableSwing=1;
          this.vacationData[i].dayOfWeek=this.vacationData[i].dayOfWeek+" / "+this.vacationData[i+1].dayOfWeek
        }else{
          this.vacationData[i].enableSwing=0;
        }
       
      }
    }
  }
}



import { Component, Inject } from '@angular/core';
import {  MatLegacyDialogRef as MatDialogRef,  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {  UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'confirmation-dialog2',
  templateUrl: 'confirmation-dialog2.html',
  styleUrls: ['confirmation-dialog2.css']

})
export class ConfirmationDialog2 {
  formGroup: UntypedFormGroup;

  mobileNumbers:any=[{
      mobileNumber: '',
      Protocol:'',
      valid:false,
      del:false
  }];

  jsonData:any={
      "Protocol": "",
      "EndPoint": ""
  }

  isPhonenumberValid:boolean=false;
  repaetIntervelValue:number=0;
  isrepaetIntervelValueValid:boolean=true;
  ImmediateAlarm :boolean=false;
  showRepeatEnableFields:boolean=false; // Based on this flag, Repeat Alarm fileds will be shown in pop-up. This falg will sent as input paramter from component while loading popup. 
  message: string = "Please Enter Your Mobile Number Below."
  confirmButtonText = "Yes"
  cancelButtonText = "Cancel"
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ConfirmationDialog2>) {
        
      if(data){
    this.message = data.message || this.message;
    this.showRepeatEnableFields =data.showRepeatEnableFields;
    if (data.buttonText) {
      this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
      this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
    }
      }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  formatPhoneNumber(index){
   if(this.mobileNumbers[index].mobileNumber.length==3){
    this.mobileNumbers[index].mobileNumber="("+this.mobileNumbers[index].mobileNumber+") ";
   }
   if(this.mobileNumbers[index].mobileNumber.length==9){
    this.mobileNumbers[index].mobileNumber=this.mobileNumbers[index].mobileNumber+"-";
   }

  }
  onConfirmClick(): void {

    this.validatePhoneNumbers();    

    if(this.isPhonenumberValid){
      this.reFormatPhonenumbers();
      for(var i=0;i<this.mobileNumbers.length;i++){
        this.mobileNumbers[i].mobileNumber="+1"+ this.mobileNumbers[i].mobileNumber; 
      }
      
      let Protocol=this.mobileNumbers[0].Protocol;
      let EndPoint=this.mobileNumbers[0].mobileNumber;
     
  
      for(var i=1;i<this.mobileNumbers.length;i++){
        if(this.mobileNumbers[i].mobileNumber!=""){
          Protocol+=","+this.mobileNumbers[i].Protocol
          EndPoint+=","+this.mobileNumbers[i].mobileNumber
        }
        
      }
  
      this.jsonData.Protocol=Protocol;
      this.jsonData.EndPoint=EndPoint;
      this.jsonData['repaetIntervelValue']=this.repaetIntervelValue;
      this.jsonData['ImmediateAlarm']=this.ImmediateAlarm;
      this.dialogRef.close({event:true,data:this.jsonData});
    }else{
      alert("Please Enter Valid Phone Numbers")
    }


    

    
    
    
    
  }

  deletePhoneNumber(index){
    this.mobileNumbers.splice(index, 1);
    if(this.mobileNumbers.length==1){
      this.mobileNumbers[0].del=false; 
    }
  }

  validatePhoneNumbers(){
    
    
    for(var i=0;i<this.mobileNumbers.length;i++){      
      if( this.mobileNumbers[i].mobileNumber!= "" && this.mobileNumbers[i].mobileNumber.length==14 ){
        this.isPhonenumberValid=true;
      }else{
        this.isPhonenumberValid=false;
      }      
    }    
  }
  
  reFormatPhonenumbers(){
    
    for(var i=0;i<this.mobileNumbers.length;i++){
      let number=this.mobileNumbers[i].mobileNumber;
      let reformattedNumber=number.slice(1, 4)+number.slice(6,9)+number.slice(10,14);
      this.mobileNumbers[i].mobileNumber=reformattedNumber;
    }
  }
  
  repaetIntervelValueCheck(val){
  console.log(this.repaetIntervelValue)
   if (this.repaetIntervelValue>=5 || this.repaetIntervelValue==0) {
      	this.isrepaetIntervelValueValid=true;
    }else if(this.repaetIntervelValue==null){
	    this.isrepaetIntervelValueValid=true;
	    this.repaetIntervelValue=0;
    }
    else{
    	this.isrepaetIntervelValueValid=false;
    }
  }

  valuechange(newValue,index) {
    if(this.mobileNumbers[index].mobileNumber.length==14){
      this.mobileNumbers[index].valid=true;
    }else{
      this.mobileNumbers[index].valid=false;
    }
    
    if(this.mobileNumbers[index].mobileNumber!=""){
      this.mobileNumbers[index].Protocol="sms";
    }else{
      this.mobileNumbers[index].Protocol="";
    }
   
  }
  

}
